import { useEffect, useState } from "react";
import Table from "./components/Table";
import Button from "../../../base-components/Button";
import './dish-master.css'
import { useLoader } from "../../../base-components/Loader";

function Main() {
	const { showLoader, hideLoader } = useLoader();
	const [prioritiesList, setPrioritiesList] = useState<PriorityData[]>([]);
	const [priorities, setPriorities] = useState<number[]>([1, 2]);
	const fetchPriorities = async () => {
		try {
			showLoader()
			const f = await fetch('/api/priorities_init')
			if (f.ok) {
				const r = await f.json()
				if (r.e) { console.error(r) }
				else {//const newArray = r.map(c => ({ ...c, qty: [10, 20], }));
					setPriorities(r.priorities)
					setPrioritiesList(r.list)
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	useEffect(() => { fetchPriorities() }, [])

	const handleEditForm = (e: any) => {//e.preventDefault();
		const fieldName = e.target.getAttribute('name');
		const fieldValue = e.target.value;

		const q = fieldName.replace('qty', '').split('-')
		prioritiesList[q[1]].qty[q[0]] = Number(fieldValue);

		setPrioritiesList([...prioritiesList]);
	};

	const handleLoadForm = (e: any) => {
		e.preventDefault();
		const index = Number(e.target.getAttribute('name').replace('priority', ''))
		priorities[index] = Number(e.target.value)
		setPriorities([...priorities]);
	};

	return (
		<>
			<div className="mt-5 intro-y box">
				<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60">
					<h2 className="mr-auto text-base font-medium">Dish Master</h2>
					<div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
						<Button rounded variant="soft-success" className="mx-2" onClick={addColumn}>Add Column</Button>
						<Button rounded variant="soft-danger" className="mx-2" onClick={removeColumn}>Remove Column</Button>
					</div>
				</div>
				<div className="p-5">
					<div className="overflow-x-auto">
						<Table priorities={priorities} dmdList={prioritiesList!!}
							handleEditForm={handleEditForm} handleLoadForm={handleLoadForm} />
					</div>
					<Button rounded variant="outline-success" className="flex justift-center mx-auto mt-2" onClick={saveDish}>Save</Button>
				</div>
			</div>
		</>
	);

	async function saveDish() {
		try {
			showLoader()
			const data = prioritiesList.filter((item) => item.category !== undefined).map(({ category, ...rest }) => rest);
			const f = await fetch("/api/add_priority", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ priorities, data }), })
			if (f.ok) {
				const r = f.json()
				alert(JSON.stringify(r))
			} else console.error(f.status, f.statusText)
		} catch (error) { console.error(error) } finally { hideLoader() }
	}

	function addColumn() {
		priorities.push(priorities[priorities.length - 1])
		setPriorities(priorities)

		const dmdl = [...prioritiesList]
		dmdl.forEach((d) => { d.qty.push(d.qty[d.qty.length - 1]) })
		setPrioritiesList(dmdl)
	}
	function removeColumn() {
		const dm = { ...priorities }
		priorities.pop()
		setPriorities(dm)

		const dmdl = [...prioritiesList]
		dmdl.forEach((d) => { d.qty.pop() })
		setPrioritiesList(dmdl)
	}
}

export default Main;