import React from 'react';
import FormInput from '../../../../base-components/Form/FormInput';
import EditRow from './EditRow';

interface TableProps {//dmd: DishMasterData2;
	priorities: number[];
	dmdList: PriorityData[];
	handleEditForm: (e: any) => void;
	handleLoadForm: (e: any) => void;
}

const Table: React.FC<TableProps> = ({ priorities, dmdList, handleEditForm, handleLoadForm }) => {
	//(dmds.length == 0) ? <></> :
	return (<div className="overflow-x-auto">
		<table className="w-full text-left">
			<thead style={{ background: '#f0eeee', textAlign: 'center' }}>
				<tr>
					<th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">Content</th>
					<th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">
						<div>Priority</div>
						<div className="flex align-center justify-center gap-2">
							{priorities.map((number: number, index: number) => (
								<FormInput type="number" onKeyDown={(e) => ["ArrowUp", "ArrowDown", "e", "E"].includes(e.key) && e.preventDefault()}
									min={0} name={"priority" + index} onChange={handleLoadForm} value={number} placeholder={"priority" + index} required />)
							)}
							{/*{dm.priority.map((number: number, index: number) => (*/}
							{/*	<FormInput type="number" onKeyDown={(e) => ["ArrowUp", "ArrowDown", "e", "E"].includes(e.key) && e.preventDefault()}*/}
							{/*		min={0} name={"priority" + index} onChange={handleLoadForm} value={number} placeholder={"priority" + index} required />)*/}
							{/*)}*/}
						</div>
					</th>
					{/*<th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">Actions</th>*/}
				</tr>
			</thead>
			<tbody>
				{dmdList.length == 0 ? <tr><td colSpan={5}><h1 className='text-center mt-3 text-xl font-medium leading-none'>Not added yet</h1></td></tr> :
					dmdList.map((d, i) => (<EditRow index={ i} data={d} handleEditForm={handleEditForm} />))
				}
			</tbody>
		</table>
	</div>
	);
};

export default Table;