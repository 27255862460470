import Button from "../../../../base-components/Button";
import FormInput from "../../../../base-components/Form/FormInput";
import FormSelect from "../../../../base-components/Form/FormSelect";

export default function EditRow({ handleEditForm, handleEditFormSubmit, editFormData, unitProcessList }:
	{
		handleEditForm: (e: any) => void;
		handleEditFormSubmit: (e: any) => void;
		editFormData: DishMasterData;
		unitProcessList: any;
	}) {
	return <>
		<tr>
			<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
				<FormInput type="text" list={'contents'} name="content" onChange={handleEditForm} value={editFormData.content} placeholder="Enter Content" required /></td>
			<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
				<div className="flex justify-center align-center gap-2">
					{
						editFormData.val.map((number: number, index: number) => (
							<FormInput type="number" key={index} min={0} placeholder="Enter Quantity"
								onKeyDown={(e) => ["e", "E"].includes(e.key) && e.preventDefault()}
								name={"val" + index} onChange={handleEditForm} value={number} required />
						))
					}
				</div>
			</td>
			<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
				<FormSelect onChange={handleEditForm} name='unit' value={editFormData.unit} aria-label="Unit">
					<option value='0'>Unit</option>
					{(unitProcessList != undefined && unitProcessList.units != undefined) ? unitProcessList.units.map((p: any) => <option value={p.value} key={p.value}>{p.text}</option>) : <option>Nothing to show</option>}
				</FormSelect>
			</td>
			<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
				<FormSelect onChange={handleEditForm} name='process' value={editFormData.process} aria-label="Process">
					<option value='0'>Process</option>
					{(unitProcessList != undefined && unitProcessList.processes != null) ? unitProcessList.processes.map((p: any) => <option value={p.value} key={p.value}>{p.text}</option>) : <option>Nothing to show</option>}
				</FormSelect>
			</td>
			<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
				<div className="flex align-center justify-center gap-2">
					<Button onClick={handleEditFormSubmit} rounded variant="outline-success">Save</Button>{' '}
					{/*<Button onClick={handleCancelClick} rounded variant="outline-warning">Cancel</Button>*/}
				</div>
			</td>
		</tr>
	</>;
}