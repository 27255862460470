import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import { useLoader } from "../../../base-components/Loader";

export default function Main() {
	const navigate = useNavigate()
	const { showLoader, hideLoader } = useLoader()
	const tableRef = createRef<HTMLDivElement>()
	const tabulator = useRef<Tabulator>()

	//const sendButtonRef = useRef();

	const [customers, setCustomers] = useState([])

	//const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false)
	//const [successModalPreview, setSuccessModalPreview] = useState(false)
	//const [modal, setModal] = useState({ msg: '', title: '', type: '' })

	//const editedCallback = (cell: CellComponent) => {
	//	const d = cell.getData() as any
	//	const name = d.name
	//}

	var copyIcon = function () { return '<i data-lucide="bell-plus" className="w-8 h-8 mr-1 text-success"></i> Remind'; };

	const initTabulator = () => {
		if (tableRef.current) {
			tabulator.current = new Tabulator(tableRef.current, {
				data: customers,//ajaxURL: "api/utensils_category", paginationMode: "remote", filterMode: "remote", sortMode: "remote",
				movableColumns: true, ajaxResponse: (_url, p, res) => {
					//setTotPages(res.last_page)
					const page = p.page, size = p.size
					const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));
					res.data = processedData
					return res
				},
				//dataChanged: (data) => { },
				//footerElement: `<tr><td><b>Total Pages: </b><span>${(tabulator.current != undefined) ?  : 0}</span ></td ></tr >`,
				//paginationCounter: "",
				pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitColumns", placeholder: "No matching records found",
				//groupHeader: (value: any, count: number, data: any, group: GroupComponent) => { return value },
				columns: [
					{ title: "Sr.", field: "srno", minWidth: 30, print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Name", field: "name", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", },
					{ title: "Mobile", field: "in", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", },
					{ title: "Address", field: "out", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', },
					{ title: "Events", field: "ucat", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", },
					{ title: "Pending Amount", field: "ucat", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", },
					{
						title: "Reminder", print: false, formatter: copyIcon, headerSort: false, headerHozAlign: "center", width: 100, hozAlign: "center",
						cellClick: function (_e, row) {
							const id = (row.getData() as any).id;
							navigate('/admin/events/' + id)
							//const index = dataList.findIndex((item) => item.id === id);
							//dataList.splice(index, 1);//tabulator.current?.deleteRow(index+1)
						}
					},
				],
			});
		}
		//tabulator.current?.on('tableBuilt', () => {tabulator.current?.redraw();});
		tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
	};

	// Redraw table onresize
	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator.current) {
				tabulator.current.redraw();
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
			}
		});
	};

	const loadData = async () => {
		try {
			showLoader()
			const f = await fetch('/api/utensils')
			if (f.ok) {
				const d = await f.json()
				d.u.forEach((u: any, i: number) => { u.srno = i + 1 })
				setCustomers(d.customers)
			} else console.error(f.status, f.statusText)
		} catch (e) { } finally { hideLoader() }
	}

	useEffect(() => { loadData() }, [])
	useEffect(() => {
		initTabulator()
		reInitOnResizeWindow()
	}, [customers])

	return <>
		<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
			<h2 className="mr-auto text-lg font-medium">Customer List</h2>
			<div className="flex w-full mt-4 sm:w-auto sm:mt-0">
				{/*<Button variant="primary" className="mr-2 shadow-md" onClick={() => setHeaderFooterModalPreview(true)} >Add Customer</Button>*/}
			</div>
		</div>
		<div className="p-5 mt-5 intro-y box">
			<div className="overflow-x-auto scrollbar-hidden">
				<div id="tabulator" ref={tableRef} className="mt-5"></div>
			</div>
		</div>
		{/*
			<Dialog open={headerFooterModalPreview} onClose={() => { setHeaderFooterModalPreview(false); }} initialFocus={sendButtonRef}>
			<Dialog.Panel>
				<Dialog.Title><h2 className="mr-auto text-base font-medium">Add Customer</h2></Dialog.Title>
				<Dialog.Description className="grid grid-cols-12 gap-4 gap-y-3">
					<div className="col-span-6 sm:col-span-6">
						<FormLabel>Customer Name</FormLabel>
						<FormInput type="text" placeholder="Customer Name" onChange={(e) => setCategory(e.target.value)} />
					</div>
					<div className="col-span-6 sm:col-span-6">
						<FormLabel>Mobile</FormLabel>
						<FormInput type="text" placeholder="Mobile" onChange={(e) => setCategory(e.target.value)} />
					</div>
					<div className="col-span-12 sm:col-span-12">
						<FormLabel>Address</FormLabel>
						<FormTextarea placeholder="Address" onChange={(e) => setCategory(e.target.value)} />
					</div>
					<div className="col-span-12 sm:col-span-12">
						<FormLabel>Menu</FormLabel>
						<FormTextarea placeholder="Menu" onChange={(e) => setCategory(e.target.value)} />
					</div>
				</Dialog.Description>
				<Dialog.Footer>
					<Button type="button" variant="outline-secondary" onClick={() => { setHeaderFooterModalPreview(false); }} className="w-20 mr-1">Cancel</Button>
					<Button variant="primary" type="button" ref={sendButtonRef} onClick={saveCategory}>Add Maharaj</Button>
				</Dialog.Footer>
			</Dialog.Panel>
		</Dialog>
		<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
			<Dialog.Panel>
				<div className="p-5 text-center">
					<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${modal.type}`} />
					<div className="mt-5 text-3xl">{modal.title}</div>
					<div className="mt-2 text-slate-500">{modal.msg}</div>
				</div>
				<div className="px-5 pb-8 text-center">
					<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24" > Ok </Button>
				</div>
			</Dialog.Panel>
		</Dialog>
		*/}
		
	</>
}