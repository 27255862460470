import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import Button from "../../../base-components/Button";
import { FormInput, FormLabel, FormSelect, FormTextarea } from "../../../base-components/Form";
import { Dialog } from "../../../base-components/Headless";
import { useLoader } from "../../../base-components/Loader";
import Lucide from "../../../base-components/Lucide";

type Vendor = {
	name: string,
	mobile: string,
	address: string,
	category: string,
}

export default function Main() {
	const { showLoader, hideLoader } = useLoader();

	const tableRef = createRef<HTMLDivElement>();
	const tabulator = useRef<Tabulator>();

	const sendButtonRef = useRef();

	const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false);
	const [successModalPreview, setSuccessModalPreview] = useState(false);
	const [msg, setMsg] = useState('');
	const [title, setTitle] = useState('');
	const [type, setType] = useState('');

	const [vendorData] = useState<Vendor>({ name: '', mobile: '', address: '', category: '' })
	//const [category, setCategory] = useState('')
	const [vendors, setVendor] = useState([])
	const [categories, setCategories] = useState([])

	const initTabulator = () => {
		if (tableRef.current) {
			tabulator.current = new Tabulator(tableRef.current, {
				data: vendors, groupBy: 'cat',
				//ajaxURL: "api/utensils_category", paginationMode: "remote", filterMode: "remote", sortMode: "remote",
				movableColumns: true, ajaxResponse: (_url, p, res) => {
					const page = p.page, size = p.size
					const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));
					res.data = processedData
					return res
				},
				//dataChanged: (data) => { },
				//footerElement: `<tr><td><b>Total Pages: </b><span>${(tabulator.current != undefined) ?  : 0}</span ></td ></tr >`,
				//paginationCounter: "",
				pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitColumns", placeholder: "No matching records found",
				//groupHeader: (value: any, count: number, data: any, group: GroupComponent) => { return value },
				columns: [
					{ title: "Sr.", field: "srno", minWidth: 30, print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Name", field: "name", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", },
					{ title: "Mobile", field: "mobile", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", },
					{ title: "Address", field: "address", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', },
					{ title: "Category", field: "cat", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", },
				],
			});
		}//tabulator.current?.on('tableBuilt', () => {tabulator.current?.redraw();});
		tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
	};

	// Redraw table onresize
	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator.current) {
				tabulator.current.redraw();
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
			}
		});
	};

	useEffect(() => { initVendor() }, []);
	useEffect(() => {
		initTabulator();
		reInitOnResizeWindow();
	}, [vendors]);

	return (
		<>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
				<h2 className="mr-auto text-lg font-medium">Add Vendor</h2>
				<div className="flex w-full mt-4 sm:w-auto sm:mt-0">
					<Button variant="primary" className="mr-2 shadow-md" onClick={() => setHeaderFooterModalPreview(true)} >Add Vendor</Button>
					{/*<Menu className="ml-auto sm:ml-0">*/}
					{/*	<Menu.Button as={Button} className="px-2 font-normal !box">*/}
					{/*		<span className="flex items-center justify-center w-5 h-5"><Lucide icon="Plus" className="w-4 h-4" /></span>*/}
					{/*	</Menu.Button>*/}
					{/*	<Menu.Items className="w-40">*/}
					{/*		<Menu.Item><Lucide icon="FilePlus" className="w-4 h-4 mr-2" /> New Category</Menu.Item>*/}
					{/*		<Menu.Item><Lucide icon="UserPlus" className="w-4 h-4 mr-2" /> New Group</Menu.Item>*/}
					{/*	</Menu.Items>*/}
					{/*</Menu>*/}
				</div>
			</div>
			<div className="p-5 mt-5 intro-y box">
				<div className="overflow-x-auto scrollbar-hidden">
					<div id="tabulator" ref={tableRef} className="mt-5"></div>
				</div>
			</div>
			<Dialog open={headerFooterModalPreview} onClose={() => { setHeaderFooterModalPreview(false); }} initialFocus={sendButtonRef}>
				<Dialog.Panel>
					<Dialog.Title>
						<h2 className="mr-auto text-base font-medium">Add Vendor</h2>
						{/*<Button variant="outline-secondary" className="hidden sm:flex"><Lucide icon="File" className="w-4 h-4 mr-2" />{" "}Download Docs</Button>*/}
						{/*<Menu className="sm:hidden">*/}
						{/*	<Menu.Button className="block w-5 h-5"><Lucide icon="MoreHorizontal" className="w-5 h-5 text-slate-500" /></Menu.Button>*/}
						{/*	<Menu.Items className="w-40"><Menu.Item><Lucide icon="File" className="w-4 h-4 mr-2" />Download Docs</Menu.Item></Menu.Items>*/}
						{/*</Menu>*/}
					</Dialog.Title>
					<Dialog.Description className="grid grid-cols-12 gap-4 gap-y-3">
						<div className="col-span-6 sm:col-span-6">
							<FormLabel>Vendor Name</FormLabel>
							<FormInput type="text" placeholder="Vendor Name"
								onChange={(e) => vendorData.name = e.target.value} />
						</div>
						<div className="col-span-6 sm:col-span-6">
							<FormLabel>Mobile</FormLabel>
							<FormInput type="text" placeholder="Mobile"
								onChange={(e) => vendorData.mobile = e.target.value} />
						</div>
						<div className="col-span-12 sm:col-span-12">
							<FormLabel>Address</FormLabel>
							<FormTextarea placeholder="Address"
								onChange={(e) => vendorData.address = e.target.value} />
						</div>
						<div className="col-span-12 sm:col-span-12">
							<FormLabel>Vendor Category</FormLabel>
							<FormSelect name='category' aria-label="Category"
								onChange={(e) => vendorData.category = e.target.value} >
								<option value={0}>Vendor Category</option>
								{categories.map((x, i) => <option value={x.id} key={i} >{x.name}</option>)}
							</FormSelect>
						</div>
					</Dialog.Description>
					<Dialog.Footer>
						<Button type="button" variant="outline-secondary" onClick={() => { setHeaderFooterModalPreview(false); }} className="w-20 mr-1">Cancel</Button>
						<Button variant="primary" type="button" ref={sendButtonRef}
							onClick={saveVendor}>Add Vendor</Button>
					</Dialog.Footer>
				</Dialog.Panel>
			</Dialog>
			<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${type}`} />
						<div className="mt-5 text-3xl">{title}</div>
						<div className="mt-2 text-slate-500">{msg}</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24">Ok</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
		</>
	);

	async function saveVendor() {
		try {
			showLoader()
			const f = await fetch('/api/vendor', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(vendorData), })
			if (f.ok) {
				const r = await f.json()
				if (r.e) {
					setType('error')
					setTitle(r.d)
					setMsg('Save Filed')
				}
				else {
					setType('success')
					setTitle(r.d)
					setMsg('Save Successful')
				}
				setHeaderFooterModalPreview(false)
				setSuccessModalPreview(true)

				initVendor()
			}
			else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	async function initVendor() {
		try {
			showLoader()
			const f = await fetch('/api/vendor_init')
			if (f.ok) {
				const r = await f.json()
				if (r.e) { console.error(r) }
				else {
					const c = r.d.categories
					r.d.vendors.forEach((u: any, i: number) => {
						u.srno = i + 1;
						u.cat = c.find(x => x.id == u.catid).name
					})

					setVendor(r.d.vendors)
					setCategories(c)
				}
			}
			else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
}