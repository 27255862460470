import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import Button from "../../../base-components/Button";
import { FormInput, FormLabel } from "../../../base-components/Form";
import { Dialog } from "../../../base-components/Headless";
import { useLoader } from "../../../base-components/Loader";
import Lucide from "../../../base-components/Lucide";

export default function Main() {
	const { showLoader, hideLoader } = useLoader()

	const tableRef = createRef<HTMLDivElement>()
	const tabulator = useRef<Tabulator>()

	const tableRef2 = createRef<HTMLDivElement>()
	const tabulator2 = useRef<Tabulator>()

	const sendButtonRef = useRef()

	//var utensilsCategories = []

	const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false);
	const [successModalPreview, setSuccessModalPreview] = useState(false);
	const [modal, setModal] = useState({ msg: '', title: '', type: '' });

	var copyIcon = function () { return '<i data-lucide="copy" class="w-8 h-8 mr-1 text-success"></i> Share'; };
	var shareIcon = function () { return '<i data-lucide="share2" class="w-8 h-8 mr-1 text-success"></i> Share'; };
	var messageIcon = function () { return '<i data-lucide="message-circle" class="w-8 h-8 mr-1 text-success"></i> Share'; };

	//const editedCallback = (cell: CellComponent) => {
	//	const d = cell.getData() as any
	//	const name = d.name
	//}
	const [category, setCategory] = useState('')
	const [utensils, setUtensils] = useState([])
	/*const [utensilsCategories, setUtensilsCategories] = useState([])*/
	//var utensils = []

	const shareText = async (text) => {
		if (navigator.share) {
			try {
				await navigator.share({ text: text, });
			} catch (error) {
				console.error(error);
			}
		} else {
			// Fallback for older browsers
			// ...
		}
	};
	const initTabulator = () => {
		if (tableRef.current) {
			tabulator.current = new Tabulator(tableRef.current, {
				data: utensils, groupBy: "ucat", movableColumns: true,
				pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitColumns", placeholder: "No matching records found",
				//groupHeader: (value: any, count: number, data: any, group: GroupComponent) => { return value },
				columns: [
					{ title: "Sr.", field: "srno", minWidth: 30, print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Vendor Name", field: "name", minWidth: 150, headerFilter: 'input', headerFilterPlaceholder: 'Search Vendor', print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", },
					{ title: "Contact", field: "in", minWidth: 50, headerFilter: 'input', headerFilterPlaceholder: 'Search Contact', print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "number", validator: "required", },
					{ title: "Address", field: "out", minWidth: 50, headerFilter: 'input', headerFilterPlaceholder: 'Search Address', print: true, hozAlign: "center", headerHozAlign: 'center', },
					{ title: "uc", field: "ucat", print: false, hozAlign: "center", visible: false },
					{
						title: "Copy", print: false, formatter: copyIcon, headerSort: false, headerHozAlign: "center", width: 100, hozAlign: "center",
						cellClick: function (_e, row) {
							const id = (row.getData() as any).id;
							shareText(id + '')
							//const index = dataList.findIndex((item) => item.id === id);
							//dataList.splice(index, 1);//tabulator.current?.deleteRow(index+1)
						}
					},
					{
						title: "Share", print: false, formatter: shareIcon, headerSort: false, headerHozAlign: "center", width: 100, hozAlign: "center",
						cellClick: function (_e, row) {
							const id = (row.getData() as any).id;
							shareText(id + '')
							//const index = dataList.findIndex((item) => item.id === id);
							//dataList.splice(index, 1);//tabulator.current?.deleteRow(index+1)
						}
					},
					{
						title: "WhatsApp", print: false, formatter: messageIcon, headerSort: false, headerHozAlign: "center", width: 100, hozAlign: "center",
						cellClick: function (_e, _row) {
							//const id = (row.getData() as any).id
							window.open('https://wa.me/?text=Your%20text%20here')
							//const index = dataList.findIndex((item) => item.id === id);
							//dataList.splice(index, 1);//tabulator.current?.deleteRow(index+1)
						}
					}
				],
			});
		}
		//tabulator.current?.on('tableBuilt', () => {tabulator.current?.redraw();});
		tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });

		if (tableRef2.current) {
			tabulator2.current = new Tabulator(tableRef2.current, {
				data: utensils, groupBy: "ucat", movableColumns: true,
				pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitColumns", placeholder: "No matching records found",
				//groupHeader: (value: any, count: number, data: any, group: GroupComponent) => { return value },
				columns: [
					{ title: "Sr.", field: "srno", minWidth: 30, print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Name", field: "name", minWidth: 150, headerFilter: 'input', headerFilterPlaceholder: 'Search', print: true, hozAlign: "center", headerHozAlign: 'center', },
					{ title: "Vendor 1", field: "in", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', },
					{ title: "Vendor 2", field: "out", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', },
					{ title: "Vendor 3", field: "out", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', },
					{ title: "Vendor 4", field: "out", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', },
					{ title: "uc", field: "ucat", print: false, hozAlign: "center", visible: false },
				],
			});
		}
		//tabulator.current?.on('tableBuilt', () => {tabulator.current?.redraw();});
		tabulator2.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
	};

	// Redraw table onresize
	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator.current) {
				tabulator.current.redraw();
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
			}
			if (tabulator2.current) {
				tabulator2.current.redraw();
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
			}
		});
	};

	const loadData = async () => {
		try {
			showLoader()
			const f = await fetch('/api/utensils')
			if (f.ok) {
				const d = await f.json()
				d.u.forEach((u, i) => {
					u.srno = i + 1;
					u.in = 0;
					u.out = 0;
					u.ucat = d.uc.find(x => x.id == u.catid).name;
				})
				setUtensils(d.u)
				//setUtensilsCategories(d.uc)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	useEffect(() => { loadData() }, [])

	useEffect(() => {
		initTabulator()
		reInitOnResizeWindow()
	}, [utensils])

	return (
		<>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
				<h2 className="mr-auto text-lg font-medium">Order Request Rates</h2>
			</div>
			<div className="p-5 mt-5 intro-y box">
				<div className="overflow-x-auto scrollbar-hidden">
					<div ref={tableRef} className="mt-5"></div>
				</div>
				<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
					<h2 className="mr-auto text-lg font-medium">Vendor Rates</h2>
				</div>
				<div className="overflow-x-auto scrollbar-hidden">
					<div ref={tableRef2} className="mt-5"></div>
				</div>
			</div>
			<Dialog open={headerFooterModalPreview} onClose={() => { setHeaderFooterModalPreview(false); }} initialFocus={sendButtonRef}>
				<Dialog.Panel>
					<Dialog.Title>
						<h2 className="mr-auto text-base font-medium">Add Utensils Category</h2>
					</Dialog.Title>
					<Dialog.Description className="grid grid-cols-12 gap-4 gap-y-3">
						<div className="col-span-12 sm:col-span-12">
							<FormLabel htmlFor="modal-form-1">Utensils Category Name</FormLabel>
							<FormInput id="modal-form-1" type="text" placeholder="Category Name" onChange={(e) => setCategory(e.target.value)} />
						</div>
					</Dialog.Description>
					<Dialog.Footer>
						<Button type="button" variant="outline-secondary" onClick={() => { setHeaderFooterModalPreview(false); }} className="w-20 mr-1">Cancel</Button>
						<Button variant="primary" type="button" ref={sendButtonRef} onClick={saveCategory}>Add Utensils Category</Button>
					</Dialog.Footer>
				</Dialog.Panel>
			</Dialog>
			<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${modal.type}`} />
						<div className="mt-5 text-3xl">{modal.title}</div>
						<div className="mt-2 text-slate-500">{modal.msg}</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24" >Ok</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
		</>
	);
	async function saveCategory() {
		try {
			showLoader()
			const f = await fetch('/api/add_utensils_category', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ name: category }), })
			if (f.ok) {
				const r = await f.json()
				if (r.e) setModal({ type: 'error', title: r.d, msg: 'Save Filed' })
				else setModal({ type: 'success', title: r.d, msg: 'Save Successful' })
				setHeaderFooterModalPreview(false)
				setSuccessModalPreview(true)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
}