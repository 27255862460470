import { useEffect, useState } from "react";
import Button from "../../../base-components/Button";
import FormInput from "../../../base-components/Form/FormInput";
import Dialog from "../../../base-components/Headless/Dialog";
import Litepicker from "../../../base-components/Litepicker";
import Lucide from "../../../base-components/Lucide";
import { FormTextarea } from "../../../base-components/Form";
import tippy from "tippy.js";
import { useLoader } from "../../../base-components/Loader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";

type IncomeExpense = {
	name: string,
	narration: string,
	amt: number,
}

export default function Main() {
	const { showLoader, hideLoader } = useLoader();
	const [income, setIncome] = useState([]);
	const [expense, setExpense] = useState([]);
	const [receivable, setReceivable] = useState([]);
	const [payable, setPayable] = useState([]);

	const [ie, setIE] = useState<IncomeExpense>({ name: '', narration: '', amt: 0, });
	const [date, setDate] = useState('');

	const [newEventModalPreview, setnewEventModalPreview] = useState(false);
	const [type, setType] = useState(0);
	const [title, setTitle] = useState('');
	const [dateStr, setDateStr] = useState('');
	const opt = ['Income', 'Expense', 'Receivable', 'Payable']

	const nav = useNavigate()
	const eventId = useSelector((state: RootState) => state.eventId.eventId);

	useEffect(() => {
		if (eventId == null) nav('/admin')
		else init()
	}, [])
	useEffect(() => {
		if (type != 0) {
			setTitle(opt[type - 1])
			switch (type) {
				case 1: setDateStr('Payment'); break;
				case 2: setDateStr('Receivable'); break;
				case 3: setDateStr('Notification'); break;
				case 4: setDateStr('Payment Schedule'); break;
			}
			setnewEventModalPreview(true);
		}
	}, [type])

	return eventId != null && <>
		<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
			<h2 className="mr-auto text-lg font-medium">Income & Expense</h2>
		</div>
		<div className="grid grid-cols-12 gap-2 mt-2">
			<div className="col-span-12 intro-y lg:col-span-6">
				<div className="intro-y box h-full mt-2">
					<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
						<h2 className="mr-auto text-base font-medium">{opt[0]}</h2>
						<div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
							<Button variant="soft-primary" className="" onClick={() => { setType(1) }}>
								<Lucide icon='Plus' className='h-4' /> Add
							</Button>
						</div>
					</div>
					<div className="p-5 text-center">
						{income.length > 0 ? income.map((inc, i) => (
							<div className='flex' onMouseOver={(e) => {
								const txt = `Name: ${inc.partyName}, Date: ${inc.date}`
								tippy(e.currentTarget, { allowHTML: true, theme: 'light', content: txt })
							}}>
								<label>{(i + 1) + '. ' + inc.narration}</label>
								<b className='ml-auto'>{inc.amt}</b>
							</div>)) : <label className='text-center'>Not found</label>
						}
					</div>
				</div>
				<div className="intro-y box h-full mt-2">
					<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
						<h2 className="mr-auto text-base font-medium">{opt[1]}</h2>
						<div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
							<Button variant="soft-primary" className="" onClick={() => { setType(2) }}>
								<Lucide icon='Plus' className='h-4' /> Add
							</Button>
						</div>
					</div>
					<div className="p-5 text-center">
						{expense.length > 0 ? expense.map((exp, i) => (
							<div className='flex' onMouseOver={(e) => {
								const txt = `Name: ${exp.partyName}, Date: ${exp.date}`
								tippy(e.currentTarget, { allowHTML: true, theme: 'light', content: txt })
							}}>
								<label>{(i + 1) + '. ' + exp.narration}</label>
								<b className='ml-auto'>{exp.amt}</b>
							</div>)) : <label>Not found</label>
						}
					</div>
				</div>
			</div>
			<div className="col-span-12 intro-y lg:col-span-6">
				<div className="intro-y box h-full mt-2">
					<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
						<h2 className="mr-auto text-base font-medium">{opt[2]}</h2>
						<div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
							<Button variant="soft-primary" className="" onClick={() => { setType(3) }}>
								<Lucide icon='Plus' className='h-4' /> Add
							</Button>
						</div>
					</div>
					<div className="p-5 text-center">
						{receivable.length > 0 ? receivable.map((rec, i) => (
							<div className='flex' onMouseOver={(e) => {
								const txt = `Name: ${rec.partyName}, Date: ${rec.date}`
								tippy(e.currentTarget, { allowHTML: true, theme: 'light', content: txt })
							}}>
								<label>{(i + 1) + '. ' + rec.narration}</label>
								<b className='ml-auto'>{rec.amt}</b>
							</div>)) : <label className='text-center'>Not found</label>
						}
					</div>
				</div>
				<div className="intro-y box h-full mt-2">
					<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
						<h2 className="mr-auto text-base font-medium">{opt[3]}</h2>
						<div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
							<Button variant="soft-primary" className="" onClick={() => { setType(4) }}>
								<Lucide icon='Plus' className='h-4' /> Add
							</Button>
						</div>
					</div>
					<div className="p-5 text-center">
						{payable.length > 0 ? payable.map((pay, i) => (
							<div className='flex' onMouseOver={(e) => {
								const txt = `Name: ${pay.partyName}, Date: ${pay.date}`
								tippy(e.currentTarget, { allowHTML: true, theme: 'light', content: txt })
							}}>
								<label>{(i + 1) + '. ' + pay.narration}</label>
								<b className='ml-auto'>{pay.amt}</b>
							</div>)) : <label className='text-center'>Not found</label>
						}
					</div>
				</div>
			</div>
		</div>
		<Dialog open={newEventModalPreview} onClose={() => { setnewEventModalPreview(false); }}>
			<Dialog.Panel>
				<Dialog.Title>
					<h2 className="mr-auto text-base font-medium">{title}</h2>
					<Button className="w-24 ml-auto" onClick={() => { setnewEventModalPreview(false); }}>
						<Lucide icon='XCircle' />
					</Button>
				</Dialog.Title>
				<div className="mx-5 my-3">
					<label className='font-medium'>Party Name:</label>
					<FormInput placeholder='Party Name' className='mb-5' value={ie.name}
						onChange={(e) => { setIE({ ...ie, name: e.target.value }) }} />
					<label className='font-medium'>Narration:</label>
					<FormTextarea placeholder='Narration' className='mb-5' value={ie.narration}
						onChange={(e) => { setIE({ ...ie, narration: e.target.value }) }} />
					<label className='font-medium'>Amount:</label>
					<FormInput type='number' placeholder='Amount' className='mb-5' value={ie.amt}
						onChange={(e) => { setIE({ ...ie, amt: Number(e.target.value) }) }} />
					<label className='font-medium'>Date: </label>{/*onChange={setDaterange} value={daterange}*/}
					<Litepicker placeholder={dateStr + ' Date'} value={date}
						options={{
							autoApply: true, showWeekNumbers: false, format: 'DD/MM/YYYY',
							dropdowns: { minYear: 1990, maxYear: null, months: true, years: true, },
						}} onChange={setDate} />
				</div>
				<Dialog.Footer className='mt-10'>
					<Button type="button" variant="primary" className="w-24 mr-2"
						onClick={() => {
							setnewEventModalPreview(false)
							save()
						}}>Save</Button>
				</Dialog.Footer>
			</Dialog.Panel>
		</Dialog>
	</>;

	async function save() {
		try {
			showLoader()
			const f = await fetch('/api/income_expense/' + eventId, { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ ...ie, type, date }), })
			if (f.ok) {
				const x = await f.json()
				if (x.e) console.error(x); else init()
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	async function init() {
		try {
			showLoader()
			const f = await fetch(`/api/income_expense/${eventId}/${type}`)
			if (f.ok) {
				const x = await f.json()
				if (x.e) { console.error(x) }
				else {
					switch (type) {
						case 0:
							setIncome(x.d[0])
							setExpense(x.d[1])
							setReceivable(x.d[2])
							setPayable(x.d[3])
							break;
						case 1: setIncome(x.d); break;
						case 2: setExpense(x.d); break;
						case 3: setReceivable(x.d); break;
						case 4: setPayable(x.d); break;
					}
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
}