import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import tippy from 'tippy.js';
import Button from "../../../base-components/Button";
import { FormLabel } from '../../../base-components/Form';
import { Dialog } from "../../../base-components/Headless";
import Litepicker from "../../../base-components/Litepicker";
import { useLoader } from '../../../base-components/Loader';
import Lucide from '../../../base-components/Lucide';
import { setDateRange } from "../../../stores/calendarDateRangeSlice";
import { setEventId } from "../../../stores/eventIdSlice";
import { useAppDispatch } from '../../../stores/hooks';

function Main() {
	const styles = '.fc-event:hover{cursor:pointer;}'
	const { showLoader, hideLoader } = useLoader();
	const calendarRef = useRef(null);

	//const dispatch = useDispatch();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [events, setEvents] = useState([]);
	const [createNewEventModal, setCreateNewEventModal] = useState(false);
	const [eventModalPreview, seteventModalPreview] = useState(false);
	const [newEventModalPreview, setnewEventModalPreview] = useState(false);
	const [daterange, setDaterange] = useState('');
	const [currDate, setCurrDate] = useState(formatDateWithIntl(new Date()));
	const [followUpDate, setFollowUpDate] = useState(formatDateWithIntl(new Date()));
	const [followUpId, setFollowUpId] = useState(0);

	const options: CalendarOptions = {
		initialDate: new Date(), eventInteractive: true, weekNumbers: true, navLinks: false, droppable: false, editable: false, dayMaxEvents: true, defaultAllDay: true,
		plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
		headerToolbar: { left: "prevYear,prev,next,nextYear today", center: "title", right: "dayGridMonth,listMonth,timeGridWeek,listWeek,timeGridDay", },
		datesSet: (d) => fetchEventlist(d.start, d.end), eventDisplay: 'block', events: events, selectable: true,
		dateClick: function (dca) {
			const e = dca.date
			e.setSeconds(-1)
			const selectedOption = parseInt(localStorage.getItem('LastEventStatus') || '')
			const startDate = formatDateWithIntl(dca.date)
			const endDate = formatDateWithIntl(e)
			const dr = startDate + ' - ' + endDate
			setDaterange(dr)
			dispatch(setDateRange(dr));
			(selectedOption == 0) ? setnewEventModalPreview(true) : seteventModalPreview(true)
		},
		select: (dsa) => {
			const e = dsa.end
			e.setSeconds(-1)
			const selectedOption = parseInt(localStorage.getItem('LastEventStatus') || '')
			const startDate = formatDateWithIntl(dsa.start)
			const endDate = formatDateWithIntl(e)
			const dr = startDate + ' - ' + endDate
			setDaterange(dr)
			dispatch(setDateRange(dr));
			(selectedOption == 0) ? setnewEventModalPreview(true) : seteventModalPreview(true)
		},
		eventDidMount: function (info) {
			if (info.event.backgroundColor == '#d2d9ef') {
				info.el.oncontextmenu = (e) => {//e.preventDefault();
					setFollowUpId(Number(info.event.id))
					showContextMenu(e)
				};
			}
		},
		eventClick: (info) => {
			const eventId = info.event.id
			dispatch(setEventId(eventId));
			const fromDate = info.event.start, toDate = info.event.end;
			const enddate = (toDate == null) ? fromDate : toDate;
			const dr = formatDateWithIntl(fromDate) + "-" + formatDateWithIntl(enddate)
			dispatch(setDateRange(dr));
			navigate(`/admin/client?eventId=${eventId}`);
		},
		eventMouseEnter: (info) => tippy(info.el, { allowHTML: true, theme: 'light', content: info.event.extendedProps.tooltip }),
		eventContent: (eventInfo) => {
			const { title, start } = eventInfo.event;
			const viewType = eventInfo.view.type;
			if (viewType === "listWeek" || viewType === "listMonth") {
				return (
					<>
						<span>{title}</span>
						<span> ({start.toLocaleString()})</span><br />
						<span dangerouslySetInnerHTML={{ __html: eventInfo.event.extendedProps.tooltip, }} />
						<button onClick={() => alert("Custom button clicked!")}>Click Me</button>
					</>
				);
			}
			return <>
				<div className="fc-event-main-frame">
					<div className="fc-event-title-container">
						<div className="fc-event-title fc-sticky">{title}</div>
					</div>
				</div>
			</>;
		},//dateClick: (dca) => {},
	};

	useEffect(() => { handleGoToDate() }, [currDate]);

	const [isShown, setIsShown] = useState(false);

	const [position, setPosition] = useState({ x: 0, y: 0 });

	const showContextMenu = (event: MouseEvent) => {
		event.preventDefault();

		setIsShown(false);
		const newPosition = { x: event.pageX, y: event.pageY, };

		setPosition(newPosition);
		setIsShown(true);
	};

	const hideContextMenu = () => { setIsShown(false); };

	return (
		<>{<style>{styles}</style>}
			<div className='flex'>
				<div className='flex ml-auto'>
					<FormLabel className='flex align-center mt-5 mr-2 text-md font-medium'>Go to Date:</FormLabel>
					<div className="relative my-3 text-slate-500">
						<Lucide icon="Calendar" className="absolute inset-y-0 left-0 z-10 w-4 h-4 my-auto ml-3" />
						<Litepicker className="pl-10 sm:w-56 !box" value={currDate} onChange={setCurrDate}
							options={{
								autoApply: false, showWeekNumbers: false, format: 'DD/MM/YYYY',
								dropdowns: { minYear: (new Date()).getFullYear(), maxYear: (new Date()).getFullYear() + 10, months: true, years: true, },
							}} />
					</div>
				</div>
			</div>
			<div className="full-calendar"><FullCalendar ref={calendarRef} {...options} /></div>
			<Dialog staticBackdrop open={createNewEventModal} onClose={() => { setCreateNewEventModal(false); }}>
				<Dialog.Panel className="px-5 py-10">
					<div className="text-center">
						<div className="mb-5">Do you want to create a new event ?</div>
						<Button type="button" variant="primary" className="w-24 mr-2"
							onClick={() => {
								seteventModalPreview(true);
								setCreateNewEventModal(false);
							}}>Yes</Button>
						<Button m-3 className="w-24" type="button" variant="primary" onClick={() => { setCreateNewEventModal(false); }}>No</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
			<Dialog staticBackdrop open={eventModalPreview} onClose={() => { seteventModalPreview(false); }}>
				<Dialog.Panel className="" style={{ width: 560 }}>
					<Dialog.Title> <h2 className="mr-auto text-base font-medium">Event</h2> </Dialog.Title>
					<div className="text-center">
						<div className="mb-1">
							<div className="px-4 py-1 eventcard">
								<div><label>Select Date: </label></div>
								<div className="m-3">
									<Litepicker onChange={setDaterange} value={daterange} options={{ autoApply: true, showWeekNumbers: false, singleMode: false, numberOfColumns: 2, numberOfMonths: 2, format: 'DD/MM/YYYY', dropdowns: { minYear: 1990, maxYear: null, months: true, years: true, }, }} className="block w-96 mx-auto" />
								</div>
							</div>
						</div>
						<div>
							<Button type="button" variant="primary" onClick={addEvent} className="w-24 " > Save </Button>
							<Button type="button" variant="primary" className="w-24 m-2" onClick={() => { seteventModalPreview(false); }}>Cancel</Button>
						</div>
					</div>
				</Dialog.Panel>
			</Dialog>
			<Dialog staticBackdrop open={newEventModalPreview} onClose={() => { setnewEventModalPreview(false); }}>
				<Dialog.Panel className="px-5 py-10">
					<div className="text-center">
						<div className="mb-5"> Previous Event is in process.Do you want to create a new event? </div>
						<Button type="button" variant="primary" className="w-24 mr-2"
							onClick={() => {
								seteventModalPreview(true)
								setnewEventModalPreview(false)
							}}>Yes</Button>
						<Button m-3 type="button" variant="primary" className="w-24"
							onClick={() => { setnewEventModalPreview(false); }}>No</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
			{isShown && (
				<>
					<div onClick={hideContextMenu} className='fixed w-full h-full' style={{ zIndex: 51, background: 'rgba(255,255,255,0.3)', top: 0, left: 0 }} ></div>
					<div style={{ position: 'fixed', zIndex: 52, top: position.y, left: position.x, }} className="custom-context-menu">
						<div className="intro-y box">
							<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
								<h2 className="mr-auto text-base font-medium">Basic Tooltip</h2>
								<div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
									<div onClick={hideContextMenu}><Lucide icon='XCircle' /></div>
								</div>
							</div>
							<div className="p-5">
								<div className="text-center">
									<div className='ml-auto'>
										<FormLabel className='flex align-center mr-2 text-md font-medium'>Follow Up Date:</FormLabel>
										<div className="relative my-1 text-slate-500">
											<Lucide icon="Calendar" className="absolute inset-y-0 left-0 z-10 w-4 h-4 my-auto ml-3" />
											<Litepicker className="pl-10 sm:w-56 " value={followUpDate}
												onChange={setFollowUpDate} placeholder='Follow Up Date'
												options={{ autoApply: false, showWeekNumbers: false, format: 'DD/MM/YYYY', dropdowns: { minYear: (new Date()).getFullYear(), maxYear: (new Date()).getFullYear() + 10, months: true, years: true, }, }} />
										</div>
									</div>
									<Button variant="soft-primary" className='mt-2' onClick={() => { changeFollowUpDate() }} >Save</Button>
								</div>
							</div>
						</div>
					</div>
				</>)}
		</>
	);

	async function changeFollowUpDate() {
		try {
			showLoader()
			const f = await fetch("/api/followup", { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ id: followUpId, date: followUpDate }), })
			if (f.ok) { setIsShown(false) } else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	function handleGoToDate() {
		if (calendarRef.current) {
			const date = parseDate(currDate)
			calendarRef.current.getApi().gotoDate(date);
		}
	}
	async function fetchEventlist(from: Date, to: Date) {
		try {
			showLoader()
			const f = await fetch("/api/events", { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ from: from, to: to }), })
			if (f.ok) {
				const r = await f.json()
				setEvents(r)
				if (r.length == 0) localStorage.clear()
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	function formatDateWithIntl(date: Date): string { return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date); }
	async function addEvent() {
		try {
			showLoader()
			const dates = daterange.split('-')
			const f = await fetch('/api/add_event', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ from: dates[0].trim(), to: dates[1].trim() }), })
			if (f.ok) {
				const j = await f.json()
				const eventId = j.id, Eventstatus = j.status

				seteventModalPreview(false)
				dispatch(setEventId(eventId))
				localStorage.setItem('LastEventStatus', Eventstatus)

				const calendarApi = calendarRef.current.getApi()
				const startDate = calendarApi.view.currentStart, endDate = calendarApi.view.currentEnd

				fetchEventlist(startDate, endDate)
				navigate('/admin/client')
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	function parseDate(dateString: string): Date | null {
		const parts = dateString.split('/');
		if (parts.length !== 3) return null;
		// Months are 0-indexed
		const day = parseInt(parts[0], 10), month = parseInt(parts[1], 10) - 1, year = parseInt(parts[2], 10);
		return new Date(year, month, day);
	}
}

export default Main;