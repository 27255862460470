import FormInput from "../../../../base-components/Form/FormInput";

export default function EditRow({ index, data, handleEditForm }:
	{
		handleEditForm: (e: any) => void;
		data: PriorityData;
		index: number;
	}) {
	return (<>
		<tr>
			<td className="text-center px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
				<FormInput type="text" name="content" onChange={handleEditForm} value={data.category} placeholder={data.category} required disabled /></td>
			<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
				<div className="flex justify-center align-center gap-2">
					{
						data.qty.map((number: number, i: number) => (<>
							<FormInput type="number" min={0} placeholder="Enter Quantity"
								onKeyDown={(e) => ["ArrowUp", "ArrowDown", "e", "E"].includes(e.key) && e.preventDefault()}
								name={"qty" + i + '-' + index} onChange={handleEditForm} value={number} required />
						</>))
					}
				</div>
			</td>
			{/*<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">*/}
			{/*	<div className="flex align-center justify-center gap-2">*/}
			{/*		<Button onClick={handleEditFormSubmit} rounded variant="outline-success">Save</Button>{' '}*/}
			{/*	</div>*/}
			{/*</td>*/}
		</tr>
	</>);
}