import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import * as xlsx from "xlsx";
import '../../../assets/css/tabulator-edit.css';
import Button from "../../../base-components/Button";
import { FormInput, FormLabel, FormSelect, FormTextarea } from "../../../base-components/Form";
import { Dialog, Menu } from "../../../base-components/Headless";
import { useLoader } from "../../../base-components/Loader";
import Lucide from "../../../base-components/Lucide";

export default function Main() {
	const { showLoader, hideLoader } = useLoader()

	const tableRef = createRef<HTMLDivElement>()
	const tabulator = useRef<Tabulator>()

	const sendButtonRef = useRef()

	const [employees, setEmployees] = useState([])

	const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false)
	const [successModalPreview, setSuccessModalPreview] = useState(false)
	const [modal, setModal] = useState({ msg: '', title: '', type: '' })

	const [employee, setEmployee] = useState({ name: '', address: '', mail: '', username: '', password: '' })

	const [filter, setFilter] = useState({ field: "name", type: "like", value: "", })

	var deleteIcon = function () { return '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>' }

	//const editedCallback = (cell: CellComponent) => {
	//	const d = cell.getData() as any
	//	const name = d.name
	//}
	const [totPages, setTotPages] = useState(0)
	//const [category, setCategory] = useState('')

	const initTabulator = () => {
		if (tableRef.current) {
			tabulator.current = new Tabulator(tableRef.current, {
				data: employees,//, groupBy: "ucat",
				//ajaxURL: "api/utensils_category", paginationMode: "remote", filterMode: "remote", sortMode: "remote",
				movableColumns: true, ajaxResponse: (_url, p, res) => {
					setTotPages(res.last_page)
					const page = p.page, size = p.size
					const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));
					res.data = processedData
					return res
				},
				//dataChanged: (data) => {  },
				//footerElement: `<tr><td><b>Total Pages: </b><span>${(tabulator.current != undefined) ?  : 0}</span ></td ></tr >`,
				//paginationCounter: "",
				pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitColumns", placeholder: "No matching records found",
				columns: [//    
					{ title: "Serial Number", field: "srno", minWidth: 200, print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Username", field: "username", headerFilter: "input", minWidth: 200, headerFilterPlaceholder: "Search Username", print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", },
					{ title: "Name", field: "name", headerFilter: "input", minWidth: 200, headerFilterPlaceholder: "Search Name", print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", },
					{ title: "Address", field: "address", minWidth: 200, headerFilter: "number", headerFilterPlaceholder: "Search Address", print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "number", validator: "required", },
					{ title: "Mail", field: "mail", minWidth: 200, headerFilter: "number", headerFilterPlaceholder: "Search Mail", print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "number", validator: "required", },
					{ title: "Password", field: "password", print: true, hozAlign: "center", headerHozAlign: 'center', visible: false },
					{
						title: "Actions", print: false, formatter: deleteIcon, headerSort: false, headerHozAlign: "center", width: 100, hozAlign: "center",
						cellClick: function (_e, _row) {
							//const id = (row.getData() as any).id;
							alert('Currently Delete not available')
							//const index = dataList.findIndex((item) => item.id === id);
							//dataList.splice(index, 1);//tabulator.current?.deleteRow(index+1)
						}
					}
				],
			});
		}
		//tabulator.current?.on('tableBuilt', () => {tabulator.current?.redraw();});
		tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }) })
	}

	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator.current) {
				tabulator.current.redraw()
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", })
			}
		})
	}

	const onFilter = () => { if (tabulator.current) { tabulator.current.setFilter(filter.field, filter.type, filter.value) } }

	const onResetFilter = () => {
		setFilter({ ...filter, field: "name", type: "like", value: "", })
		onFilter()
	}

	const onPrint = () => { if (tabulator.current) tabulator.current.print(); };
	const onExportCsv = () => { if (tabulator.current) tabulator.current.download("csv", "data.csv"); };
	const onExportJson = () => { if (tabulator.current) tabulator.current.download("json", "data.json"); };
	const onExportHtml = () => { if (tabulator.current) tabulator.current.download("html", "data.html", { style: true, }); };

	const onExportXlsx = () => {
		if (tabulator.current) {
			(window as any).XLSX = xlsx;
			tabulator.current.download("xlsx", "data.xlsx", { sheetName: "Products", });
		}
	};
	//var utensils = []
	//const showList = () => console.info(JSON.stringify(utensils))

	//const ucFormatter = (cell: CellComponent) => {
	//	const d = cell.getData() as any
	//	const c = utensilsCategories.find(x => x.id == d.catid)
	//	return c.name
	//};

	const loadData = async () => {
		try {
			showLoader()
			const f = await fetch('/api/employees')
			if (f.ok) {
				const d = await f.json()
				d.employees.forEach((u: any, i: number) => { u.srno = i + 1; })
				setEmployees(d.employees)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) }
		finally { hideLoader() }
	}

	const createEmployee = async () => {
		try {
			showLoader()
			const f = await fetch('/api/create_employee', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(employee), })
			if (f.ok) {
				const r = await f.json()
				if (r.e) { setModal({ type: 'error', title: r.d, msg: 'Save Filed' }) }
				else {
					setModal({ type: 'success', title: r.d, msg: 'Save Successful' })

					setHeaderFooterModalPreview(false)
					setSuccessModalPreview(true)

					loadData()
				}
			} else setModal({ type: 'error', title: f.status + ' ' + f.statusText, msg: 'Save Filed' })
		}
		catch (e) { setModal({ type: 'error', title: e.message, msg: 'Save Filed' }) }
		finally { showLoader() }
	}

	useEffect(() => { loadData() }, [])

	useEffect(() => {
		initTabulator()
		reInitOnResizeWindow()
	}, [employees])

	return <>
		<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
			<h2 className="mr-auto text-lg font-medium">Create Employee</h2>
			<div className="flex w-full mt-4 sm:w-auto sm:mt-0">

				<Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto" variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" />Print</Button>
				<Menu className="w-1/2 sm:w-auto">
					<Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">
						<Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export
						<Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />
					</Menu.Button>
					<Menu.Items className="w-40">
						<Menu.Item onClick={onExportCsv}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export CSV</Menu.Item>
						<Menu.Item onClick={onExportJson}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export JSON</Menu.Item>
						<Menu.Item onClick={onExportXlsx}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export XLSX</Menu.Item>
						<Menu.Item onClick={onExportHtml}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export HTML</Menu.Item>
					</Menu.Items>
					<Button variant="primary" className="mx-2" onClick={(event: React.MouseEvent) => {
						event.preventDefault();
						setHeaderFooterModalPreview(true);
					}} >Add New Employee</Button>
				</Menu>
				{/*<Button variant="primary" className="mr-2 shadow-md" onClick={(e) => { }} >Add Menu</Button>*/}
				{/*<Menu className="ml-auto sm:ml-0">*/}
				{/*	<Menu.Button as={Button} className="px-2 font-normal !box">*/}
				{/*		<span className="flex items-center justify-center w-5 h-5"><Lucide icon="Plus" className="w-4 h-4" /></span>*/}
				{/*	</Menu.Button>*/}
				{/*	<Menu.Items className="w-40">*/}
				{/*		<Menu.Item><Lucide icon="FilePlus" className="w-4 h-4 mr-2" /> New Category</Menu.Item>*/}
				{/*		<Menu.Item><Lucide icon="UserPlus" className="w-4 h-4 mr-2" /> New Group</Menu.Item>*/}
				{/*	</Menu.Items>*/}
				{/*</Menu>*/}
			</div>
		</div>
		<div className="p-5 mt-5 intro-y box">
			<div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
				<div className="xl:flex sm:mr-auto"></div>
				<form id="tabulator-html-filter-form" className="xl:flex sm:mr-auto"
					onSubmit={(e) => { e.preventDefault(); onFilter(); }} >
					<div className="items-center sm:flex sm:mr-4">
						<label className="flex-none w-12 mr-2 xl:w-auto xl:flex-initial">Field</label>
						<FormSelect id="tabulator-html-filter-field" value={filter.field}
							onChange={(e) => { setFilter({ ...filter, field: e.target.value, }); }}
							className="w-full mt-2 2xl:w-full sm:mt-0 sm:w-auto">
							<option value="name">Name</option>
							<option value="in">Inward</option>
							<option value="out">Outward</option>
						</FormSelect>
					</div>
					<div className="items-center mt-2 sm:flex sm:mr-4 xl:mt-0">
						<label className="flex-none w-12 mr-2 xl:w-auto xl:flex-initial">Type</label>
						<FormSelect id="tabulator-html-filter-type" value={filter.type}
							onChange={(e) => { setFilter({ ...filter, type: e.target.value, }); }}
							className="w-full mt-2 sm:mt-0 sm:w-auto">
							<option value="like">like</option>
							<option value="=">=</option>
							<option value="<">&lt;</option>
							<option value="<=">&lt;=</option>
							<option value=">">&gt;</option>
							<option value=">=">&gt;=</option>
							<option value="!=">!=</option>
						</FormSelect>
					</div>
					<div className="items-center mt-2 sm:flex sm:mr-4 xl:mt-0">
						<label className="flex-none w-12 mr-2 xl:w-auto xl:flex-initial">Value</label>
						<FormInput id="tabulator-html-filter-value" value={filter.value}
							onChange={(e) => { setFilter({ ...filter, value: e.target.value, }); }}
							type="text" className="mt-2 sm:w-40 2xl:w-full sm:mt-0" placeholder="Search..." />
					</div>
					<div className="mt-2 xl:mt-0">
						<Button id="tabulator-html-filter-go" className="w-full sm:w-16" variant="primary" type="button" onClick={onFilter}>Go</Button>
						<Button id="tabulator-html-filter-reset" className="w-full mt-2 sm:w-16 sm:mt-0 sm:ml-1" variant="secondary" type="button" onClick={onResetFilter}>Reset</Button>
					</div>
				</form>
				<div className="flex mt-5 sm:mt-0"></div>
			</div>
			<div className="overflow-x-auto scrollbar-hidden">
				<div id="tabulator" ref={tableRef} className="mt-5"></div>
			</div>
			<div className='flex w-full'>
				<span className='ml-auto mr-10'><b>Total Pages: </b>{totPages}</span>
			</div>
		</div>
		<Dialog open={headerFooterModalPreview} onClose={() => { setHeaderFooterModalPreview(false); }} initialFocus={sendButtonRef}>
			<Dialog.Panel>
				<Dialog.Title>
					<h2 className="mr-auto text-base font-medium">Add Employee</h2>
				</Dialog.Title>
				<Dialog.Description className="grid grid-cols-12 gap-4 gap-y-3">
					<div className="col-span-12 sm:col-span-12">
						<FormLabel>Employee Name</FormLabel>
						<FormInput type="text" placeholder="Employee Name"
							onChange={(e) => setEmployee({ ...employee, name: e.target.value })} />
					</div>
					<div className="col-span-12 sm:col-span-12">
						<FormLabel>Email Id</FormLabel>
						<FormInput type="text" placeholder="Email Id"
							onChange={(e) => setEmployee({ ...employee, mail: e.target.value })} />
					</div>
					<div className="col-span-6 sm:col-span-6">
						<FormLabel>Username</FormLabel>
						<FormInput type="text" placeholder="Username"
							onChange={(e) => setEmployee({ ...employee, username: e.target.value })} />
					</div>
					<div className="col-span-6 sm:col-span-6">
						<FormLabel>Password</FormLabel>
						<FormInput type="text" placeholder="Password"
							onChange={(e) => setEmployee({ ...employee, password: e.target.value })} />
					</div>
					<div className="col-span-12 sm:col-span-12">
						<FormLabel>Address</FormLabel>
						<FormTextarea placeholder="Address" onChange={(e) => setEmployee({ ...employee, address: e.target.value })} />
					</div>
				</Dialog.Description>
				<Dialog.Footer>
					<Button type="button" variant="outline-secondary" onClick={() => { setHeaderFooterModalPreview(false); }} className="w-20 mr-1">Cancel</Button>
					<Button variant="primary" type="button" ref={sendButtonRef} onClick={() => createEmployee()}>Add New Employee</Button>
				</Dialog.Footer>
			</Dialog.Panel>
		</Dialog>
		<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
			<Dialog.Panel>
				<div className="p-5 text-center">
					<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${modal.type}`} />
					<div className="mt-5 text-3xl">{modal.title}</div>
					<div className="mt-2 text-slate-500">{modal.msg}</div>
				</div>
				<div className="px-5 pb-8 text-center">
					<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24" > Ok </Button>
				</div>
			</Dialog.Panel>
		</Dialog>
	</>
}