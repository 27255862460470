// LoaderContext.tsx
import React, { createContext, ReactNode, useContext, useState } from 'react';

interface LoaderContextProps {
	isLoading: boolean;
	showLoader: () => void;
	hideLoader: () => void;
}

const LoaderContext = createContext<LoaderContextProps | undefined>(undefined);

export const LoaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [isLoading, setIsLoading] = useState(false);

	const hideLoader = () => {
		var v = document.querySelector('#loader')
		if (v) v.classList.add('hidden')
		setIsLoading(true);
	};

	const showLoader = () => {
		var v = document.querySelector('#loader')
		if (v) v.classList.remove('hidden')
		setIsLoading(false);
	};

	return <LoaderContext.Provider value={{ isLoading, hideLoader, showLoader }}>{children}</LoaderContext.Provider>
};
export function Loader() {
	return <div className='hidden' id='loader'>
		<div className="z-[52] flex flex-col items-center justify-center fixed top-0 left-0 right-0 bottom-0 w-full h-screen overflow-hidden backdrop-blur-[1px] ">
			<div className='rounded-lg p-5 flex flex-col items-center justify-center bg-gray-600 backdrop-blur-xl backdrop-opacity-5'>
				<div className="animate-spin loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4 border-t-primary"></div>
				<h2 className="text-center text-white text-xl font-semibold">Loading...</h2>
				<p className="w-5/6 text-center text-white">This may take a few seconds, please don't close this page.</p>
				{/*<Button variant="danger" className="w-32 mt-4 mb-2 mr-2">*/}
				{/*	<Lucide icon="XCircle" className="w-4 h-4 mr-2" /> Cancel*/}
				{/*</Button>*/}
			</div>
		</div>
	</div>
}

export const useLoader = () => {
	const context = useContext(LoaderContext);

	if (!context) {
		throw new Error('useLoader must be used within a LoaderProvider');
	}

	return context;
};