/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from "../../../../base-components/Button";
import { FormSelect } from "../../../../base-components/Form";
import FormInput from "../../../../base-components/Form/FormInput";

const DishMasterForm: React.FC<{
	handleLoadForm: (event: any) => void;
	handleLoadSubmitForm: (event: React.FormEvent<HTMLFormElement>) => void;
	dm: any;
	categories: any;
}> = ({ handleLoadForm, handleLoadSubmitForm, dm, categories }) => {
	return <form onSubmit={handleLoadSubmitForm}>
		<div className="flex flex-col justify-center align-center sm:flex-row gap-2">
			<FormInput type="text" list={'dishes'} name="dish" placeholder="Dish Name" onChange={handleLoadForm} required autoComplete="off" />
			<FormInput type="text" className="col-span-3" name="stall" value={dm.stall} placeholder="Stall Details" onChange={handleLoadForm} required />
			<FormSelect onChange={handleLoadForm} name='priority' value={dm.priority} aria-label="Priority" required>
				<option value='0'>Priority</option>
				<option value='1'>1</option>
				<option value='2'>2</option>
				<option value='3'>3</option>
			</FormSelect>
			<FormSelect onChange={handleLoadForm} name='category' value={dm.category} aria-label="Priority" required>
				<option value='0'>Category</option>
				{(categories != undefined) ? categories.map((p: any) => <option value={p.value} key={p.value}>{p.text}</option>) : <option>Nothing to show</option>}
			</FormSelect>
			<Button variant="primary" type="submit">Load</Button>
		</div>
	</form>
};

export default DishMasterForm;