/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import Button from "../../../base-components/Button";
import FormInput from "../../../base-components/Form/FormInput";
import FormLabel from "../../../base-components/Form/FormLabel";
import FormSelect from "../../../base-components/Form/FormSelect";
import Dialog from "../../../base-components/Headless/Dialog";
import Litepicker from "../../../base-components/Litepicker";
import { useLoader } from "../../../base-components/Loader";
import Lucide from "../../../base-components/Lucide";

export default function Main() {
	const { showLoader, hideLoader } = useLoader()
	const tableRef = createRef<HTMLDivElement>()
	const tabulator = useRef<Tabulator>()

	const sendButtonRef = useRef()

	const [utensilsCategories, setUtensilsCategories] = useState([])
	const [utensils, setUtensils] = useState([])

	//const [date, setDate] = useState('');
	const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false)
	const [successModalPreview, setSuccessModalPreview] = useState(false)

	const [modal, setModal] = useState({ msg: '', title: '', type: '' })
	const [inventory, setInventory] = useState({ name: '', date: '', qty: 0, category: 0 })

	//const [category, setCategory] = useState('')

	const initTabulator = () => {
		if (tableRef.current) {
			tabulator.current = new Tabulator(tableRef.current, {
				data: utensils, groupBy: 'cat',
				pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitColumns", placeholder: "No matching records found",
				//groupHeader: (value: any, count: number, data: any, group: GroupComponent) => {return value},
				columns: [
					{ title: "Sr.", field: "srno", minWidth: 30, print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Name", field: "name", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", },//cellEdited: editedCallback,
					{ title: "Date", field: "date", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "date", validator: "required", },
					{ title: "Quantity", field: "qty", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center' },//, cellEdited: editedCallback,
					{ title: "Category", field: "cat", visible: false, minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', },
				],
			})
		}
		//tabulator.current?.on('tableBuilt', () => {tabulator.current?.redraw();});
		tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }) })
	}

	// Redraw table onresize
	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator.current) {
				tabulator.current.redraw()
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", })
			}
		})
	}

	const loadData = () => {
		const d = {
			"u": [
				{ "id": 1, "name": "Rice", "catid": 1, date: '23/08/2023', qty: 5 },
				{ "id": 2, "name": "Toor Daal", "catid": 1, date: '01/01/2024', qty: 94 },
				{ "id": 3, "name": "Maida", "catid": 2, date: '07/05/2024', qty: 8 },
				{ "id": 4, "name": "Peanut", "catid": 3, date: '09/05/2024', qty: 6 },
				{ "id": 5, "name": "Rawa", "catid": 3, date: '06/06/1996', qty: 7 },
			],
			"uc": [
				{ "id": 1, "name": "Grocery" },
				{ "id": 2, "name": "Vegitables" },
				{ "id": 3, "name": "Dairy" },
				{ "id": 4, "name": "Kirana" },
				{ "id": 5, "name": "Bakery" }
			]
		}
		d.u.forEach((u: any, i: number) => {
			u.srno = i + 1;
			const c = d.uc.find(x => x.id == u.catid).name
			u.cat = c;
		});
		setUtensils(d.u)
		setUtensilsCategories(d.uc)
	}

	useEffect(() => { loadData() }, [])

	useEffect(() => {
		initTabulator()
		reInitOnResizeWindow()
	}, [utensils])

	return <>
		<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
			<h2 className="mr-auto text-lg font-medium">Add Inventry</h2>
			<div className="flex w-full mt-4 sm:w-auto sm:mt-0">
				<Button variant="primary" className="mx-auto shadow-md" onClick={() => setHeaderFooterModalPreview(true)} >Add New</Button>
			</div>
		</div>
		<div className="p-5 mt-5 intro-y box">
			<div className="overflow-x-auto scrollbar-hidden">
				<div id="tabulator" ref={tableRef} className="mt-5"></div>
			</div>
		</div>
		<Dialog open={headerFooterModalPreview} onClose={() => { setHeaderFooterModalPreview(false); }} initialFocus={sendButtonRef}>
			<Dialog.Panel>
				<Dialog.Title><h2 className="mr-auto text-base font-medium">Add Inventry</h2></Dialog.Title>
				<Dialog.Description className="grid grid-cols-12 gap-4 gap-y-3">
					<div className="col-span-12 sm:col-span-12">
						<FormLabel>Name</FormLabel>
						<FormInput type="text" placeholder="Name" onChange={(e) => setInventory({ ...inventory, name: e.target.value })} />
					</div>
					<div className="col-span-6 sm:col-span-6">
						<FormLabel>Date</FormLabel>
						<div className="relative mx-auto">
							<div className="absolute flex items-center justify-center w-10 h-full border rounded-l bg-slate-100 text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
								<Lucide icon="Calendar" className="w-4 h-4" />
							</div>
							<Litepicker placeholder='Date' onChange={(date) => { setInventory({ ...inventory, date: date }) }} options={{
								autoApply: false, format: 'DD/MM/YYYY',
								dropdowns: { minYear: 1990, maxYear: null, months: true, years: true, },
							}} className="pl-12" />
						</div>
					</div>
					<div className="col-span-6 sm:col-span-6">
						<FormLabel>Quantity</FormLabel>
						<FormInput type='number' placeholder="Quantity" onChange={(e) => setInventory({ ...inventory, qty: Number(e.target.value) })} />
					</div>
					<div className="col-span-12 sm:col-span-12">
						<FormLabel>Category</FormLabel>
						<FormSelect name='category' aria-label="Category" onChange={(e) => { setInventory({ ...inventory, category: Number(e.target.value) }) }} >
							<option value='0'>Category</option>
							{/*<option value='1'>Boil</option>*/}
							{/*<option value='2'>Cut</option>*/}
							{/*<option value='3'>Grind</option>*/}
							{(utensilsCategories != undefined && utensilsCategories != null) ? utensilsCategories.map((p: any) => <option value={p.id} key={p.id}>{p.name}</option>) : <option>Nothing to show</option>}
						</FormSelect>
					</div>
				</Dialog.Description>
				<Dialog.Footer>
					<Button type="button" variant="outline-secondary" onClick={() => { setHeaderFooterModalPreview(false); }} className="w-20 mr-1">Cancel</Button>
					<Button variant="primary" type="button" ref={sendButtonRef} onClick={() => { saveCategory() }}>Add Inventry</Button>
				</Dialog.Footer>
			</Dialog.Panel>
		</Dialog>
		<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
			<Dialog.Panel>
				<div className="p-5 text-center">
					<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${modal.type}`} />
					<div className="mt-5 text-3xl">{modal.title}</div>
					<div className="mt-2 text-slate-500">{modal.msg}</div>
				</div>
				<div className="px-5 pb-8 text-center">
					<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24" > Ok </Button>
				</div>
			</Dialog.Panel>
		</Dialog>
	</>

	async function saveCategory() {
		try {
			showLoader()
			const f = await fetch('/api/add_utensils_category', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ name: 'category' }), })
			if (f.ok) {
				const r = await f.json()
				if (r.e) setModal({ type: 'error', title: r.d, msg: 'Save Filed' })
				else setModal({ type: 'success', title: r.d, msg: 'Save Successful' })
				setHeaderFooterModalPreview(false)
				setSuccessModalPreview(true)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
}