import Button from "../../../../base-components/Button";
import { FormSelect } from "../../../../base-components/Form";
import FormInput from "../../../../base-components/Form/FormInput";

const Form: React.FC<{
	handleClearForm: () => void;
	handleAddForm: (event: any) => void;
	handleSubmitForm: (event: any) => void;
	unitProcessList: any;
	dmd: DishMasterData;
}> = ({ handleClearForm, handleAddForm, handleSubmitForm, unitProcessList, dmd }) => {
	return (
		<>{/*id=""<h2>Add a Content</h2><div className="grid grid-cols-12 gap-2"></div>*/}
			<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
				<FormInput type="text" list={'contents'} className="col-span-3" name="content" placeholder="Content" value={dmd.content} onChange={handleAddForm} required /></td>
			<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
				<div className="flex align-center justify-center gap-2">
					{dmd.val.map((number: number, index: number) => (
						<FormInput type="number" key={index} onKeyDown={(e) => ["ArrowUp", "ArrowDown", "e", "E"].includes(e.key) && e.preventDefault()}
							className="col-span-1" name={"val" + index} placeholder={"Qty " + (index+1)} value={number} min="1"
							onChange={handleAddForm} required />
					))}
				</div>
			</td>
			<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
				<FormSelect onChange={handleAddForm} name='unit' aria-label="Unit" value={dmd.unit}>
					<option value='0'>Unit</option>
					{(unitProcessList != undefined && unitProcessList.units != undefined) ? unitProcessList.units.map((p: any) => <option value={p.value} key={p.value}>{p.text}</option>) : <option>Nothing to show</option>}
				</FormSelect>
				{/*<FormInput type="number" className="col-span-2" name="unit" placeholder="Unit" onChange={handleAddForm} required />*/}
			</td>
			<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
				{/*<FormInput type="text" className="col-span-2" name="process" placeholder="Process" onChange={handleAddForm} required />*/}
				<FormSelect onChange={handleAddForm} name='process' aria-label="Process" value={dmd.process}>
					<option value='0'>Process</option>
					{(unitProcessList != undefined && unitProcessList.processes != null) ? unitProcessList.processes.map((p: any) => <option value={p.value} key={p.value}>{p.text}</option>) : <option>Nothing to show</option>}
				</FormSelect>
			</td>
			<td className="text-center px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
				<div className="flex justify-center align-center gap-2">
					<Button variant="outline-primary" rounded className="col-span-2" onClick={handleSubmitForm}>Add</Button>
					<Button variant="outline-danger" rounded className="col-span-2" onClick={handleClearForm}>Clear</Button>
				</div>
			</td>
		</>);
};

export default Form;