import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import Button from "../../../base-components/Button";
import { FormInput, FormLabel, FormTextarea } from "../../../base-components/Form";
import { Dialog, Tab } from "../../../base-components/Headless";
import { useLoader } from "../../../base-components/Loader";
import Lucide from "../../../base-components/Lucide";
import SimpleLineChart1 from "../../../components/SimpleLineChart1";
import SimpleLineChart2 from "../../../components/SimpleLineChart2";
import fakerData from "../../../utils/faker";

export default function Main() {
	const { showLoader, hideLoader } = useLoader()

	const { customer_id } = useParams()

	const tableRef = createRef<HTMLDivElement>()
	const tabulator = useRef<Tabulator>()

	const sendButtonRef = useRef();

	//var utensilsCategories = []

	const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false);
	const [successModalPreview, setSuccessModalPreview] = useState(false);
	const [modal, setModal] = useState({ msg: '', title: '', type: '' });

	//const editedCallback = (cell: CellComponent) => {
	//	const d = cell.getData() as any
	//	const name = d.name
	//}
	const [category, setCategory] = useState('')

	//const [utensilsCategories, setUtensilsCategories] = useState([])
	const [utensils, setUtensils] = useState([])
	//var utensils = []
	var copyIcon = function () { return '<i data-lucide="bell-plus" className="w-8 h-8 mr-1 text-success"></i> Remind'; };

	const initTabulator = () => {
		if (tableRef.current) {
			tabulator.current = new Tabulator(tableRef.current, {
				data: utensils, movableColumns: true,
				pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitColumns", placeholder: "No matching records found",
				//groupHeader: (value: any, count: number, data: any, group: GroupComponent) => { return value },
				columns: [
					{ title: "Sr.", field: "srno", minWidth: 30, print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Date", field: "name", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", },
					{ title: "Days", field: "in", minWidth: 120, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", },
					{ title: "Event", field: "name", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", },
					{ title: "Address", field: "out", minWidth: 250, print: true, hozAlign: "center", headerHozAlign: 'center', },
					{ title: "Mobile", field: "in", minWidth: 150, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", },
					{ title: "Amount", field: "ucat", minWidth: 120, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", },
					{ title: "Received", field: "ucat", minWidth: 120, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", },
					{ title: "Pending", field: "ucat", minWidth: 120, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", },
					{ title: "Status", field: "ucat", minWidth: 120, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", },
					{
						title: "Reminder", print: false, formatter: copyIcon, headerSort: false, headerHozAlign: "center", width: 100, hozAlign: "center",
						cellClick: function (_e, _row) {
							//const _id = (row.getData() as any).id
							//const index = dataList.findIndex((item) => item.id === id);
							//dataList.splice(index, 1);//tabulator.current?.deleteRow(index+1)
						}
					},
				],
			});
		}
		//tabulator.current?.on('tableBuilt', () => {tabulator.current?.redraw();});
		tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
	};

	// Redraw table onresize
	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator.current) {
				tabulator.current.redraw();
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
			}
		});
	};

	useEffect(() => { loadData() }, [])
	useEffect(() => {
		initTabulator()
		reInitOnResizeWindow()
	}, [utensils])

	return (
		<>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
				<h2 className="mr-auto text-lg font-medium">Event List</h2>
				<div className="flex w-full mt-4 sm:w-auto sm:mt-0">
					<Button variant="primary" className="mr-2 shadow-md" onClick={() => setHeaderFooterModalPreview(true)} >Add Customer</Button>
				</div>
			</div>
			<div className="p-5 mt-5 intro-y box">
				Customer Id: {customer_id}
				<Tab.Group>
					<div className="px-5 pt-5 mt-5 intro-y box">
						<div className="flex flex-col pb-5 -mx-5 border-b lg:flex-row border-slate-200/60 dark:border-darkmode-400">
							<div className="flex items-center justify-center flex-1 px-5 lg:justify-start">
								<div className="relative flex-none w-20 h-20 sm:w-24 sm:h-24 lg:w-32 lg:h-32 image-fit">
									<img alt="Catering Software" className="rounded-full" src={fakerData[0].photos[0]} />
									<div className="absolute bottom-0 right-0 flex items-center justify-center p-2 mb-1 mr-1 rounded-full bg-primary">
										<Lucide icon="Camera" className="w-4 h-4 text-white" />
									</div>
								</div>
								<div className="ml-5">
									<div className="w-24 text-lg font-medium truncate sm:w-40 sm:whitespace-normal">
										{fakerData[0].users[0].name}
									</div>
									<div className="text-slate-500">{fakerData[0].jobs[0]}</div>
								</div>
							</div>
							<div className="flex-1 px-5 pt-5 mt-6 border-t border-l border-r lg:mt-0 border-slate-200/60 dark:border-darkmode-400 lg:border-t-0 lg:pt-0">
								<div className="font-medium text-center lg:text-left lg:mt-3">
									Contact Details
								</div>
								<div className="flex flex-col items-center justify-center mt-4 lg:items-start">
									<div className="flex items-center truncate sm:whitespace-normal">
										<Lucide icon="Mail" className="w-4 h-4 mr-2" />
										{fakerData[0].users[0].email}
									</div>
									<div className="flex items-center mt-3 truncate sm:whitespace-normal">
										<Lucide icon="Instagram" className="w-4 h-4 mr-2" /> Instagram
										{fakerData[0].users[0].name}
									</div>
									<div className="flex items-center mt-3 truncate sm:whitespace-normal">
										<Lucide icon="Twitter" className="w-4 h-4 mr-2" /> Twitter
										{fakerData[0].users[0].name}
									</div>
								</div>
							</div>
							<div className="flex-1 px-5 pt-5 mt-6 border-t lg:mt-0 lg:border-0 border-slate-200/60 dark:border-darkmode-400 lg:pt-0">
								<div className="font-medium text-center lg:text-left lg:mt-5">
									Profit Report Stats
								</div>
								<div className="flex items-center justify-center mt-2 lg:justify-start">
									<div className="flex w-20 mr-2">
										USP:{" "}
										<span className="ml-3 font-medium text-success">+23%</span>
									</div>
									<div className="w-3/4">
										<SimpleLineChart1 height={55} className="-mr-5" />
									</div>
								</div>
								<div className="flex items-center justify-center lg:justify-start">
									<div className="flex w-20 mr-2">
										STP: <span className="ml-3 font-medium text-danger">-2%</span>
									</div>
									<div className="w-3/4">
										<SimpleLineChart2 height={55} className="-mr-5" />
									</div>
								</div>
							</div>
						</div>
						<Tab.List variant="link-tabs" className="flex-col justify-center text-center sm:flex-row lg:justify-start" onClick={(_e) => { }} >
							<Tab fullWidth={false}><Tab.Button className="py-4 cursor-pointer">Dashboard</Tab.Button></Tab>
							<Tab fullWidth={false}><Tab.Button className="py-4 cursor-pointer">Account & Profile</Tab.Button></Tab>
							<Tab fullWidth={false}><Tab.Button className="py-4 cursor-pointer">Activities</Tab.Button></Tab>
							<Tab fullWidth={false}><Tab.Button className="py-4 cursor-pointer">Tasks</Tab.Button></Tab>
						</Tab.List>
					</div>
				</Tab.Group>
				<div className="overflow-x-auto scrollbar-hidden">
					<div id="tabulator" ref={tableRef} className="mt-5"></div>
				</div>
			</div>
			<Dialog open={headerFooterModalPreview} onClose={() => { setHeaderFooterModalPreview(false); }} initialFocus={sendButtonRef}>
				<Dialog.Panel>
					<Dialog.Title><h2 className="mr-auto text-base font-medium">Add Customer</h2></Dialog.Title>
					<Dialog.Description className="grid grid-cols-12 gap-4 gap-y-3">
						<div className="col-span-6 sm:col-span-6">
							<FormLabel>Customer Name</FormLabel>
							<FormInput type="text" placeholder="Customer Name" onChange={(e) => setCategory(e.target.value)} />
						</div>
						<div className="col-span-6 sm:col-span-6">
							<FormLabel>Mobile</FormLabel>
							<FormInput type="text" placeholder="Mobile" onChange={(e) => setCategory(e.target.value)} />
						</div>
						<div className="col-span-12 sm:col-span-12">
							<FormLabel>Address</FormLabel>
							<FormTextarea placeholder="Address" onChange={(e) => setCategory(e.target.value)} />
						</div>
						<div className="col-span-12 sm:col-span-12">
							<FormLabel>Menu</FormLabel>
							<FormTextarea placeholder="Menu" onChange={(e) => setCategory(e.target.value)} />
						</div>
					</Dialog.Description>
					<Dialog.Footer>
						<Button type="button" variant="outline-secondary" onClick={() => { setHeaderFooterModalPreview(false); }} className="w-20 mr-1">Cancel</Button>
						<Button variant="primary" type="button" ref={sendButtonRef} onClick={saveCategory}>Add Maharaj</Button>
					</Dialog.Footer>
				</Dialog.Panel>
			</Dialog>
			<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${modal.type}`} />
						<div className="mt-5 text-3xl">{modal.title}</div>
						<div className="mt-2 text-slate-500">{modal.msg}</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24">Ok</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
		</>);

	async function loadData() {
		try {
			showLoader()
			const f = await fetch('/api/utensils')
			if (f.ok) {
				const d = await f.json()
				d.u.forEach((u, i) => {
					u.srno = i + 1;
					u.in = 0;
					u.out = 0;
					const c = d.uc.find(x => x.id == u.catid).name
					u.ucat = c;
				})

				setUtensils(d.u)
				//setUtensilsCategories(d.uc)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	async function saveCategory() {
		try {
			showLoader()
			const f = await fetch('/api/add_utensils_category', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ name: category }), })
			if (f.ok) {
				const r = await f.json()
				if (r.e) setModal({ type: 'error', title: r.d, msg: 'Save Filed' })
				else setModal({ type: 'success', title: r.d, msg: 'Save Successful' })

				setHeaderFooterModalPreview(false)
				setSuccessModalPreview(true);
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
}