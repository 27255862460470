/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import darkModeReducer from "./darkModeSlice";
import colorSchemeReducer from "./colorSchemeSlice";
import sideMenuReducer from "./sideMenuSlice";
import simpleMenuReducer from "./simpleMenuSlice";
import topMenuReducer from "./topMenuSlice";

import userReducer from "./userSlice";

import eventIdReducer from "./eventIdSlice";
import dateRangeReducer from "./calendarDateRangeSlice";
import { loadState, logoutState, saveState } from "./localStorage";

const mPreloadedState = await loadState();

const rootReducer = combineReducers({
	darkMode: darkModeReducer,
	colorScheme: colorSchemeReducer,
	sideMenu: sideMenuReducer,
	simpleMenu: simpleMenuReducer,
	topMenu: topMenuReducer,

	auth: userReducer,

	eventId: eventIdReducer,
	dateRange: dateRangeReducer,
});

export const store = configureStore({
	reducer: rootReducer, preloadedState:mPreloadedState,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat((store: any) => (next: any) => (action: any) => {
		const result = next(action);
		if (result.type == "auth/setUser") {
			console.log(result)
			saveState(store.getState());
		}
		else if (result.type == "auth/logout") {
			logoutState(store.getState());
		}
		else console.log(result.type)
		return result;
	}),
	devTools: true
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;