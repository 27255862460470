import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import Button from "../../../base-components/Button";
import { Dialog } from "../../../base-components/Headless";
import { useLoader } from "../../../base-components/Loader";
import Lucide from "../../../base-components/Lucide";
import { RootState } from "../../../stores/store";

export default function Main() {
	const { showLoader, hideLoader } = useLoader();

	const tableRef = createRef<HTMLDivElement>();
	const tabulator = useRef<Tabulator>();
	const updateButtonRef = useRef();

	const [utensils, setUtensils] = useState([]);

	const [confirmationModal, setConfirmationModal] = useState(false);
	const [successModalPreview, setSuccessModalPreview] = useState(false);
	const [modal, setModal] = useState({ msg: '', title: '', type: '' });

	//const editedCallback = (cell: CellComponent) => {
	//	const d = cell.getData() as any
	//	const name = d.name
	//}

	const initTabulator = () => {
		if (tableRef.current) {
			tabulator.current = new Tabulator(tableRef.current, {
				data: utensils, groupBy: "cat", movableColumns: true,
				//pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitColumns", placeholder: "No matching records found",
				columns: [
					{ title: "Serial Number", field: "srno", minWidth: 200, print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Name", field: "name", headerFilter: "input", minWidth: 200, headerFilterPlaceholder: "Search Name", print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required", },
					//{ title: "Inward", field: "in", minWidth: 200, headerFilter: "number", headerFilterPlaceholder: "Search Inward", print: true, hozAlign: "center", headerHozAlign: 'center', cellEdited: editedCallback, editable: true, editor: "number", validator: "required", },
					{ title: "Outward", field: "out", minWidth: 200, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "number", validator: "required", },
					{ title: "uc", field: "ucat", print: true, hozAlign: "center", headerHozAlign: 'center', visible: false },
				],
			});
		}
		//tabulator.current?.on('tableBuilt', () => {tabulator.current?.redraw();});
		tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
	};

	// Redraw table onresize
	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator.current) {
				tabulator.current.redraw();
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
			}
		});
	};

	const nav = useNavigate()
	const eventId = useSelector((state: RootState) => state.eventId.eventId);

	useEffect(() => { if (eventId == null) nav('/admin'); else loadData() }, [])

	useEffect(() => {
		initTabulator()
		reInitOnResizeWindow()
	}, [utensils]);

	return <>
		<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
			<h2 className="mr-auto text-lg font-medium">Utensils Outward</h2>
			{/*<div className="flex w-full mt-4 sm:w-auto sm:mt-0">*/}
			{/*	<Button variant="primary" className="mr-2 shadow-md" onClick={showList} >Add Menu</Button>*/}
			{/*	<Menu className="ml-auto sm:ml-0">*/}
			{/*		<Menu.Button as={Button} className="px-2 font-normal !box">*/}
			{/*			<span className="flex items-center justify-center w-5 h-5"><Lucide icon="Plus" className="w-4 h-4" /></span>*/}
			{/*		</Menu.Button>*/}
			{/*		<Menu.Items className="w-40">*/}
			{/*			<Menu.Item><Lucide icon="FilePlus" className="w-4 h-4 mr-2" /> New Category</Menu.Item>*/}
			{/*			<Menu.Item><Lucide icon="UserPlus" className="w-4 h-4 mr-2" /> New Group</Menu.Item>*/}
			{/*		</Menu.Items>*/}
			{/*	</Menu>*/}
			{/*</div>*/}
		</div>
		<div className="p-5 mt-5 intro-y box">
			<div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
				<div className="xl:flex sm:mr-auto"></div>
			</div>
			<div className="overflow-x-auto scrollbar-hidden">
				<div id="tabulator" ref={tableRef} className="mt-5"></div>
			</div>
			<div className='flex'>
				<Button variant="primary" className="mx-auto my-3" onClick={(event: React.MouseEvent) => {
					event.preventDefault();
					setConfirmationModal(true)
				}} >Add Utensils Outward</Button>
			</div>
		</div>
		<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
			<Dialog.Panel>
				<div className="p-5 text-center">
					<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${modal.type}`} />
					<div className="mt-5 text-3xl">{modal.title}</div>
					<div className="mt-2 text-slate-500">{modal.msg}</div>
				</div>
				<div className="px-5 pb-8 text-center">
					<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24" > Ok </Button>
				</div>
			</Dialog.Panel>
		</Dialog>
		<Dialog open={confirmationModal} initialFocus={updateButtonRef} onClose={() => { setConfirmationModal(false); }}>
			<Dialog.Panel>
				<div className="p-5 text-center">
					<Lucide icon="Edit" className="w-16 h-16 mx-auto mt-3 text-danger" />
					<div className="mt-5 text-3xl">Are you sure?</div>
					<div className="mt-2 text-slate-500">Save utensils outward<br />This process cannot be undone.</div>
				</div>
				<div className="px-5 pb-8 text-center">
					<Button variant="outline-secondary" type="button" onClick={() => { setConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
					<Button variant="success" type="button" className="text-white w-24" ref={updateButtonRef}
						onClick={() => {
							const a = utensils.map(({ id, out }) => ({ id, out }))
							saveUtensilsOutward(a)
						}} >Save</Button>
				</div>
			</Dialog.Panel>
		</Dialog>
	</>

	async function loadData() {
		try {
			showLoader()
			const f = await fetch('/api/utensils_outward/' + eventId)
			if (f.ok) {
				const x = await f.json()
				if (x.e) { }
				else {
					x.d.forEach((u: any, i: number) => {
						u.srno = i + 1
						//const c = d.uc.find(x => x.id == u.catid).name
						//u.ucat = c;
					})
					setUtensils(x.d)
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	async function saveUtensilsOutward(data) {
		try {
			setConfirmationModal(false)
			showLoader()
			const f = await fetch('/api/add_utensils_trans/' + eventId, { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data), })
			if (f.ok) {
				const r = await f.json()
				if (r.e) setModal({ type: 'error', title: r.d, msg: 'Save Filed' })
				else setModal({ type: 'success', title: r.d, msg: 'Save Successful' })

				setSuccessModalPreview(true)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
}