/* eslint-disable @typescript-eslint/no-explicit-any */
// src/utils/localStorage.ts

// src/utils/indexedDB.ts

import { openDB } from 'idb';

const dbPromise = openDB('auth', 1, { upgrade(db) { db.createObjectStore('state'); }, })

export const loadState = async() => {
    const db = await dbPromise;
    const state = await db.get('state', 'key');
    return state ? JSON.parse(state) : undefined;
    //try {
    //    const accessToken=localStorage.getItem("accessToken")
    //    console.log('loadState localStorage', accessToken)
    //    const serializedState = localStorage.getItem('state');
    //    if (serializedState === null) {
    //        return undefined;
    //    }
    //    return JSON.parse(serializedState);
    //} catch (err) {
    //    return undefined;
    //}
};

export const saveState = async (state: any) => {
    const db = await dbPromise;
    await db.put('state', JSON.stringify(state), 'key');
    try {
        console.log('saveState',)
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (err) {
        // Ignore write errors
    }
};

export const logoutState = async (state: any) => {
    const db = await dbPromise;
    await db.put('state', JSON.stringify(state), 'key');
    try {
        console.log('logoutState',)
        localStorage.setItem('state', null);
    } catch (err) {
        // Ignore write errors
    }
};
