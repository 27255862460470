import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../stores/hooks";
import { selectAccessToken } from "../../stores/userSlice";
import { useEffect } from "react";

const RequireAuth = () => {
	const accessToken = useAppSelector(selectAccessToken);
	const location = useLocation()
	/*<div>Require Auth</div> <Outlet />*/
	//const c = accessToken  && accessToken.length > 0
	//accessToken == undefined ||

	//useEffect(() => { console.log('accessToken: ', { accessToken }) }, [accessToken])

	return (!accessToken ? <Navigate to='/auth' state={{ from: location }} replace /> : <Outlet />);
}

export default RequireAuth