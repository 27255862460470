import { useEffect, useRef, useState } from "react";
import Button from "../../../../base-components/Button";
import FormInput from "../../../../base-components/Form/FormInput";
import Lucide from "../../../../base-components/Lucide";
import Table from "../../../../base-components/Table";
import Dialog from "../../../../base-components/Headless/Dialog";
import { useLoader } from "../../../../base-components/Loader";

export default function Main(props) {
	const { showLoader, hideLoader } = useLoader();

	const updateButtonRef = useRef();
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [successModalPreview, setSuccessModalPreview] = useState(false);
	const [modal, setModal] = useState({ msg: '', title: '', type: '' });

	const [people, setPeople] = useState([]);

	useEffect(() => {
		if (props.eventId != null) initPeople()
	}, [])

	return (
		<>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
				<h2 className="mr-auto text-lg font-medium">After Event</h2>
			</div>
			<div className="grid grid-cols-12 gap-2 mt-2">
				<div className="col-span-12 intro-y lg:col-span-12">
					<div className="intro-y box h-full mt-2">
						<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
							<h2 className="mr-auto text-base font-medium">People</h2>
						</div>
						<div className="p-5">
							{people.length > 0 ?
								<>
									<Table className="border rounded">
										<Table.Thead>
											<Table.Tr>
												<Table.Th className="text-center bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap">Name</Table.Th>
												<Table.Th className="text-center bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap">People</Table.Th>
												<Table.Th className="text-center bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap">Actual People</Table.Th>
											</Table.Tr>
										</Table.Thead>
										<Table.Tbody>
											{people.map((x, i) => (
												<Table.Tr key={i} >
													<Table.Td className="border-r text-center">{x.name}</Table.Td>
													<Table.Td className="border-r text-center">
														<FormInput type='number' placeholder='People' value={x.people} disabled />
													</Table.Td>
													<Table.Td className="border-r text-center">
														<FormInput type='number' className='w-full' placeholder='Actual People' value={x.actual}
															onChange={(e) => {
																x.actual = Number(e.target.value)
																setPeople([...people])
															}} />
													</Table.Td>
												</Table.Tr>
											))}
										</Table.Tbody>
									</Table>
									{people && <div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
										<Button variant="soft-primary" className="mx-auto my-3"
											onClick={() => { setConfirmationModal(true) }}>
											<Lucide icon='Save' className='h-4' /> Save
										</Button>
									</div>}
								</>
								: <b>People Details not found</b>}
						</div>
					</div>
				</div>
			</div>

			<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${modal.type}`} />
						<div className="mt-5 text-3xl">{modal.title}</div>
						<div className="mt-2 text-slate-500">{modal.msg}</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24" > Ok </Button>
					</div>
				</Dialog.Panel>
			</Dialog>
			<Dialog open={confirmationModal} initialFocus={updateButtonRef} onClose={() => { setConfirmationModal(false); }}>
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="Edit" className="w-16 h-16 mx-auto mt-3 text-danger" />
						<div className="mt-5 text-3xl">Are you sure?</div>
						<div className="mt-2 text-slate-500">Save People Details<br />This process cannot be undone.</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button variant="outline-secondary" type="button" onClick={() => { setConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
						<Button variant="success" type="button" className="text-white w-24" ref={updateButtonRef}
							onClick={() => { savePeople(people.filter((item) => item.name !== undefined).map(({ name, ...rest }) => rest)) }} >Save</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
		</>
	);

	async function initPeople() {
		try {
			showLoader()
			const f = await fetch('/api/ae_people/' + props.eventId)
			if (f.ok) {
				const x = await f.json()
				if (x.e) console.error(x); else setPeople(x.d)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	async function savePeople(data) {
		try {
			showLoader()
			setConfirmationModal(false)
			const f = await fetch('/api/ae_people/' + props.eventId, { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data), })
			if (f.ok) {
				const x = await f.json()
				if (x.e) setModal({ msg: x.d, title: 'Failed', type: 'error' })
				else setModal({ msg: x.d, title: 'Success', type: 'success' })
				setSuccessModalPreview(true)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
}