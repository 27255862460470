/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Table from "./components/Table";
import Button from "../../../base-components/Button";
import DishMasterForm from "./components/DishMasterForm";
import './dish-master.css'
import Dialog from "../../../base-components/Headless/Dialog";
import Lucide from "../../../base-components/Lucide";
import { useLoader } from "../../../base-components/Loader";

function Main() {//id: nanoid(),
	const { showLoader, hideLoader } = useLoader();

	const [unitProcessList, setUnitProcessList] = useState([]);
	const [dishes, setDishes] = useState([]);
	const [categories, setCategories] = useState([]);
	const [contents, setContents] = useState([]);

	const [dishMasterDataList, setDishMasterDataList] = useState<DishMasterData[]>([]);
	const initDMD = { id: 0, content: '', val: [0,], unit: 0, process: 0 }
	const [dishMasterData, setDishMasterData] = useState<DishMasterData>(initDMD);
	const [dishMaster, setDishMaster] = useState<DishMaster>({ dish: '', priority: 0, people: [10,], kg: [8,], price: [100,], stall: '', category: 0 });

	const [editDMD, setEditDMD] = useState<DishMasterData>();
	const [editId, setEditId] = useState<number>(-1);

	const [successModalPreview, setSuccessModalPreview] = useState(false);
	const [modal, setModal] = useState({ msg: '', title: '', type: '' });

	const getSavedStallName = async (dishName: string) => {
		try {
			const f = await fetch('/api/dish_info?dishName=' + dishName)
			if (f.ok) {
				const r = await f.json()
				setDishMaster({ ...dishMaster, dish: dishName, ...r })
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) }
	}

	const saveDish = async () => {
		try {
			showLoader()
			const body = JSON.stringify({ dm: dishMaster, list: dishMasterDataList })
			const f = await fetch('/api/add_dish', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: body, })
			if (f.ok) {
				const r = await f.json()
				if (r.e) setModal({ type: 'error', title: r.d, msg: 'Save Filed' })
				else setModal({ type: 'success', title: r.d, msg: 'Save Successful' })

				setSuccessModalPreview(true);
			}
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	const loadStaticData = () => {
		const l = [...dishMasterDataList]
		const count = dishMaster.people.length
		for (let c = 0; c < 6; c++) {
			const i = l.length + 1, q = []
			for (let v = 0; v < count; v++) q.push(i + v)
			l.push({ id: l.length, content: 'content ' + i, val: q, unit: i, process: i })
		}
		setDishMasterDataList(l)
	}
	const addColumn = () => {
		const dm = { ...dishMaster }
		dm.people.push(dm.people[dm.people.length - 1])
		dm.kg.push(dm.kg[dm.kg.length - 1])
		dm.price.push(dm.price[dm.price.length - 1])
		setDishMaster(dm)

		const dmd = { ...dishMasterData }
		dmd.val.push(dmd.val[dmd.val.length - 1])
		setEditDMD(editDMD)

		const dmdl = [...dishMasterDataList]
		dmdl.forEach((d) => { d.val.push(d.val[d.val.length - 1]) })
		setDishMasterDataList(dmdl)
	}
	const removeColumn = () => {
		const dm = { ...dishMaster }
		dm.people.pop()
		dm.kg.pop()
		dm.price.pop()
		setDishMaster(dm)

		//const editdm = { ...editDMD }
		//editdm.val.pop()
		//setEditDMD(editDMD)

		dishMasterData.val.pop()
		setDishMasterData(dishMasterData)

		const dmdl = [...dishMasterDataList]
		dmdl.forEach((d) => { d.val.pop() })
		setDishMasterDataList(dmdl)
	}
	const loadUnitProcess = async () => {
		try {
			showLoader()
			const f = await fetch('/api/dish_master_init')
			if (f.ok) {
				const r = await f.json()
				setDishes(r.dishNames)
				setCategories(r.categories)
				setUnitProcessList(r.up)
				setContents(r.contents)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	const loadSaved = async () => {
		try {
			showLoader()
			const f = await fetch(`/api/saved_dish?dishName=${dishMaster.dish}`)
			if (f.ok) {
				const r = await f.json()
				if (r.e) { console.error(r) }
				else {
					const dm = { ...dishMaster, people: r.people, kg: r.kg, price: r.prices }
					setDishMaster(dm)

					//For Bottom Footer Form
					const dmd = { ...dishMasterData, content: '', process: undefined, unit: undefined }
					dmd.val = Array(dm.people.length).fill('')
					setDishMasterData(dmd)

					setDishMasterDataList(r.list)
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	useEffect(() => { loadUnitProcess() }, []);//dishMasterDataList

	const handleEditClick = (e: any, dmd: DishMasterData) => {
		e.preventDefault();
		setEditId(dmd.id);
		setEditDMD(dmd);
	};

	//const handleCancelClick = () => { setEditId(-1); };

	const handleDeleteClick = (contactId: any) => {
		const index = dishMasterDataList?.findIndex((contact: { id: any; }) => contact.id === contactId);
		const newContacts = [...dishMasterDataList];

		newContacts.splice(index ?? 0, 1);
		setDishMasterDataList(newContacts);
	};

	const handleEditForm = (e: any) => {
		e.preventDefault();
		const fieldName = e.target.getAttribute('name');
		const fieldValue = e.target.value;

		const editData = { ...editDMD };
		if (e.target.type === 'number') {
			const matches = fieldName.match(/\d+/g);
			const index = (matches) ? Number(matches.join('')) : 0
			const field = fieldName.substring(0, fieldName.indexOf(index + ''))
			editData[field][index] = Number(fieldValue);
		} else if (e.target.type === 'select-one') {
			(editData as any)[fieldName] = Number(fieldValue);
		}
		else editData[fieldName] = fieldValue;
		setEditDMD({ ...editData });
	};

	const handleEditFormSubmit = (_e: any) => {
		const editedContact = { ...editDMD, id: editId }
		const newContacts = [...dishMasterDataList];
		const index = dishMasterDataList.findIndex(contact => contact.id === editId);
		newContacts[index] = editedContact as any;

		setDishMasterDataList(newContacts);
		setEditId(-1);
	};

	const handleAddForm = (e: any) => {
		e.preventDefault();
		const fieldName = e.target.getAttribute('name'), fieldValue = e.target.value;

		const addData = { ...dishMasterData };
		if (e.target.type === 'number') {
			const matches = fieldName.match(/\d+/g);
			const index = (matches) ? Number(matches.join('')) : 0
			const field = fieldName.substring(0, fieldName.indexOf(index + ''))
			addData[field][index] = Number(fieldValue);
		} else if (e.target.type === 'select-one') addData[fieldName] = Number(fieldValue);
		else addData[fieldName] = fieldValue;
		setDishMasterData({ ...addData });
	};

	const handleSubmitForm = () => {
		setDishMasterDataList([...dishMasterDataList, { ...dishMasterData, id: dishMasterDataList.length }]);
		handleClearForm()
	};
	const handleClearForm = () => { setDishMasterData({ ...initDMD, val: Array(dishMasterData.val.length).fill(0) }); };

	const handleLoadForm = (e: any) => {
		e.preventDefault();
		const fieldName = e.target.getAttribute('name') as string;
		const fieldValue = e.target.value;
		const newDishMaster = { ...dishMaster };
		if (e.target.type === 'number') {
			const matches = fieldName.match(/\d+/g);
			const index = (matches) ? Number(matches.join('')) : 0
			const field = fieldName.substring(0, fieldName.indexOf(index + ''))
			newDishMaster[field][index] = fieldValue;
		} else if (e.target.type === 'select-one') (newDishMaster as any)[fieldName] = Number(fieldValue);
		else (newDishMaster as any)[fieldName] = fieldValue;

		setDishMaster(newDishMaster);
		if (fieldName == 'dish' && dishes.includes(fieldValue)) getSavedStallName(fieldValue)
	};

	const handleLoadSubmitForm = (e: any) => {
		e.preventDefault();
		loadSaved()
	};

	return (
		<>
			<div className="mt-5 intro-y box">
				<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60">
					<h2 className="mr-auto text-base font-medium">Dish Master</h2>
					<div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
						{/*<label htmlFor="show-example-2" className="cursor-pointer ml-2">Show example code</label>*/}
						{/*<input id="show-example-2" className="transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;[type='radio']]:checked:bg-primary [&amp;[type='radio']]:checked:border-primary [&amp;[type='radio']]:checked:border-opacity-10 [&amp;[type='checkbox']]:checked:bg-primary [&amp;[type='checkbox']]:checked:border-primary [&amp;[type='checkbox']]:checked:border-opacity-10 [&amp;:disabled:not(:checked)]:bg-slate-100 [&amp;:disabled:not(:checked)]:cursor-not-allowed [&amp;:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&amp;:disabled:checked]:opacity-70 [&amp;:disabled:checked]:cursor-not-allowed [&amp;:disabled:checked]:dark:bg-darkmode-800/50 w-[38px] h-[24px] p-px rounded-full relative before:w-[20px] before:h-[20px] before:shadow-[1px_1px_3px_rgba(0,0,0,0.25)] before:transition-[margin-left] before:duration-200 before:ease-in-out before:absolute before:inset-y-0 before:my-auto before:rounded-full before:dark:bg-darkmode-600 checked:bg-primary checked:border-primary checked:bg-none before:checked:ml-[14px] before:checked:bg-white ml-3 mr-0" type="checkbox" />*/}
						<Button rounded variant="soft-success" className="mx-2" onClick={loadStaticData}>Add Static Values</Button>
						<Button rounded variant="soft-success" className="mx-2" onClick={addColumn}>Add Column</Button>
						<Button rounded variant="soft-danger" className="mx-2" onClick={removeColumn}>Remove Column</Button>
					</div>
				</div>
				<div className="p-5">
					<datalist id="dishes">{(dishes == undefined) ? <></> : dishes.map((d: any) => (<option value={d} key={d}>{d}</option>))}</datalist>
					<datalist id="contents">{(dishes == undefined) ? <></> : contents.map((d: any) => (<option value={d} key={d}>{d}</option>))}</datalist>
					<DishMasterForm categories={categories} dm={dishMaster} handleLoadForm={handleLoadForm} handleLoadSubmitForm={handleLoadSubmitForm} />
					{/*handleLoadSubmitForm={handleLoadSubmitForm}*/}
					<div className="overflow-x-auto">{/*handleCancelClick={handleCancelClick}*/}
						<Table dmd={dishMasterData} dm={dishMaster} dmdList={dishMasterDataList}
							unitProcessList={unitProcessList}
							editId={editId}
							handleEditClick={handleEditClick}
							handleEditForm={handleEditForm}
							editFormData={editDMD}
							handleEditFormSubmit={handleEditFormSubmit}
							handleDeleteClick={handleDeleteClick}

							handleAddForm={handleAddForm}
							handleSubmitForm={handleSubmitForm}
							handleLoadForm={handleLoadForm}
							handleClearForm={handleClearForm}
						/>
					</div>
					<Button rounded variant="outline-success" className="flex justift-center mx-auto" onClick={saveDish}>Save</Button>
				</div>
			</div>
			<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${modal.type}`} />
						<div className="mt-5 text-3xl">{modal.title}</div>
						<div className="mt-2 text-slate-500">{modal.msg}</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24" > Ok </Button>
					</div>
				</Dialog.Panel>
			</Dialog>
		</>
	);
}

export default Main;