import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Litepicker from "../../../base-components/Litepicker";
import { FormLabel, FormInput, FormTextarea, FormInline, } from "../../../base-components/Form";
import Notification from "../../../base-components/Notification";
import Lucide from "../../../base-components/Lucide";
import Toastify from "toastify-js";
import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import Button from "../../../base-components/Button";
import { Dialog } from "../../../base-components/Headless";
import { FormSelect } from "../../../base-components/Form";
import { useForm } from "react-hook-form";
import './client.css'
import { useLoader } from "../../../base-components/Loader";

function Main() {
	const { showLoader, hideLoader } = useLoader();
	const navigate = useNavigate();
	const eventId = useSelector((state: RootState) => state.eventId.eventId);

	const dateRange1 = useSelector((state: RootState) => state.dateRange.dateRange ?? "");

	const [dateRange, setDateRange] = useState(dateRange1);
	const [invalidAlert, setInvalidAlert] = useState(false);
	const [castes, setCastes] = useState([]);
	const initClient = { id: 0, name: '', mobile1: '', mobile2: '', address: '', eventName: '', eventAddress: '', casteId: '0', eventid: 0 }
	const [client, setClient] = useState(initClient)

	//const [clientData, setClientData] = useState([]);
	const [records, setRecords] = useState([])
	const [showList, setShowList] = useState(true);

	const schema = yup.object().shape({
		id: yup.number(), eventid: yup.number(),
		name: yup.string().trim().required().min(2),
		casteId: yup.string().required('Caste is required')
			.matches(/^[1-9]\d*$/, { excludeEmptyString: true, message: 'Caste is required', }),
		address: yup.string().required().min(4),
		eventAddress: yup.string().required().min(4),
		eventName: yup.string().required().min(5),
		mobile1: yup.string().required().min(10).max(10)
			.matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
		mobile2: yup.string().min(10).max(10)//.required()
			.matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
	}).required();

	const { register, trigger, formState: { errors }, } = useForm({//defaultValues: client,
		criteriaMode: 'all', mode: "onSubmit", shouldFocusError: true,
		values: client, reValidateMode: 'onChange',
		resolver: yupResolver(schema),
	})

	const handleListItemClick = async (record: any) => {
		try {
			showLoader()
			localStorage.clear()
			setClient(recentData => ({ ...recentData, name: record.name }))
			setShowList(false)
			const f = await fetch(`/api/client?id=${record.id}`)
			if (f.ok) {
				const data = await f.json()
				if (data == null || data == undefined) setClient(initClient)
				else setClient({ client, ...data })
				localStorage.setItem('clientId', String(data.id))
			} else console.error(f.status, f.statusText)
		} catch (err) { console.error(err) } finally { hideLoader() }
	}

	const handleChange = (e) => {
		const name = e.target.value
		setClient((recentData) => ({ ...recentData, [e.target.name]: name }))
		fetchAutoCompleteNames(name.toString().trim())
		//setRecords(clientData.filter(f => f.name.substring(0, 3).toLowerCase().includes(e.target.value)))
		//setShowList(true);
		//if (e.target.value == "") {
		//	setShowList(false);
		//	setClient(initClient);
		//}
		//if (records.length == 0) {
		//	setShowList(false);
		//	setClient((recentData) => ({ ...recentData, [e.target.name]: e.target.value }));
		//}
	}

	const fetchClient = async (eventId: string | null) => {
		try {
			showLoader()
			localStorage.clear()
			const f = await fetch(`/api/event_client?id=${eventId}`)
			if (f.ok) {
				const data = await f.json()
				if (data == null || data == undefined) setClient(initClient)
				else {
					setClient(data)//setRecords(clientData)
					localStorage.setItem('clientId', String(data.id))
				}
			} else console.error(f.status, f.statusText)
		} catch (err) { console.error(err) } finally { hideLoader() }
	}

	function getFormValuesAsJson(form: HTMLFormElement): any {
		const formData = new FormData(form);
		const values: any = {};

		for (const entry of formData.entries()) {
			const [name, value] = entry;
			if (name === '') continue;
			if (Object.prototype.hasOwnProperty.call(values, name)) {
				if (typeof values[name] === 'string') values[name] = [values[name], value];
				else values[name].push(value);
			} else values[name] = value;
		}
		return values;
	}

	const onSubmit2 = async (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault()
		try {
			const result = await trigger()
			if (result) {
				showLoader()
				setClient(getFormValuesAsJson(event.target))
				//...client,
				const cl = { id: client.id, name: client.name, mobile1: client.mobile1, mobile2: client.mobile2, address: client.address, casteId: Number(client.casteId), }
				const ts = { id: Number(eventId), eventName: client.eventName, eventAddress: client.eventAddress, }
				const d = JSON.stringify({ cl, ts })

				const prevClientid = parseInt(localStorage.getItem('clientId') || '')
				if (prevClientid == 0 || prevClientid == undefined || Number.isNaN(prevClientid)) {
					const f = await fetch('/api/add_client', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
					if (f.ok) {
						const data = await f.json()
						hideLoader()
						if (data.e == false) navigate('/admin/sub-event', { state: { dateRange: dateRange1, eventNo: eventId }, });
						else navigate('/admin')
					} else console.error(f.status, f.statusText)
				}
				else {
					const f = await fetch('/api/update_client', { method: 'PUT', headers: { 'Content-Type': 'application/json', }, body: d, })
					if (f.ok) {
						const data = await f.json()
						hideLoader()
						if (data.e) setInvalidAlert(true);
						else {
							const successEl = document.querySelectorAll("#success-notification-content")[0].cloneNode(true) as HTMLElement;
							successEl.classList.remove("hidden");
							Toastify({
								node: successEl, duration: 3000,
								newWindow: true, close: true, stopOnFocus: true,
								gravity: "top", position: "center",
								style: { width: '300px', marginTop: '20%' }
							}).showToast();
							navigate('/admin/sub-event', { state: { dateRange: dateRange1, eventNo: eventId }, });
						}
					} else console.error(f.status, f.statusText)
				}
			}
			else setInvalidAlert(true)
		} catch (error) { console.error(error) } finally { hideLoader() }
	}

	const fetchCaste = async () => {
		try {
			showLoader()
			const f = await fetch('/api/caste')
			if (f.ok) {
				const d = await f.json()
				setCastes(d)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	useEffect(() => {
		if (eventId === null || eventId === undefined) navigate('/admin');
		else {
			fetchCaste()
			if (eventId) fetchClient(eventId)
		}
	}, [])

	const fetchAutoCompleteNames = async (name: string) => {
		try {
			setRecords([])
			showLoader()
			const f = await fetch('/api/client_data', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(name), })
			if (f.ok) {
				const data = await f.json()
				setShowList(true)
				setRecords(data)
				//setClientData(data)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	return (
		<>
			<div className="flex items-center mt-8 intro-y">
				<div className="p-5">
					<FormInline>
						<b className="mr-5 font-medium">Event No:{eventId}</b>
						<Litepicker value={dateRange} disabled onChange={setDateRange} className="block w-56 mx-auto"
							options={{
								autoApply: false, singleMode: false,
								numberOfColumns: 2, numberOfMonths: 2,
								dropdowns: { minYear: 1990, maxYear: null, months: true, years: true, },
							}} />
					</FormInline>
				</div>
			</div>
			<div className="flex items-center mt-8 intro-y">
				<h1 className="mr-auto text-lg font-medium">Client Information</h1>
			</div>
			<div className="grid grid-cols-12 gap-6 mt-5">
				<div className="col-span-12 intro-y lg:col-span-12">
					<div className="p-5 intro-y box">
						<form className="validate-form" onSubmit={onSubmit2}>
							<div className="input-form mb-5">
								<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row">Name
									<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>
								</FormLabel>
								<FormInput id='fiName' {...register("name")} type="text" name="name" value={client.name} placeholder="Enter Name"
									onChange={handleChange} className={clsx({ "border-danger": errors.name })} autoComplete='off' />
								{showList && (
									<div className="w-full">
										<ul className="MyListComponent">
											{records && records.length > 0 && records.map((record: any, i: number) => (
												<li className="m-2" key={i} onClick={() => handleListItemClick(record)} style={{ color: "#666", fontFamily: 'Bold', }}>
													<span>{record.name} {record.mobile1 && (<span>({record.mobile1}{record.mobile2 && `,${record.mobile2}`})</span>)}</span>
												</li>
											))}
										</ul>
									</div>
								)}
								{errors.name && (<div className="mt-2 text-danger">{typeof errors.name.message === "string" && errors.name.message}</div>)}
								<div className="grid w-full h-1 grid-cols-12 gap-4 mt-3">
									<div className="h-full col-span-3 rounded bg-success"></div>
									<div className="h-full col-span-3 rounded bg-success"></div>
									<div className="h-full col-span-3 rounded bg-success"></div>
									<div className="h-full col-span-3 rounded bg-slate-100 dark:bg-darkmode-800"></div>
								</div>
							</div>
							<div className="grid grid-cols-12 input-form gap-8">
								<div className="col-span-12 intro-y lg:col-span-6">
									<div className="input-form">
										<FormLabel className="flex flex-col w-full sm:flex-row">Mobile 1
											<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">
												Required, 10 digits
											</span>
										</FormLabel>
										<FormInput {...register("mobile1")} type="text" value={client.mobile1}
											onChange={handleChange} maxLength={10} className={clsx({ "border-danger": errors.mobile1 })}
											placeholder="Enter Mobile1" />
										{errors.mobile1 && (<div className="mt-2 text-danger">{typeof errors.mobile1.message === "string" && errors.mobile1.message}</div>)}
									</div>
								</div>
								<div className="col-span-12 intro-y lg:col-span-6">
									<div className="input-form">
										<FormLabel className="flex flex-col w-full sm:flex-row">Mobile 2
											<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">
												Required, 10 digits
											</span>
										</FormLabel>
										<FormInput {...register("mobile2")} type="text" value={client.mobile2}
											onChange={handleChange} maxLength={10} className={clsx({ "border-danger": errors.mobile2 })}
											placeholder="Enter Mobile2" />
										{errors.mobile2 && (<div className="mt-2 text-danger">{typeof errors.mobile2.message === "string" && errors.mobile2.message}</div>)}
									</div>
								</div>
							</div>
							<div className="col-span-12 intro-y lg:col-span-6">
								<div className="input-form">
									<FormLabel className="flex flex-col w-full sm:flex-row">Event Name
										<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">
											Required at least 5 character
										</span>
									</FormLabel>
									<FormInput {...register("eventName")} type="text" value={client.eventName}
										onChange={handleChange} maxLength={200} className={clsx({ "border-danger": errors.eventName })}
										placeholder="Enter eventName" />
									{errors.eventName && (<div className="mt-2 text-danger"> {typeof errors.eventName.message === "string" && errors.eventName.message} </div>)}
								</div>
							</div>
							<div className="mt-3 input-form">
								<FormLabel className="flex flex-col w-full sm:flex-row">Address
									<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">
										Required, at least 5 characters
									</span>
								</FormLabel>
								<FormTextarea {...register("address")} value={client.address}
									onChange={handleChange} className={clsx({ "border-danger": errors.address, })}
									placeholder="Type your Address" />
								{errors.address && (<div className="mt-2 text-danger">{typeof errors.address.message === "string" && errors.address.message}</div>)}
							</div>
							<div className="mt-3 input-form">
								<FormLabel className="flex flex-col w-full sm:flex-row">Event Address
									<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">
										Required, at least 5 characters
									</span>
								</FormLabel>
								<FormTextarea {...register("eventAddress")} value={client.eventAddress} onChange={handleChange}
									className={clsx({ "border-danger": errors.eventAddress, })}
									placeholder="Type your Event Address" />
								{errors.eventAddress && (<div className="mt-2 text-danger">{typeof errors.eventAddress.message === "string" && errors.eventAddress.message}</div>)}
							</div>
							<div className="input-form mb-5">
								<FormLabel className="flex flex-col w-full sm:flex-row">Caste
									<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">
										Required, at least 2 characters
									</span>
								</FormLabel>
								<FormSelect {...register("casteId")} value={client.casteId} onChange={handleChange}>
									<option value='0'>Select Caste</option>
									{(castes != undefined) ?
										castes.map((caste: any, i: number) => <option key={i} value={caste.id}>{caste.caste}</option>)
										: <option>Nothing to show</option>}
								</FormSelect>
								{errors.casteId && (<div className="mt-2 text-danger">{typeof errors.casteId.message === "string" && errors.casteId.message}</div>)}
							</div>
							<Button variant="primary" type="submit" className="m-2">Save & Proceed</Button>
						</form>
					</div>
					<Notification id="success-notification-content" className="flex hidden">
						<Lucide icon="CheckCircle" className="text-success" />
						<div className="ml-4 mr-4">
							<div className="font-medium">Registration success!</div>
							<div className="mt-1 text-slate-500"> Please check your e-mail for further info! </div>
						</div>
					</Notification>
					<Dialog id='failed-notification-content' staticBackdrop open={invalidAlert} onClose={() => { setInvalidAlert(false); }} >
						<Dialog.Panel className="px-5 py-10">
							<div className="text-center">
								<div className="mb-5">Invalid data...!</div>
								<Button type="button" variant="primary" className="w-24 mr-2" onClick={() => setInvalidAlert(false)}>Ok</Button>
							</div>
						</Dialog.Panel>
					</Dialog>
				</div>
			</div>
		</>
	);
}

export default Main;