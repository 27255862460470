import { Fragment, useEffect, useRef, useState } from "react";
import Button from "../../../../base-components/Button";
import FormInline from "../../../../base-components/Form/FormInline";
import FormInput from "../../../../base-components/Form/FormInput";
import FormLabel from "../../../../base-components/Form/FormLabel";
import Lucide from "../../../../base-components/Lucide";
import Table from "../../../../base-components/Table";
import Dialog from "../../../../base-components/Headless/Dialog";
import { useLoader } from "../../../../base-components/Loader";

export default function Main(props) {
	const { showLoader, hideLoader } = useLoader();

	const updateButtonRef = useRef();
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [successModalPreview, setSuccessModalPreview] = useState(false);
	const [modal, setModal] = useState({ msg: '', title: '', type: '' });

	const [wastage, setWastage] = useState([]);

	useEffect(() => { initWastage() }, [])

	return (
		<>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
				<h2 className="mr-auto text-lg font-medium">Wastage Report</h2>
			</div>
			<div className="grid grid-cols-12 gap-2 mt-2">
				<div className="col-span-12 intro-y lg:col-span-12">
					<div className="intro-y box h-full mt-2">
						<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
							<h2 className="mr-auto text-base font-medium">Wastage Report</h2>
						</div>
						<div className="p-5">
							{wastage.map((subevent, index) => (
								<div className="mt-5" key={index} >
									<FormInline className="flex-col items-start pt-5 mt-5 xl:flex-row first:mt-0 first:pt-0">
										<FormLabel className="xl:w-64 xl:!mr-10">
											<div className="text-left">
												<div className="flex items-center"><div className="font-medium">{(index + 1) + '. ' + subevent.name}</div></div>
												<div className="mt-3 text-xs leading-relaxed text-slate-500">
													Apply price and stock on all variants or based on
													certain variant codes.
												</div>
											</div>
										</FormLabel>
									</FormInline>
									<div className="overflow-x-auto">
										<Table className="border rounded">
											<Table.Thead>
												<Table.Tr>
													<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap"></Table.Th>
													<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap">
														Category
													</Table.Th>
													<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap !px-2">
														<div className="flex items-center">Quantity</div>
													</Table.Th>
												</Table.Tr>
											</Table.Thead>
											<Table.Tbody>
												{subevent.categories.map((cat, i) => (
													<Fragment key={i}>
														<Table.Tr>
															<Table.Td rowSpan={cat.dishes.length + 1} className="border-r text-center">{i + 1}</Table.Td>
															<Table.Td rowSpan={cat.dishes.length + 1} className="border-r">
																<h3 className="text-center text-xl font-medium leading-none">{cat.name}</h3>
															</Table.Td>
														</Table.Tr>
														{cat.dishes.map((sel, i1) =>
															<Table.Tr key={i1} >
																<Table.Td className="!px-2 dish">
																	<div className="flex align-center gap-2">
																		<b className='w-full flex items-center p-2 hover:animate-pulse hover:bg-secondary hover:rounded'>{i1 + 1}. {sel.name}</b>
																		<FormInput type="number" placeholder='Quantity' className="w-250" value={sel.qty ?? 0}
																			onChange={(e) => {
																				sel.qty = Number(e.target.value)
																				setWastage([...wastage])
																			}} />
																		<b className='mt-2'>kg/people</b>
																	</div>
																</Table.Td>
															</Table.Tr>)}
													</Fragment>)
												)}
											</Table.Tbody>
										</Table>
									</div>
								</div>
							))}
							{wastage && <div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
								<Button variant="soft-primary" className="mx-auto my-3"
									onClick={() => { setConfirmationModal(true) }} >
									<Lucide icon='Save' className='h-4' /> Save
								</Button>
							</div>}
						</div>
					</div>
				</div>
			</div>

			<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${modal.type}`} />
						<div className="mt-5 text-3xl">{modal.title}</div>
						<div className="mt-2 text-slate-500">{modal.msg}</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24" > Ok </Button>
					</div>
				</Dialog.Panel>
			</Dialog>
			<Dialog open={confirmationModal} initialFocus={updateButtonRef} onClose={() => { setConfirmationModal(false); }}>
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="Edit" className="w-16 h-16 mx-auto mt-3 text-danger" />
						<div className="mt-5 text-3xl">Are you sure?</div>
						<div className="mt-2 text-slate-500">Save Wastage Report<br />This process cannot be undone.</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button variant="outline-secondary" type="button" onClick={() => { setConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
						<Button variant="success" type="button" className="text-white w-24" ref={updateButtonRef}
							onClick={() => {
								const filteredData = wastage.map((event) => ({ id: event.id, dishes: [].concat(...event.categories.map((category) => category.dishes.map(({ name, ...rest }) => rest))), }));
								saveWastage(filteredData)
							}} >Save</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
		</>
	)

	async function initWastage() {
		try {
			showLoader()
			const f = await fetch('/api/ae_wastage/' + props.eventId)
			if (f.ok) {
				const x = await f.json()
				if (x.e) console.error(x); else setWastage(x.d)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	async function saveWastage(data) {
		try {
			setConfirmationModal(false)
			showLoader()
			const f = await fetch('/api/ae_wastage', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data), })
			if (f.ok) {
				const x = await f.json()
				if (x.e) setModal({ msg: x.d, title: 'Failed', type: 'error' })
				else setModal({ msg: x.d, title: 'Success', type: 'success' })
				setSuccessModalPreview(true)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
}