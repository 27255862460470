import { Fragment, useEffect, useRef, useState } from "react";
import Button from "../../../../base-components/Button";
import FormInput from "../../../../base-components/Form/FormInput";
import Lucide from "../../../../base-components/Lucide";
import Table from "../../../../base-components/Table";
import Dialog from "../../../../base-components/Headless/Dialog";
import { useLoader } from "../../../../base-components/Loader";

export default function Main(props) {
	const { showLoader, hideLoader } = useLoader();

	const updateButtonRef = useRef();
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [successModalPreview, setSuccessModalPreview] = useState(false);
	const [modal, setModal] = useState({ msg: '', title: '', type: '' });

	const [utensils, setUtensils] = useState([]);

	useEffect(() => { initUtensils() }, [])

	return (
		<>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
				<h2 className="mr-auto text-lg font-medium">Utensils Checking</h2>
			</div>
			<div className="grid grid-cols-12 gap-2 mt-2">
				<div className="col-span-12 intro-y lg:col-span-12">
					<div className="intro-y box h-full mt-2">
						<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
							<h2 className="mr-auto text-base font-medium">Utensils Checking</h2>
						</div>
						<div className="p-5">
							{utensils.length > 0 ?
								<Table className="border rounded">
									<Table.Thead>
										<Table.Tr>
											<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap"></Table.Th>
											<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap">Category</Table.Th>
											<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap !px-2">Quantity</Table.Th>
											<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap !px-2">Outward</Table.Th>
											<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap !px-2">Inward</Table.Th>
										</Table.Tr>
									</Table.Thead>
									<Table.Tbody>
										{utensils.map((cat, i) => (
											<Fragment key={i}>
												<Table.Tr>
													<Table.Td rowSpan={cat.items.length + 1} className="border-r text-center">{i + 1}</Table.Td>
													<Table.Td rowSpan={cat.items.length + 1} className="border-r">
														<h3 className="text-center text-xl font-medium leading-none">{cat.name}</h3>
													</Table.Td>
												</Table.Tr>
												{cat.items.map((sel, i1) =>
													<Table.Tr key={i1} >
														<Table.Td className="!px-2 dish">
															<div className="flex align-center gap-2">
																<b className='w-full flex items-center p-2 hover:animate-pulse hover:bg-secondary hover:rounded'>{i1 + 1}. {sel.name}</b>
															</div>
														</Table.Td>
														<Table.Td className="!px-2 dish">
															<FormInput disabled type="number" placeholder='Outward' className="w-250" value={sel.outward} />
															{/* onChange={(e) => {*/}
															{/*	sel.outward = Number(e.target.value)*/}
															{/*}}*/}
														</Table.Td>
														<Table.Td className="!px-2 dish">
															<FormInput type="number" max={sel.out} placeholder='Inward' className="w-250" value={sel.inward}
																onChange={(e) => {
																	sel.inward = Number(e.target.value)
																	setUtensils([...utensils])
																}} />
														</Table.Td>
													</Table.Tr>)}
											</Fragment>)
										)}
									</Table.Tbody>
								</Table>
								: <b>Utensils Outward not found<br />Go to Utensils Outward & add</b>
							}
							<div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
								<Button variant="soft-primary" className="mx-auto my-3"
									onClick={() => { setConfirmationModal(true) }} >
									<Lucide icon='Save' className='h-4' /> Save
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${modal.type}`} />
						<div className="mt-5 text-3xl">{modal.title}</div>
						<div className="mt-2 text-slate-500">{modal.msg}</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24" > Ok </Button>
					</div>
				</Dialog.Panel>
			</Dialog>
			<Dialog open={confirmationModal} initialFocus={updateButtonRef} onClose={() => { setConfirmationModal(false); }}>
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="Edit" className="w-16 h-16 mx-auto mt-3 text-danger" />
						<div className="mt-5 text-3xl">Are you sure?</div>
						<div className="mt-2 text-slate-500">Save People Details<br />This process cannot be undone.</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button variant="outline-secondary" type="button" onClick={() => { setConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
						<Button variant="success" type="button" className="text-white w-24" ref={updateButtonRef}
							onClick={() => { saveUtensils(utensils.flatMap(({ items }) => items.map(({ id, inward }) => ({ id, qty: inward })))) }} >Save</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
		</>
	);

	async function initUtensils() {
		try {
			showLoader()
			const f = await fetch('/api/ae_utensils/' + props.eventId)
			if (f.ok) {
				const x = await f.json()
				if (x.e) console.error(x); else setUtensils(x.d)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	async function saveUtensils(data) {
		try {
			setConfirmationModal(false)
			showLoader()
			const f = await fetch('/api/ae_utensils', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data), })
			if (f.ok) {
				const x = await f.json()
				if (x.e) setModal({ msg: x.d, title: 'Failed', type: 'error' })
				else setModal({ msg: x.d, title: 'Success', type: 'success' })
				setSuccessModalPreview(true)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
}