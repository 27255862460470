// eventIdSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EventIdState {
    dateRange: string | null;
}

const initialState: EventIdState = {
    dateRange: null,
};

const dateRangeSlice = createSlice({
    name: "dateRange",
    initialState,
    reducers: {
        setDateRange: (state, action: PayloadAction<string>) => {
            state.dateRange = action.payload;
        },
        clearDateRange: (state) => {
            state.dateRange = null;
        },
    },
});

export const { setDateRange, clearDateRange } = dateRangeSlice.actions;
export default dateRangeSlice.reducer;
