import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import Button from "../../../base-components/Button";
import { FormInput, FormLabel } from "../../../base-components/Form";
import { Dialog } from "../../../base-components/Headless";
import { useLoader } from "../../../base-components/Loader";
import Lucide from "../../../base-components/Lucide";

export default function Main() {
	const { showLoader, hideLoader } = useLoader();
	//const { vendor_id, order_id } = useParams();

	const tableRef2 = createRef<HTMLDivElement>();
	const tabulator2 = useRef<Tabulator>();

	const sendButtonRef = useRef();

	//var utensilsCategories = []

	const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false);
	const [successModalPreview, setSuccessModalPreview] = useState(false);
	const [msg, setMsg] = useState('');
	const [title, setTitle] = useState('');
	const [type, setType] = useState('');

	const [category, setCategory] = useState('')

	const [utensils, setUtensils] = useState([])
	//const [utensilsCategories, setUtensilsCategories] = useState([])
	//var utensils = []

	const initTabulator = () => {
		if (tableRef2.current) {
			tabulator2.current = new Tabulator(tableRef2.current, {
				data: utensils, groupBy: "ucat",
				//ajaxURL: "api/utensils_category", paginationMode: "remote", filterMode: "remote", sortMode: "remote",
				movableColumns: true, ajaxResponse: (_url, p, res) => {
					//setTotPages(res.last_page)
					const page = p.page, size = p.size
					const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));
					res.data = processedData
					return res
				},
				pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitColumns", placeholder: "No matching records found",
				//groupHeader: (value: any, count: number, data: any, group: GroupComponent) => { return value },
				columns: [
					{ title: "Sr.", field: "srno", minWidth: 30, print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Name", field: "name", minWidth: 150, headerFilter: 'input', headerFilterPlaceholder: 'Search', print: true, hozAlign: "center", headerHozAlign: 'center', },
					{ title: "Rate", field: "in", minWidth: 50, print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: 'number' },
					{ title: "uc", field: "ucat", print: false, hozAlign: "center", visible: false },
				],
			});
		}
		//tabulator.current?.on('tableBuilt', () => {tabulator.current?.redraw();});
		tabulator2.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
	};

	// Redraw table onresize
	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator2.current) {
				tabulator2.current.redraw();
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
			}
		});
	};

	const loadData = async () => {
		try {
			showLoader()
			const f = await fetch('/api/utensils')
			if (f.ok) {
				const d = await f.json()
				d.u.forEach((u: any, i: number) => {
					u.srno = i + 1;
					u.in = 0;
					u.out = 0;
					const c = d.uc.find(x => x.id == u.catid).name
					u.ucat = c;
				});
				setUtensils(d)
				//setUtensilsCategories(d.uc)
				//utensils = d.u
				//utensilsCategories = d.uc
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	useEffect(() => { loadData() }, [])

	useEffect(() => {
		initTabulator()
		reInitOnResizeWindow()
	}, [utensils])

	return <>
		<div className="p-5 mt-5 intro-y box">
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
				<h2 className="mr-auto text-lg font-medium">Vendor Rates</h2>
			</div>
			<div className="overflow-x-auto scrollbar-hidden">
				<div ref={tableRef2} className="mt-5"></div>
			</div>
			<Button variant="outline-secondary" onClick={loadData} className="hidden sm:flex mx-auto"><Lucide icon="Save" className="w-4 h-4 mr-2" />{" "}Submit</Button>
		</div>
		<Dialog open={headerFooterModalPreview} onClose={() => { setHeaderFooterModalPreview(false); }} initialFocus={sendButtonRef}>
			<Dialog.Panel>
				<Dialog.Title>
					<h2 className="mr-auto text-base font-medium">Add Utensils Category</h2>
					{/*<Button variant="outline-secondary" className="hidden sm:flex"><Lucide icon="File" className="w-4 h-4 mr-2" />{" "}Download Docs</Button>*/}
					{/*<Menu className="sm:hidden">*/}
					{/*	<Menu.Button className="block w-5 h-5"><Lucide icon="MoreHorizontal" className="w-5 h-5 text-slate-500" /></Menu.Button>*/}
					{/*	<Menu.Items className="w-40"><Menu.Item><Lucide icon="File" className="w-4 h-4 mr-2" />Download Docs</Menu.Item></Menu.Items>*/}
					{/*</Menu>*/}
				</Dialog.Title>
				<Dialog.Description className="grid grid-cols-12 gap-4 gap-y-3">
					<div className="col-span-12 sm:col-span-12">
						<FormLabel htmlFor="modal-form-1">Utensils Category Name</FormLabel>
						<FormInput id="modal-form-1" type="text" placeholder="Category Name" onChange={(e) => setCategory(e.target.value)} />
					</div>
				</Dialog.Description>
				<Dialog.Footer>
					<Button type="button" variant="outline-secondary" onClick={() => { setHeaderFooterModalPreview(false); }} className="w-20 mr-1">Cancel</Button>
					<Button variant="primary" type="button" ref={sendButtonRef} onClick={saveCategory}>Add Utensils Category</Button>
				</Dialog.Footer>
			</Dialog.Panel>
		</Dialog>
		<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
			<Dialog.Panel>
				<div className="p-5 text-center">
					<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${type}`} />
					<div className="mt-5 text-3xl">{title}</div>
					<div className="mt-2 text-slate-500">
						{msg}
					</div>
				</div>
				<div className="px-5 pb-8 text-center">
					<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24" > Ok </Button>
				</div>
			</Dialog.Panel>
		</Dialog>
	</>

	async function saveCategory() {
		try {
			showLoader()
			const f = await fetch('/api/add_utensils_category', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ name: category }), })
			if (f.ok) {
				const r = await f.json()
				if (r.e) {
					setType('error')
					setTitle(r.d)
					setMsg('Save Filed')
				} else {
					setType('success')
					setTitle(r.d)
					setMsg('Save Successful')
				}
				setHeaderFooterModalPreview(false)
				setSuccessModalPreview(true);
			}
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
}