import Lucide from "../../../base-components/Lucide";
import { Menu } from "../../../base-components/Headless";
import Button from "../../../base-components/Button";
import { FormInput, FormSelect } from "../../../base-components/Form";
import * as xlsx from "xlsx";
import { useEffect, useRef, createRef, useState } from "react";
import { createIcons, icons } from "lucide";
import { CellComponent, TabulatorFull as Tabulator } from "tabulator-tables";
//import { stringToHTML } from "../../utils/helper";
import '../../../assets/css/tabulator-edit.css'
interface Menu2 {
	id: number;
	content: number;
	qty: number;
	unit: number;
}
interface Menu {
	id: number;
	dish: number;
	qty: number;
	unit: number;
	serviceHistory: Menu2[]
}

export default function Main() {
	const tableRef = createRef<HTMLDivElement>();
	const tabulator = useRef<Tabulator>();

	const [filter, setFilter] = useState({ field: "dish", type: "like", value: "", });

	var dishes: { [key: number]: string } = {},
		units: { [key: number]: string } = {},
		contents: { [key: number]: string } = {};
	//const [dataList, setDataList] = useState<Response[]>([
	//	{ id: 1, dish: "Dish", qty: "1", unit: 0, },
	//	{ id: 2, dish: "Dish 2", qty: "5", unit: 1, },
	//	{ id: 3, dish: "Dish 3", qty: "9", unit: 2, },
	//]);
	async function loadInit() {//const params = `dishName=${dishMaster.dish}&kgs=${dishMaster.kg1},${dishMaster.kg2},${dishMaster.kg3}&prices=${dishMaster.price1},${dishMaster.price2},${dishMaster.price3}`
		try {
			const f = await fetch('/api/add_menu_init')
			if (f.ok) {
				const r = await f.json()
				dishes = r.dishes
				units = r.units
				contents = r.contents

				initTabulator()
				reInitOnResizeWindow()
			}
		} catch (e) { console.error(e) } finally { }
	}

	var dataList: Menu[] = [
		{//"Gulabjamun"
			id: 1, dish: 1, qty: 1, unit: 1,
			serviceHistory: [
				{ id: 1, content: 1, qty: 1, unit: 1 },
				{ id: 2, content: 2, qty: 2, unit: 2 },
			]
		},
		{//"Panir Butter Masala"
			id: 2, dish: 2, qty: 1, unit: 2,
			serviceHistory: [
				{ id: 3, content: 3, qty: 1, unit: 1 },
				{ id: 4, content: 4, qty: 2, unit: 2 },
			]
		},
		{//"Panir Butter Masala"
			id: 3, dish: 2, qty: 1, unit: 2,
			serviceHistory: [
				{ id: 5, content: 5, qty: 1, unit: 1 },
				{ id: 6, content: 6, qty: 2, unit: 2 },
			]
		},
	]

	//cell, formatterParams, onRendered
	// <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="stroke-1.5 block mx-auto"><circle cx="12" cy="12" r="10"></circle><path d="M8 12h8"></path><path d="M12 8v8"></path></svg>
	//plus-circle
	var hideIcon = function () { return '<i data-lucide="Chevrons-Right" class="w-8 h-8 mr-1"></i>'; };
	var deleteIcon = function () { return '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>'; };
	var deleteIcon1 = function () { return '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>'; };
	//const dishes: { [key: number]: string } = {
	//	0: "Gulabjamun",
	//	1: "Panir Butter Masala",
	//	2: "Puri",
	//	3: "Masala bhat",
	//	4: "Dish1",
	//}
	//const units: { [key: number]: string } = {
	//	0: "Kg",
	//	1: "Ltr.",
	//	2: "Grams",
	//}

	//var filteredDishes = () => {
	//	const t = dishes as { [key: number]: string }
	//	dataList.forEach((i) => {
	//		delete t[i.dish]
	//	})
	//	return t as string[]
	//}

	//var updatedListValues: { [key: number]: string } = {...dishes};
	var filteredDishes: { [key: number]: string } = { ...dishes };

	//const editedCallback = (cell: CellComponent) => {
	//	const d = cell.getData() as any
	//	const dish = d.dish, qty = d.qty, unit = d.unit
	//}
	const editableCallback = (cell: CellComponent) => {
		//const rowData = cell.getRow().getCell("dish").getValue();
		const field = cell.getField()
		if (field == 'dish') {
			const t = { ...dishes }
			dataList.forEach((i) => { delete t[i.dish]; })
			filteredDishes = t
			//_column.modules.edit.params
			const c = cell.getColumn() as any
			c._column.modules.edit.params.values = filteredDishes
		}
		return true;
	};

	const dishFormatter = (cell: CellComponent) => {
		const d = cell.getData() as any
		return dishes[d.dish]
	};
	const unitFormatter = (cell: CellComponent) => {
		const d = cell.getData() as any
		return units[d.unit]
	};
	const contentFormatter = (cell: CellComponent) => {
		const d = cell.getData() as any
		return contents[d.content]
	};


	const initTabulator = () => {
		if (tableRef.current) {
			tabulator.current = new Tabulator(tableRef.current, {
				data: dataList, reactiveData: true, height: 'auto', layout: "fitColumns",
				selectable: true, responsiveLayout: "collapse", responsiveLayoutCollapseStartOpen: false,
				placeholder: "No matching records found",
				columns: [
					{
						title: "Details", print: false, formatter: hideIcon, headerHozAlign: "center", width: 100, hozAlign: "center", headerSort: false,
						cellClick: function (_e, row) {
							const id = (row.getData() as any).id;
							document.querySelector(".subTable" + id + "")?.classList.toggle('hidden');
							row.getElement().classList.toggle('tbl-opened');
							setTimeout(() => {
								createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", })
							}, 10);
						}
					},
					{
						title: "Dish", field: "dish", print: true, formatter: dishFormatter,
						//cellEdited: editedCallback,
						editable: editableCallback, editor: "list", validator: "required",
						editorParams: { //emptyValue: 0,
							autocomplete: true, allowEmpty: false, listOnEmpty: true,
							values: filteredDishes
						}
					},
					{ title: "Quantity", field: "qty", print: true, editable: true, editor: 'number', editorParams: { verticalNavigation: "table", min: 1, }, validator: "required" },
					{
						title: "Unit", field: "unit", print: true, formatter: unitFormatter, validator: "required",
						editable: editableCallback, editor: "list",
						editorParams: { //emptyValue: 0,
							autocomplete: true, allowEmpty: false, listOnEmpty: true,
							values: units
						}
					},
					{
						title: "Actions", print: false, formatter: deleteIcon, headerHozAlign: "center", width: 100, hozAlign: "center", headerSort: false,
						cellClick: function (_e, row) {
							const id = (row.getData() as any).id;
							const index = dataList.findIndex((item) => item.id === id);
							dataList.splice(index, 1);//tabulator.current?.deleteRow(index+1)
						}
					}
				],
				rowFormatter: function (row) {
					const id = row.getData().id;

					var holderEl = document.createElement("div");
					holderEl.style.boxSizing = "border-box";
					holderEl.style.padding = "10px 10px 10px 10px";
					holderEl.style.borderTop = "1px solid #333";
					holderEl.style.borderBottom = "1px solid #333";
					holderEl.style.background = "#ddd";
					holderEl.setAttribute('class', "subTable" + id + " hidden text-center");

					var tableEl = document.createElement("div");
					tableEl.style.border = "1px solid #333";
					tableEl.setAttribute('class', "subTable" + id + "");

					holderEl.appendChild(tableEl);
					var btn = document.createElement("button");
					btn.textContent = "Add New Content"
					btn.setAttribute('class', 'transition duration-200 border my-2 py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary shadow-md')
					holderEl.appendChild(btn);

					row.getElement().appendChild(holderEl);
					var subData = row.getData().serviceHistory
					//var _subTable =
					new Tabulator(tableEl, {
						layout: "fitColumns", data: subData, placeholder: 'No items found for this dish',
						columns: [
							{
								title: "Content", field: "content", print: true,
								formatter: contentFormatter, validator: "required",
								editable: editableCallback, editor: "list",
								editorParams: { //emptyValue: 0,
									autocomplete: true, allowEmpty: false, listOnEmpty: true,
									values: contents
								}
							},
							{ title: "Quantity", field: "qty", print: true, editable: true, editor: 'input' },
							{ title: "Unit", field: "unit", print: true, editable: true, editor: 'input' },
							{
								title: "Actions", print: false, formatter: deleteIcon1, headerHozAlign: "center", width: 100, hozAlign: "center", headerSort: false,
								cellClick: function (_e, r) {
									const rid = (r.getData() as any).id;
									const index = subData.findIndex((item: any) => item.id === rid);
									subData.splice(index, 1);
									//subTable.deleteRow(index + 1)
									tabulator.current?.redraw(true)

									document.querySelector(".subTable" + id + "")?.classList.toggle('hidden');
									row.getElement().getElementsByTagName('div')[0].classList.add('tbl-opened');

									setTimeout(() => {
										createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", })
									}, 10);
									//const index1 = dataList.findIndex((item) => item.id === id);
									//const index = dataList[index1].serviceHistory.findIndex((item) => item.id === id);

									//dataList[index1].serviceHistory.splice(index, 1);
									//tabulator.current?.redraw()
								}
							}
						]
					})
				},
			});
		}

		tabulator.current?.on("renderComplete", () => {
			createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
		});
	};

	// Redraw table onresize
	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator.current) {
				tabulator.current.redraw();
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
			}
		});
	};

	// Filter function
	const onFilter = () => {
		if (tabulator.current) {
			tabulator.current.setFilter(filter.field, filter.type, filter.value);
		}
	};

	// On reset filter
	const onResetFilter = () => {
		setFilter({ ...filter, field: "dish", type: "like", value: "", });
		onFilter();
	};

	// Export
	const onExportCsv = () => {
		if (tabulator.current) tabulator.current.download("csv", "data.csv");
	};

	const onExportJson = () => {
		if (tabulator.current) tabulator.current.download("json", "data.json");
	};

	const onExportXlsx = () => {
		if (tabulator.current) {
			(window as any).XLSX = xlsx;
			tabulator.current.download("xlsx", "data.xlsx", { sheetName: "Products", });
		}
	};

	const onExportHtml = () => {
		if (tabulator.current) {
			tabulator.current.download("html", "data.html", { style: true, });
		}
	};

	// Print
	const onPrint = () => {
		if (tabulator.current) tabulator.current.print();
	};

	useEffect(() => { loadInit() }, [])

	const showList = () => console.info(dataList)

	return (
		<>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
				<h2 className="mr-auto text-lg font-medium">Tabulator</h2>
				<div className="flex w-full mt-4 sm:w-auto sm:mt-0">
					<Button variant="primary" className="mr-2 shadow-md" onClick={showList} >Add Menu</Button>
					<Menu className="ml-auto sm:ml-0">
						<Menu.Button as={Button} className="px-2 font-normal !box">
							<span className="flex items-center justify-center w-5 h-5"><Lucide icon="Plus" className="w-4 h-4" /></span>
						</Menu.Button>
						<Menu.Items className="w-40">
							<Menu.Item><Lucide icon="FilePlus" className="w-4 h-4 mr-2" /> New Category</Menu.Item>
							<Menu.Item><Lucide icon="UserPlus" className="w-4 h-4 mr-2" /> New Group</Menu.Item>
						</Menu.Items>
					</Menu>
				</div>
			</div>
			<div className="p-5 mt-5 intro-y box">
				<div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
					<form
						id="tabulator-html-filter-form"
						className="xl:flex sm:mr-auto"
						onSubmit={(e) => { e.preventDefault(); onFilter(); }} >
						<div className="items-center sm:flex sm:mr-4">
							<label className="flex-none w-12 mr-2 xl:w-auto xl:flex-initial">Field</label>
							<FormSelect id="tabulator-html-filter-field" value={filter.field}
								onChange={(e) => { setFilter({ ...filter, field: e.target.value, }); }}
								className="w-full mt-2 2xl:w-full sm:mt-0 sm:w-auto">
								<option value="dish">Dish</option>
								<option value="qty">Quantity</option>
								<option value="unit">Unit</option>
							</FormSelect>
						</div>
						<div className="items-center mt-2 sm:flex sm:mr-4 xl:mt-0">
							<label className="flex-none w-12 mr-2 xl:w-auto xl:flex-initial">Type</label>
							<FormSelect id="tabulator-html-filter-type" value={filter.type}
								onChange={(e) => { setFilter({ ...filter, type: e.target.value, }); }}
								className="w-full mt-2 sm:mt-0 sm:w-auto">
								<option value="like">like</option>
								<option value="=">=</option>
								<option value="<">&lt;</option>
								<option value="<=">&lt;=</option>
								<option value=">">&gt;</option>
								<option value=">=">&gt;=</option>
								<option value="!=">!=</option>
							</FormSelect>
						</div>
						<div className="items-center mt-2 sm:flex sm:mr-4 xl:mt-0">
							<label className="flex-none w-12 mr-2 xl:w-auto xl:flex-initial">Value</label>
							<FormInput id="tabulator-html-filter-value" value={filter.value}
								onChange={(e) => { setFilter({ ...filter, value: e.target.value, }); }}
								type="text" className="mt-2 sm:w-40 2xl:w-full sm:mt-0" placeholder="Search..." />
						</div>
						<div className="mt-2 xl:mt-0">
							<Button id="tabulator-html-filter-go" className="w-full sm:w-16"
								variant="primary" type="button"
								onClick={onFilter}>Go</Button>
							<Button id="tabulator-html-filter-reset" className="w-full mt-2 sm:w-16 sm:mt-0 sm:ml-1"
								variant="secondary" type="button"
								onClick={onResetFilter}>Reset</Button>
						</div>
					</form>
					<div className="flex mt-5 sm:mt-0">
						<Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto"
							variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" /> Print</Button>
						<Menu className="w-1/2 sm:w-auto">
							<Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">
								<Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export
								<Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />
							</Menu.Button>
							<Menu.Items className="w-40">
								<Menu.Item onClick={onExportCsv}> <Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export CSV </Menu.Item>
								<Menu.Item onClick={onExportJson}> <Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export JSON </Menu.Item>
								<Menu.Item onClick={onExportXlsx}> <Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export XLSX </Menu.Item>
								<Menu.Item onClick={onExportHtml}> <Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export HTML </Menu.Item>
							</Menu.Items>
							<Button variant="primary" className="mx-2" onClick={showList} >Add New Dish</Button>
						</Menu>
					</div>
				</div>
				<div className="overflow-x-auto scrollbar-hidden">
					<div id="tabulator" ref={tableRef} className="mt-5"></div>
				</div>
			</div>
		</>
	);
}
