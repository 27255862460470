import React from 'react';
import Button from '../../../../base-components/Button';
import { FormInput, FormSelect } from '../../../../base-components/Form';

interface Props {
	dmd: DishMasterData;
	handleEditClick: (e: any, dmd: DishMasterData) => void;
	handleDeleteClick: (id: number) => void;
	unitProcessList: any;
}

const ReadOnlyRow: React.FC<Props> = ({ dmd, handleEditClick, handleDeleteClick, unitProcessList }) => {
	return (
		<>
			<tr>
				<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
					<FormInput value={dmd.content} required disabled />
				</td>
				<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
					<div className="flex justify-center align-center gap-2">
						{dmd.val.map((number: number, i: number) => (<FormInput key={i} type='number' value={number} required disabled />))}
					</div>
				</td>
				<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
					{/*<FormInput value={dmd.unit} required disabled />*/}
					<FormSelect className="mt-2 sm:mr-2" aria-label="Unit" value={dmd.unit} disabled>
						<option value='0'>Unit</option>
						{(unitProcessList != undefined && unitProcessList.units != undefined) ? unitProcessList.units.map((p: any) => <option value={p.value} key={p.value}>{p.text}</option>) : <option>Nothing to show</option>}
					</FormSelect>
				</td>
				<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
					{/*<FormInput value={dmd.process} required disabled />*/}
					<FormSelect value={dmd.process} className="mt-2 sm:mr-2" aria-label="Process" disabled>
						<option value='0'>Process</option>
						{(unitProcessList != undefined && unitProcessList.processes != null) ? unitProcessList.processes.map((p: any) => <option value={p.value} key={p.value}>{p.text}</option>) : <option>Nothing to show</option>}
					</FormSelect>
				</td>
				<td className="px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
					<div className="flex justify-center align-center gap-2">
						<Button rounded variant="outline-success" onClick={(e: any) => handleEditClick(e, dmd)}>Edit</Button>{' '}
						<Button rounded variant="outline-danger" onClick={() => handleDeleteClick(dmd.id)}>Delete</Button>
					</div>
				</td>
			</tr>
		</>
	);
};

export default ReadOnlyRow;