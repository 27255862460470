import { Fragment, useEffect, useState } from "react";
import Button from "../../../base-components/Button";
import FormInput from "../../../base-components/Form/FormInput";
import Dialog from "../../../base-components/Headless/Dialog";
import Lucide from "../../../base-components/Lucide";
import { FormSelect } from "../../../base-components/Form";
import { useLoader } from "../../../base-components/Loader";

interface Menu { id?: number, icon: string, pathname?: string, selected?: boolean, title: string, subMenu?: Menu[], position: number, }
interface MenuDivider { id?: number, title: string, }
type Details = { head: string, id: number, icon: string, title: string, path: string, }

export default function Main() {
	const { showLoader, hideLoader } = useLoader();

	const [newEventModalPreview, setnewEventModalPreview] = useState(false);
	const initVal = { head: '', id: 0, icon: 'Activity', title: '', path: '', }
	const [details, setDetails] = useState<Details>(initVal);

	const [navMenu, setNavMenu] = useState<Array<Menu | MenuDivider>>([])
	//const [navMenu, setNavMenu] = useState<Array<Menu | "divider">>([])

	useEffect(() => { loadNavMenu() }, [])

	//function filterSelected(arr) {
	//	return arr.map(item => {
	//		if (item.selected) { return { ...item, subMenu: item.subMenu ? filterSelected(item.subMenu) : undefined }; }
	//		else if (item.subMenu) {
	//			const filteredSubMenu = filterSelected(item.subMenu);
	//			return filteredSubMenu.length > 0 ? { ...item, subMenu: filteredSubMenu } : undefined;
	//		} else return undefined;
	//	}).filter(Boolean); // Remove any undefined values
	//}

	//const logMenu = () => {const filteredArray = filterSelected(navMenu)}

	return (
		<>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
				<h2 className="mr-auto text-lg font-medium">Nav Menu</h2>
			</div>
			<div className="p-5 mt-5 intro-y box">
				<div className="overflow-x-auto scrollbar-hidden">
					<div className='flex'>
						<Button variant='soft-success' className='ml-auto' rounded onClick={() => {
							setDetails({ ...details, id: -1, head: 'Add Top level menu' })
							setnewEventModalPreview(true)
						}}>Add Menu</Button>
					</div>
					{navMenu.map((x: any, i) => (
						x.title == 'divider' ? <div className='flex items-center' key={i}>
							<hr className='my-2 w-[88%]' />
							<Button variant='outline-danger' rounded className='ml-auto my-2' onClick={() => { removeNavMenu(x.id) }} >Delete Divider</Button>
						</div>
							: <Fragment key={i} >
								<ul id={'l1' + i} className='pl-5 mt-2 border-4 border-white border-l-success'>
									<li className='flex items-center bg-success rounded p-2 text-white'>
										<Button variant='soft-secondary' className='text-white' onClick={() => { toggleMenu('l1' + i) }}><Lucide icon='ChevronDown' />{x.title} {x.pathname}</Button>
										<FormSelect className='ml-auto w-100 text-primary'
											onChange={(e) => {
												const position = Number(e.target.value)
												if (position != 0) changePosition(x.id, position)
											}} value={x.position ?? ''}>{loadPosition(navMenu.filter(x => x.title != 'divider').length)}</FormSelect>
										<Button variant='outline-primary' rounded className='mx-2'
											onClick={() => {
												setDetails({ ...details, id: x.id, head: 'Add menu inside ' + x.title })
												setnewEventModalPreview(true)
											}}>Add Inside</Button>
										<Button variant='outline-secondary' rounded className='mx-2'
											onClick={() => {
												setDetails({ ...details, head: 'Edit ' + x.title, id: x.id, icon: x.icon, title: x.title, path: x.pathname, })
												setnewEventModalPreview(true)
											}}>Edit</Button>
										<Button variant='outline-danger' rounded className='mx-2' onClick={() => { removeNavMenu(x.id) }} >Delete</Button>
									</li>
									{x.subMenu && <li>
										{x.subMenu.map((a, i1) => (
											<ul key={i1} id={'l2' + i + i1} className='pl-5 ml-20 border-4 border-white border-l-indigo-500'>
												<li className='flex border-l-indigo-500  bg-primary rounded p-2 text-white'>
													{a.title} {a.pathname}
													<Button variant='outline-secondary' rounded className='ml-auto mr-2'
														onClick={() => {
															setDetails({ ...details, head: 'Edit ' + a.title, id: a.id, icon: a.icon, title: a.title, path: a.pathname, })
															setnewEventModalPreview(true)
														}}>Edit</Button>
													<FormSelect className='w-100 text-primary'
														onChange={(e) => {
															const position = Number(e.target.value)
															if (position != 0) changePosition(a.id, position)
														}} value={a.position ?? ''} >{loadPosition(x.subMenu.length)}</FormSelect>
													<Button variant='outline-danger' rounded className='mx-2' onClick={() => { removeNavMenu(a.id) }} >Delete</Button>
												</li>
											</ul>
										))}
									</li>}
								</ul>
								{navMenu[i + 1] && navMenu[i + 1].title != 'divider' && <div className='flex items-center justify-center'>
									<Button variant='outline-success' rounded className='' onClick={() => {
										setDetails({ ...details, id: x.id, title: 'divider', icon: '', path: '', head: 'Add Top level divider' })
										setnewEventModalPreview(true)
									}} >Add Divider</Button>
								</div>}
							</Fragment>
					))}
				</div>
				{/*<Button onClick={() => logMenu()} className='mt-5'>Log Menu</Button>*/}
			</div>
			<Dialog open={newEventModalPreview} onClose={() => { setnewEventModalPreview(false) }}>
				<Dialog.Panel>
					<Dialog.Title>
						<h2 className="mr-auto text-base font-medium">{details.head}</h2>
						<Button className="w-24 ml-auto" onClick={() => { setnewEventModalPreview(false) }}><Lucide icon='XCircle' /></Button>
					</Dialog.Title>
					<div className="mx-5 my-3">
						<label className='font-medium'>Icon:</label>
						<FormInput placeholder='Icon' className='mb-5' onChange={(e) => setDetails({ ...details, icon: e.target.value })} value={details.icon} />
						<label className='font-medium'>Title:</label>
						<FormInput placeholder='Title' className='mb-5' onChange={(e) => setDetails({ ...details, title: e.target.value })} value={details.title} />
						<label className='font-medium'>Path: </label>
						<FormInput placeholder='Path' className='mb-5' onChange={(e) => setDetails({ ...details, path: e.target.value })} value={details.path} />
					</div>
					<Dialog.Footer className='mt-10'>
						<Button type="button" variant="primary" className="mr-2"
							onClick={() => {
								addNavMenu()
								setnewEventModalPreview(false)
							}}>{details.head}</Button>
					</Dialog.Footer>
				</Dialog.Panel>
			</Dialog>
		</>
	);

	function toggleMenu(id) {
		const e = document.querySelector('#' + id + ' li:nth-child(2)')
		e.classList.toggle('hidden')
	}

	async function loadNavMenu() {
		try {
			showLoader()
			const f = await fetch('/api/nav2/Admin')
			if (f.ok) {
				const data = await f.json()
				setNavMenu(data)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	async function addNavMenu() {
		try {
			showLoader()
			const d = JSON.stringify({ id: details.id, icon: details.icon, title: details.title, path: details.path ?? '' })
			const url = '/api/' + (details.head.includes('Edit') ? 'edit_nav' : 'add_nav')
			const f = await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
			if (f.ok) {
				//const data = await f.json()
				loadNavMenu()
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	async function removeNavMenu(id) {
		try {
			showLoader()
			const f = await fetch('/api/remove_nav/' + id)
			if (f.ok) {
				//const data = await f.json()
				loadNavMenu()
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	function loadPosition(count) {
		let code = [<option value={0} key={0}>Position</option>]
		for (let i = 0; i < count; i++) code.push(<option value={i + 1} key={i + 1}>{i + 1}</option>)
		return code
	}
	async function changePosition(id, position) {
		try {
			showLoader()
			const d = JSON.stringify({ id, position })
			const f = await fetch('/api/change_nav_position', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
			if (f.ok) {
				//const data = await f.json()
				loadNavMenu()
			}
		}
		catch (e) { console.error(e) } finally { hideLoader() }
	}
}