import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../stores/store";
import Bill from "./Bill";
import People from "./People";
import RawReturn from "./RawReturn";
import Utensils from "./Utensils";
import Wastage from "./Wastage";

export default function Main() {
	const nav = useNavigate()
	const eventId = useSelector((state: RootState) => state.eventId.eventId);

	useEffect(() => { if (eventId == null) nav('/admin') }, [])

	return (
		<>
			{eventId != null ? <>
				<People eventId={eventId} />
				<Wastage eventId={eventId} />
				<RawReturn eventId={eventId} />
				<Utensils eventId={eventId} />
				<Bill eventId={eventId} />
			</> : ''}
		</>
	);
}