import { useEffect, useState } from "react"
import Button from "../../../base-components/Button"
import { FormInline, FormInput, FormTextarea, InputGroup } from "../../../base-components/Form"
import Menu from "../../../base-components/Headless/Menu"
import Lucide from "../../../base-components/Lucide"
import Table from "../../../base-components/Table"
import Dialog from "../../../base-components/Headless/Dialog"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "../../../stores/store"
import { useLoader } from "../../../base-components/Loader"

type Head = { date: string, eventName: string, eventAddress: string, clientName: string, clientMobile: string }
type Quotation = { EventId: number, events: Events[], charges: Additional[], foodCost: number, discount: number }
type Events = { name: string, dishes: Dish[], details: Details }
type Dish = { title: string, contents: string }
type Details = { people: number, date: string, type: string, cost: number, }
type Additional = { title: string, description: string, price: number, }

export default function Main() {
	const { showLoader, hideLoader } = useLoader()

	const nav = useNavigate()
	const eventId = useSelector((state: RootState) => state.eventId.eventId)

	useEffect(() => {
		if (eventId == null) nav('/admin')
		else init()
	}, [eventId])

	const [waNumber, setWaNumber] = useState('')
	const [newEventModalPreview, setnewEventModalPreview] = useState(false)
	const [sendWAModalOpen, setSendWAModalOpen] = useState(false)

	const [head, setHead] = useState<Head>()
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [price, setPrice] = useState(0)

	const [events, setEvents] = useState<Quotation>()

	const totalAmt = () => {
		let total = 0, additionalTotal = 0
		if (events) {
			events.charges.forEach(amt => additionalTotal += amt.price)
			total = events.foodCost + additionalTotal - events.discount
		}
		return total
	}
	const foodCost = () => {
		let foodCost = 0
		if (events) events.events.forEach(event => foodCost += event.details.cost * event.details.people)
		return foodCost
	}

	const loadPdf = async () => {
		try {
			showLoader()
			const f = await fetch('/api/quotation1')
			if (f.ok) {
				const d = await f.json()
				if (d.e) console.error(d)
				else window.open(d.path)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	const loadPdf1 = async () => {
		try {
			showLoader()
			const f = await fetch('/api/quotation')
			if (f.ok) {
				const d = await f.json()
				if (d.e) console.error(d)
				else window.open(d.path)
			} else console.error(f.status, f.statusText)
		} catch (err) { console.error('error: ', err) } finally { hideLoader() }
	}

	const sendWAQuotation = async function () {
		try {
			showLoader()
			const f = await fetch('/api/quotation_wa', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ ...events, mobile: waNumber }), })
			if (f.ok) {
				const x = await f.json()
				if (x.e) console.error(x)
				else { alert(x.msg) }
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	const init = async () => {
		try {
			showLoader()
			const f = await fetch('/api/quotation_init/' + eventId)
			if (f.ok) {
				const x = await f.json()
				if (x.e) {
					alert(JSON.stringify(x))
					console.log(x)
				} else {
					setEvents(x.d.qd)
					setHead(x.d.head)
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	const showQuotation = async () => {
		try {
			showLoader()
			const f = await fetch('/api/quotation', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(events), })
			if (f.ok) {
				const x = await f.json()
				if (x.e) console.error(x)
				else window.open(x.path)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	useEffect(() => { console.log(events) }, [events])

	return <>
		<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
			<h2 className="mr-auto text-lg font-medium">Quotation</h2>
			<div className="flex w-full mt-4 sm:w-auto sm:mt-0">
				<Button variant="primary" className="mr-2 shadow-md" onClick={() => { showQuotation() }} ><Lucide icon='PrinterCheck' className='mr-2' />Print</Button>
				<Button variant="primary" className="mr-2 shadow-md" onClick={() => { setSendWAModalOpen(true) }} ><Lucide icon='Send' className='mr-2' />Send To WhatsApp</Button>
				<Menu className="ml-auto sm:ml-0">
					<Menu.Button as={Button} className="px-2 !box">
						<span className="flex items-center justify-center w-5 h-5"><Lucide icon="Plus" className="w-4 h-4" /></span>
					</Menu.Button>
					<Menu.Items className="w-40">
						{/*<Menu.Item onClick={loadPdf1}><Lucide icon="File" className="w-4 h-4 mr-2" /> Export Word</Menu.Item>*/}
						<Menu.Item onClick={loadPdf}><Lucide icon="File" className="w-4 h-4 mr-2" /> Export PDF</Menu.Item>
					</Menu.Items>
				</Menu>
			</div>
		</div>
		<div id='sp-q' className="mt-2 overflow-hidden intro-y box">
			<div className="flex flex-col px-5 pt-10 text-center lg:flex-row sm:px-20 sm:pt-20 lg:pb-2 sm:text-left">
				<div className="text-3xl font-semibold text-primary">Quotation</div>
				<div className="mt-20 lg:mt-0 lg:ml-auto lg:text-right">
					<div className="text-xl font-medium text-primary">My Cater</div>
					<div className="mt-1">cateringbook@gmail.com</div>
					<div className="mt-1"></div>
				</div>
			</div>
			<div className="flex flex-col px-5 pt-1 pb-5 text-center border-b lg:flex-row sm:px-20 sm:pb-10 sm:text-left">
				<div>
					<div className="text-base text-slate-500">Client Details</div>
					<div className="mt-2 text-medium mx-2">
						<b>Name: </b>{head && head.clientName}<br />
						<b>Mobile: </b>{head && head.clientMobile}<br />
					</div>
					<div className="text-base text-slate-500 mt-4">Event Details</div>
					<div className="mt-2 text-medium mx-2">
						<b>Name: </b> {head && head.eventName}<br />
						<b>Address: </b> {head && head.eventAddress}<br />
					</div>
				</div>
				<div className="mt-10 lg:mt-0 lg:ml-auto lg:text-right">
					<div className="text-base text-slate-500"><b>Date: </b>{head && head.date}</div>
					<div className="mt-1"></div>
				</div>
			</div>
			<div className="px-5 py-2 sm:px-16 sm:py-5">
				{events && events.events.map((event: Events, index: number) => (
					<div key={index}>
						<h1 className="text-xl font-medium text-primary">{(index + 1) + ". " + event.name}</h1>
						<div className="px-5 mx-5 mb-4">
							<div className="grid grid-cols-12 gap-2">
								<div className="col-span-12 lg:col-span-6">
									<ul className='list-disc text-xl font-large text-success'>
										{event.dishes.map((c: Dish, index: number) => (<li key={index}><b>{c.title}: </b>{c.contents}</li>))}
									</ul>
								</div>
								<div className="col-span-12 lg:col-span-6">
									<div className="flex justify-end">
										<InputGroup>
											<InputGroup.Text>People</InputGroup.Text>
											{/*value={people[index]}*/}
											<FormInput type="number" placeholder="People"
												value={event.details.people}
												onChange={(e) => {
													event.details.people = Number(e.target.value)
													events.foodCost = foodCost()
													setEvents({ ...events })
												}} />
										</InputGroup>
									</div>
									<div className="flex justify-end">
										<InputGroup>
											<FormInline>
												<InputGroup.Text>Cost Per Plate</InputGroup.Text>
												<FormInput type="number" placeholder="Cost Per Plate"
													value={event.details.cost}
													onChange={(e) => {
														event.details.cost = Number(e.target.value)
														events.foodCost = foodCost()
														setEvents({ ...events })
													}} />
											</FormInline>
										</InputGroup>
									</div>
									<div className="flex justify-end mt-3">
										<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"><b>Type: </b>{event.details.type}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}

				<br />
				<br />
				<hr />

				<div className="flex flex-col-reverse pt-10 sm:px-10 sm:pb-5 sm:flex-row">
					<div className="text-center sm:text-right sm:ml-auto">
						<div className="text-base text-slate-500">Food Cost</div>
						<div className="mt-2 text-xl font-medium text-primary">
							<InputGroup>
								<InputGroup.Text>Amount</InputGroup.Text>
								<FormInput type="number" placeholder="Food Cost" value={events ? events.foodCost : 0} />
								{/*onChange={(e) => {*/}
								{/*	events.foodCost = Number(e.target.value)*/}
								{/*	setEvents({ ...events })*/}
								{/*}} */}
							</InputGroup>
						</div>
						<div className="mt-1">In rupees ₹</div>
					</div>
				</div>
				<div className="overflow-x-auto mt-3">
					<Table>
						<Table.Thead>
							<Table.Tr>
								<Table.Th className="border-b-2 dark:border-darkmode-400 whitespace-nowrap">Additional Charges</Table.Th>
								<Table.Th className="border-b-2 dark:border-darkmode-400 whitespace-nowrap">Amount</Table.Th>
							</Table.Tr>
						</Table.Thead>
						<Table.Tbody>
							{events && events.charges.map((x, i) => (
								<Table.Tr key={i}>
									<Table.Td className="border-b dark:border-darkmode-400">
										<div className="font-medium whitespace-nowrap">{(i + 1) + '. ' + x.title}</div>
										<div className="text-slate-500 text-sm mt-0.5 whitespace-nowrap">{x.description}</div>
									</Table.Td>
									<Table.Td className="w-32 font-medium text-right border-b dark:border-darkmode-400">
										<div className='flex'>
											<FormInput type="number" placeholder="Amount" value={x.price}
												onChange={(e) => {
													x.price = Number(e.target.value)
													setEvents({ ...events })
												}} />
											<Button variant='soft-danger' rounded className='mx-2'
												onClick={() => {
													events.charges.splice(i, 1)
													setEvents({ ...events })
												}}><Lucide icon='Trash2' /></Button>
										</div>
									</Table.Td>
								</Table.Tr>
							))}
						</Table.Tbody>
					</Table>
					<div className='flex'>
						<Button variant='soft-primary' className='mx-auto' onClick={() => { setnewEventModalPreview(true) }} >+ Add More Additional Charges</Button>
					</div>
				</div>
			</div>
			<div className="flex flex-col-reverse pt-10 sm:px-10 sm:pb-5 sm:flex-row">
				<div className="text-center sm:text-right sm:ml-auto">
					<div className="text-base text-slate-500">Discount Amount</div>
					<div className="mt-2 text-xl font-medium text-primary">
						<InputGroup>
							<InputGroup.Text>Discount</InputGroup.Text>
							<FormInput type="number" placeholder="Discount" value={events ? events.discount : 0}
								onChange={(e) => {
									events.discount = Number(e.target.value)
									setEvents({ ...events })
								}} />
						</InputGroup>
					</div>
					<div className="mt-1">In rupees ₹</div>
				</div>
			</div>
			<div className="flex flex-col-reverse px-5 pb-10 sm:px-20 sm:pb-20 sm:flex-row">
				{/*<div className="mt-10 text-center sm:text-left sm:mt-0">*/}
				{/*	<div className="text-base text-slate-500">Quotation From</div>*/}
				{/*	<div className="mt-2 text-lg font-medium text-primary"></div>*/}
				{/*	<div className="mt-1"><b>Name: </b>Full Name</div>*/}
				{/*	<div className="mt-1"><b>Mobile: </b>0123456789</div>*/}
				{/*</div>*/}
				<div className="text-center sm:text-right sm:ml-auto">
					<div className="text-base text-slate-500">Total Amount</div>
					<div className="mt-2 text-xl font-medium text-primary">₹{totalAmt()}</div>
					<div className="mt-1"></div>
				</div>
			</div>
			<div className='flex'>
				{/*<Button onClick={showQuotation()} className='my-5 mx-auto'><Lucide icon="File" className="w-4 h-4 mr-2" /> Export PDF Quotation</Button>*/}
			</div>
		</div>
		<Dialog open={newEventModalPreview} onClose={() => { setnewEventModalPreview(false) }}>
			<Dialog.Panel>
				<Dialog.Title>
					<h2 className="mr-auto text-base font-medium">Add Additional Charges</h2>
					<Button className="w-24 ml-auto" onClick={() => { setnewEventModalPreview(false) }}>
						<Lucide icon='XCircle' />
					</Button>
				</Dialog.Title>
				<div className="mx-5 my-3">
					<label className='font-medium'>Title:</label>
					<FormInput placeholder='Title' className='mb-5' onChange={(e) => setTitle(e.target.value)} value={title} />
					<label className='font-medium'>Description: </label>
					<FormTextarea placeholder='Description' className='mb-5' onChange={(e) => setDescription(e.target.value)} value={description} />
					<label className='font-medium'>Amount:</label>
					<FormInput type='number' placeholder='Amount' className='mb-5' onChange={(e) => setPrice(Number(e.target.value))} value={price} />
				</div>
				<Dialog.Footer className='mt-10'>
					<Button type="button" variant="primary" className="w-24 mr-2"
						onClick={() => {
							events.charges.push({ title, description, price })
							setnewEventModalPreview(false)
						}}>Add</Button>
				</Dialog.Footer>
			</Dialog.Panel>
		</Dialog>
		<Dialog open={sendWAModalOpen} onClose={() => { setSendWAModalOpen(false) }}>
			<Dialog.Panel>
				<Dialog.Title>
					<h2 className="mr-auto text-base font-medium">Share Quotation on WhatsApp</h2>
					<Button className="w-24 ml-auto" onClick={() => { setSendWAModalOpen(false) }}><Lucide icon='XCircle' /></Button>
				</Dialog.Title>
				<div className="mx-5 my-3">
					<label className='font-medium'>WhatsApp Mobile Number:</label>
					<FormInput placeholder='WhatsApp Mobile Number' className='mb-5' onChange={(e) => setWaNumber(e.target.value)} value={waNumber} maxLength={10} />
				</div>
				<Dialog.Footer className='mt-1'>
					<Button type="button" variant="primary" className=" mr-2"
						onClick={() => {
							sendWAQuotation()
							setSendWAModalOpen(false)
						}}><Lucide icon="Send" className="mr-2" /> Send Quotation on WhatsApp</Button>
				</Dialog.Footer>
			</Dialog.Panel>
		</Dialog>
	</>

	//async function showQuotationDemo() {
	//	try {
	//		showLoader()
	//		const f = await fetch('/api/quotation_demo', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(''), })
	//		if (f.ok) {
	//			const x = await f.json()
	//			if (x.e) console.error(x)
	//			else { window.open(x.path) }
	//		} else console.error(f.status, f.statusText)
	//	} catch (e) { console.error(e) } finally { hideLoader() }
	//}
}