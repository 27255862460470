import { useEffect, useRef, useState } from "react";
import { FormSelect } from "../../../../base-components/Form";
import FormInput from "../../../../base-components/Form/FormInput";
import Table from "../../../../base-components/Table";
import Button from "../../../../base-components/Button";
import Lucide from "../../../../base-components/Lucide";
import Dialog from "../../../../base-components/Headless/Dialog";
import { useLoader } from "../../../../base-components/Loader";

export default function Main(props: { eventId: string; }) {
	const { showLoader, hideLoader } = useLoader();

	const updateButtonRef = useRef();
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [successModalPreview, setSuccessModalPreview] = useState(false);
	const [modal, setModal] = useState({ msg: '', title: '', type: '' });

	const [raw, setRaw] = useState([]);
	const [vendors, setVendors] = useState([]);

	useEffect(() => { initRawReturn() }, [])

	return (
		<>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
				<h2 className="mr-auto text-lg font-medium">Raw Material Return</h2>
			</div>
			<div className="grid grid-cols-12 gap-2 mt-2">
				<div className="col-span-12 intro-y lg:col-span-12">
					<div className="intro-y box h-full mt-2">
						<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
							<h2 className="mr-auto text-base font-medium">Raw Material Return</h2>
							<div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
							</div>
						</div>
						<div className="p-5">
							<Table className="border rounded">
								<Table.Thead>
									<Table.Tr>
										<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap">Name</Table.Th>
										<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap">Quantity</Table.Th>
										<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap">Type</Table.Th>
										<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap">Vendor</Table.Th>
									</Table.Tr>
								</Table.Thead>
								<Table.Tbody>
									{raw.map((x, i) => (
										<Table.Tr key={i} >
											<Table.Td className="border-r text-center">{x.name}</Table.Td>
											<Table.Td className="flex border-r text-center">
												<FormInput type='number' placeholder='Quantity' className='!w-full' value={x.qty}
													onChange={(e) => {
														x.qty = e.target.value
														setRaw([...raw])
													}} />
												<b className='mt-2 ml-2'>{x.unit}</b>
											</Table.Td>
											<Table.Td className="border-r text-center">
												<FormSelect value={x.type} className='!w-full'
													onChange={(e) => {
														x.type = Number(e.target.value)
														setRaw([...raw])
													}}>
													<option value='1'>Self/Own/Store</option>
													<option value='2'>Vendor/Sell</option>
												</FormSelect>
											</Table.Td>
											<Table.Td className="border-r text-center">
												<FormSelect className='!w-full' value={x.vendor} onChange={(e) => {
													x.vendor = Number(e.target.value)
													setRaw([...raw])
												}}>{vendors && vendors.map(x => <option key={x.id} value={x.id} >{x.name}</option>)}
												</FormSelect>
												{/*<FormInput className='w-full' placeholder='Vendor Name' value={x.vendor}*/}
												{/*	onChange={(e) => {*/}
												{/*		x.vendor = Number(e.target.value)*/}
												{/*		setRaw([...raw])*/}
												{/*	}} />*/}
											</Table.Td>
										</Table.Tr>
									))}
								</Table.Tbody>
							</Table>
							{raw && <div className="flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0">
								<Button variant="soft-primary" className="mx-auto my-3"
									onClick={() => { setConfirmationModal(true) }} >
									<Lucide icon='Save' className='h-4' /> Save
								</Button>
							</div>}
						</div>
					</div>
				</div>
			</div>
			<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${modal.type}`} />
						<div className="mt-5 text-3xl">{modal.title}</div>
						<div className="mt-2 text-slate-500">{modal.msg}</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24" > Ok </Button>
					</div>
				</Dialog.Panel>
			</Dialog>
			<Dialog open={confirmationModal} initialFocus={updateButtonRef} onClose={() => { setConfirmationModal(false); }}>
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="Edit" className="w-16 h-16 mx-auto mt-3 text-danger" />
						<div className="mt-5 text-3xl">Are you sure?</div>
						<div className="mt-2 text-slate-500">Save People Details<br />This process cannot be undone.</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button variant="outline-secondary" type="button" onClick={() => { setConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
						<Button variant="success" type="button" className="text-white w-24" ref={updateButtonRef}
							onClick={() => { saveRawReturn(raw.map(({ name, unit, ...rest }) => rest)) }} >Save</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
		</>
	);

	async function initRawReturn() {
		try {
			showLoader()
			const f = await fetch('/api/ae_raw/' + props.eventId)
			if (f.ok) {
				const x = await f.json()
				if (x.e) console.error(x)
				else {
					setVendors(x.d.vendors)
					setRaw(x.d.raw)
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	async function saveRawReturn(data: any[]) {
		try {
			setConfirmationModal(false)
			showLoader()
			const f = await fetch('/api/ae_raw/' + props.eventId, { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data), })
			if (f.ok) {
				const x = await f.json()
				if (x.e) setModal({ msg: x.d, title: 'Failed', type: 'error' })
				else setModal({ msg: x.d, title: 'Success', type: 'success' })
				setSuccessModalPreview(true)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
}