/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useEffect } from "react";
import FormInline from "../../../../base-components/Form/FormInline";
import FormInput from "../../../../base-components/Form/FormInput";
import FormLabel from "../../../../base-components/Form/FormLabel";
import FormSelect from "../../../../base-components/Form/FormSelect";
import InputGroup from "../../../../base-components/Form/InputGroup";
import Lucide from "../../../../base-components/Lucide";
import Table from "../../../../base-components/Table";

export default function RawMaterialDay({ rawMaterialData, processes, refreshData, rmdIndex }: { rawMaterialData: RawMaterialData; processes: any; rmdIndex: number; refreshData: () => void; }) {
	const toggleDishDetails = (index: string) => {
		const v = document.querySelector('.dish' + index)
		v.classList.contains('hidden') ? v.classList.remove('hidden') : v.classList.add('hidden')
	}

	const toggleDay = (index: string) => {
		const v = document.querySelector('.rmd' + index)
		v.classList.contains('hidden') ? v.classList.remove('hidden') : v.classList.add('hidden')
	}

	useEffect(() => {
		const v = document.querySelectorAll('td.dish>div:last-child')
		v.forEach(d => { d.classList.add('hidden') })
	}, [])

	return rawMaterialData == null || rawMaterialData == undefined ?
		<>{alert('data is empty: ' + JSON.stringify(rawMaterialData))}</> : <>
			<div className="p-2 mt-2 intro-y ">
				<div className="p-5 border rounded-md border-slate-20/60 dark:border-darkmode-40">
					<div onClick={() => toggleDay(rmdIndex + '')} className="flex items-center pb-5 text-base font-medium border-b border-slate-200/60 dark:border-darkmode-400">
						<Lucide icon="ChevronDown" className="hover:animate-ping w-4 h-4 mr-2" /> {rmdIndex + 1 + '. ' + rawMaterialData.name}
					</div>
					{/*animate-bounce animate-ping animate-spin animate-pulse*/}
					<div className={'rmd' + rmdIndex}>
						{rawMaterialData.subevents.map((subevent: SubEvents, index: number) => (
							<div className="mt-5" key={index}>
								<FormInline className="flex-col items-start pt-5 mt-5 xl:flex-row first:mt-0 first:pt-0">
									<FormLabel className="xl:w-64 xl:!mr-10">
										<div className="text-left">
											<div className="flex items-center"><div className="font-medium">{subevent.name}</div></div>
											<div className="mt-3 text-xs leading-relaxed text-slate-500">
												Apply price and stock on all variants or based on
												certain variant codes.
											</div>
										</div>
									</FormLabel>
								</FormInline>
								<div className="overflow-x-auto">
									<div className='flex flex-row-reverse'>
										<FormInline>
											<InputGroup>
												<InputGroup.Text>People</InputGroup.Text>
												<FormInput type="number" placeholder="People" value={subevent.people}
													onChange={(e) => {
														subevent.people = Number(e.target.value)
														refreshData()
													}} />
											</InputGroup>
											<InputGroup>
												<InputGroup.Text>Preparation</InputGroup.Text>
												<FormInput type="number" placeholder="Prepare" value={subevent.prepare}
													onChange={(e) => {
														subevent.prepare = Number(e.target.value)
														refreshData()
													}} />
											</InputGroup>
										</FormInline>
									</div>
									<Table className="border rounded">
										<Table.Thead>
											<Table.Tr>
												<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap"></Table.Th>
												<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap">Category</Table.Th>
												<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap !px-2">Dish</Table.Th>
											</Table.Tr>
										</Table.Thead>
										<Table.Tbody>
											{subevent.category.map((cat: Category, i: number) => (
												<Fragment key={i}>
													<Table.Tr key={i} >
														<Table.Td rowSpan={cat.list.length + 1} className="border-r text-center">{i + 1}</Table.Td>
														<Table.Td rowSpan={cat.list.length + 1} className="border-r">
															<h3 className="text-center text-xl font-medium leading-none">{cat.name}</h3>
														</Table.Td>
													</Table.Tr>
													{cat.list.map((sel: SubEventList, i1: number) =>
														<Table.Tr key={i1} >
															<Table.Td className="!px-2 dish">
																<div className="flex align-center gap-2">
																	<b className='w-full flex items-center p-2 hover:animate-pulse hover:bg-secondary hover:rounded' onClick={() => toggleDishDetails(i + '' + sel.id)}>{i1 + 1}. {sel.name}</b>
																	<FormInput type="text" placeholder='Maharaj Name' className="w-250" value={sel.maharaj}
																		onChange={(e) => {
																			sel.maharaj = (e.target.value)
																			refreshData()
																		}} />
																	<InputGroup className="flex-1 ml-auto mr-5">
																		<FormInput type="number" className="min-w-[6rem]" value={sel.qty}
																			onChange={(e) => {
																				sel.qty = Number(e.target.value)
																				refreshData()
																			}} />
																		<InputGroup.Text>{sel.unit}</InputGroup.Text>
																	</InputGroup>
																</div>
																<div className={`contents dish${i}${sel.id}`}>
																	<Table className="border">
																		<Table.Thead>
																			<Table.Tr>
																				<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap">Content</Table.Th>
																				<Table.Th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap !px-2">Qty</Table.Th>
																			</Table.Tr>
																		</Table.Thead>
																		<Table.Tbody>
																			{sel.content.map((content: Contents, ci: number) => (
																				<Table.Tr key={ci}>
																					<Table.Td className="border-r">{content.name}</Table.Td>
																					<Table.Td className="border-r flex flex-row-reverse">
																						<div className="flex align-center gap-2">
																							<FormSelect name='process' aria-label="Process" value={content.process}
																								onChange={(e) => {
																									content.process = Number(e.target.value)
																									refreshData()
																								}}>
																								<option value='0'>Process</option>
																								{processes.map((x, i) => <option key={i} value={x}>{x}</option>)}
																							</FormSelect>
																							<InputGroup className="flex-1 ml-auto">
																								<FormInput type="number" className="min-w-[6rem]" value={content.qty}
																									onChange={(e) => {
																										content.qty = Number(e.target.value)
																										refreshData()
																									}} />
																								<InputGroup.Text>{content.unit}</InputGroup.Text>
																							</InputGroup>
																						</div>
																					</Table.Td>
																				</Table.Tr>
																			))}
																		</Table.Tbody>
																	</Table>
																</div>
															</Table.Td>
														</Table.Tr>)}
												</Fragment>)
											)}
										</Table.Tbody>
									</Table>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>;
}
