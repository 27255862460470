import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import * as xlsx from "xlsx";
import '../../../assets/css/tabulator-edit.css';
import Button from "../../../base-components/Button";
import { FormInput, FormLabel } from "../../../base-components/Form";
import { Dialog, Menu } from "../../../base-components/Headless";
import { useLoader } from "../../../base-components/Loader";
import Lucide from "../../../base-components/Lucide";

export default function Main() {
	const { showLoader, hideLoader } = useLoader();

	const tableRef = createRef<HTMLDivElement>();
	const tabulator = useRef<Tabulator>();

	const sendButtonRef = useRef();

	const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false);
	const [successModalPreview, setSuccessModalPreview] = useState(false);

	const [modal, setModal] = useState({ msg: '', title: '', type: '' });

	const [totPages, setTotPages] = useState(0)
	const [category, setCategory] = useState('')

	var deleteIcon = function () { return '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>'; };

	//const editedCallback = (cell: CellComponent) => {
	//	const d = cell.getData() as any
	//	const name = d.name
	//}

	useEffect(() => {//setSuccessModalPreview(true)
		initTabulator();
		reInitOnResizeWindow();
	}, []);

	const initTabulator = () => {
		if (tableRef.current) {
			tabulator.current = new Tabulator(tableRef.current, {//groupBy: "type",//data: dataList,
				ajaxURL: "/api/utensils_category", paginationMode: "remote", filterMode: "remote", sortMode: "remote",
				movableColumns: true, ajaxResponse: (_url, p, res) => {
					setTotPages(res.last_page)
					const page = p.page, size = p.size
					const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));
					res.data = processedData
					return res
				},
				pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
				reactiveData: true, height: 'auto', layout: "fitColumns", placeholder: "No matching records found",
				columns: [
					{ title: "Serial Number", field: "srno", print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
					{ title: "Name", field: "name", headerFilter: "input", headerFilterPlaceholder: "Search Category Name", print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", },
					{ title: "Type", field: "type", print: true, hozAlign: "center", headerHozAlign: 'center', visible: false },
					{
						title: "Actions", print: false, formatter: deleteIcon, headerSort: false, headerHozAlign: "center", width: 100, hozAlign: "center",
						//cellClick: function (_e, row) {const id = (row.getData() as any).id}
					}
				],
			});
		}

		tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
	};
	const reInitOnResizeWindow = () => {
		window.addEventListener("resize", () => {
			if (tabulator.current) {
				tabulator.current.redraw();
				createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
			}
		});
	};

	const onPrint = () => { if (tabulator.current) tabulator.current.print(); };
	const onExportCsv = () => { if (tabulator.current) tabulator.current.download("csv", "data.csv"); };
	const onExportJson = () => { if (tabulator.current) tabulator.current.download("json", "data.json"); };
	const onExportHtml = () => { if (tabulator.current) tabulator.current.download("html", "data.html", { style: true, }); };

	const onExportXlsx = () => {
		if (tabulator.current) {
			(window as any).XLSX = xlsx;
			tabulator.current.download("xlsx", "data.xlsx", { sheetName: "Products", });
		}
	};

	return (
		<>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row"><h2 className="mr-auto text-lg font-medium">Utensils Category</h2></div>
			<div className="p-5 mt-5 intro-y box">
				<div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
					<div className="xl:flex sm:mr-auto">
						<Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto" variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" />Print</Button>
						<Menu className="w-1/2 sm:w-auto">
							<Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">
								<Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export
								<Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />
							</Menu.Button>
							<Menu.Items className="w-40">
								<Menu.Item onClick={onExportCsv}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export CSV</Menu.Item>
								<Menu.Item onClick={onExportJson}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export JSON</Menu.Item>
								<Menu.Item onClick={onExportXlsx}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export XLSX</Menu.Item>
								<Menu.Item onClick={onExportHtml}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export HTML</Menu.Item>
							</Menu.Items>
						</Menu>
					</div>
					<div className="flex items-center mt-5 sm:mt-0">
						<Button variant="primary" className="mx-2" onClick={(event: React.MouseEvent) => {
							event.preventDefault();
							setHeaderFooterModalPreview(true);
						}} >Add New Category</Button>
					</div>
				</div>
				<div className="overflow-x-auto scrollbar-hidden">
					<div id="tabulator" ref={tableRef} className="mt-5"></div>
				</div>
				<div className='flex w-full'>
					<span className='ml-auto mr-10'><b>Total Pages: </b>{totPages}</span>
				</div>
			</div>
			<Dialog open={headerFooterModalPreview} onClose={() => { setHeaderFooterModalPreview(false); }} initialFocus={sendButtonRef}>
				<Dialog.Panel>
					<Dialog.Title>
						<h2 className="mr-auto text-base font-medium">Add Utensils Category</h2>
					</Dialog.Title>
					<Dialog.Description className="grid grid-cols-12 gap-4 gap-y-3">
						<div className="col-span-12 sm:col-span-12">
							<FormLabel htmlFor="modal-form-1">Utensils Category Name</FormLabel>
							<FormInput id="modal-form-1" type="text" placeholder="Category Name" onChange={(e) => setCategory(e.target.value)} />
						</div>
					</Dialog.Description>
					<Dialog.Footer>
						<Button type="button" variant="outline-secondary" onClick={() => { setHeaderFooterModalPreview(false); }} className="w-20 mr-1">Cancel</Button>
						<Button variant="primary" type="button" ref={sendButtonRef} onClick={saveCategory}>Add Utensils Category</Button>
					</Dialog.Footer>
				</Dialog.Panel>
			</Dialog>
			<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }} >
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="CheckCircle" className={`w-16 h-16 mx-auto mt-3 text-${modal.type}`} />
						<div className="mt-5 text-3xl">{modal.title}</div>
						<div className="mt-2 text-slate-500">{modal.msg}</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24" > Ok </Button>
					</div>
				</Dialog.Panel>
			</Dialog>
		</>
	);
	async function saveCategory() {
		try {
			showLoader()
			const f = await fetch('/api/add_utensils_category', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ name: category }), })
			if (f.ok) {
				const r = await f.json()
				if (r.e) setModal({ type: 'error', title: r.d, msg: 'Save Filed' })
				else setModal({ type: 'success', title: r.d, msg: 'Save Successful' })
				setHeaderFooterModalPreview(false)
				setSuccessModalPreview(true)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
}