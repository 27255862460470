import { ChevronsDown } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../base-components/Button';
import { FormInline, FormInput, InputGroup } from '../../../base-components/Form';
import Litepicker from '../../../base-components/Litepicker';
import Lucide from '../../../base-components/Lucide';
import TomSelect from '../../../base-components/TomSelect';
import { RootState } from '../../../stores/store';
import { useLoader } from '../../../base-components/Loader';

function Main() {
	const { showLoader, hideLoader } = useLoader()

	const navigate1 = useNavigate();
	const eventId = useSelector((state: RootState) => state.eventId.eventId);
	const dateRange1 = useSelector((state: RootState) => state.dateRange.dateRange ?? "");
	const [daterange, setDaterange] = useState(dateRange1);

	const [dishCategory, setDishCategory] = useState<any[]>([]);
	const [dishes, setDishes] = useState([]);

	const [divs, setDivs] = useState<Div[]>([{ id: 1, content: '', subevent: '', date: '', time: '', people: 0, dishes: [], manpower: 0, waitersMale: 0, waitersFemale: 0, washers: 0, cooks: 0, helpers: 0, tags: [[]], },]);

	const [expandedIndex, setExpandedIndex] = useState<number | null>(0);

	const handleToggle = (index: number) => {
		setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	const handleRemoveDiv = (id: number) => {
		setDivs((prevDivs) => prevDivs.filter((div) => div.id !== id));
		setExpandedIndex(null);
	};

	const handleAddDiv = () => {
		setDivs((prevDivs: any) => {
			const lastDiv = prevDivs.length > 0 ? prevDivs[prevDivs.length - 1] : divs;
			const newId = lastDiv.id + 1;
			const newDiv = {
				id: newId + 1,
				content: "",
				subevent: '',
				date: '',
				time: '',
				people: 0,
				dishes: [],
				manpower: lastDiv.manpower,  // Retain the value from the last div
				waitersMale: lastDiv.waitersMale,  // Retain the value from the last div
				waitersFemale: lastDiv.waitersFemale,  // Retain the value from the last div
				washers: lastDiv.washers,  // Retain the value from the last div
				cooks: lastDiv.cooks,  // Retain the value from the last div
				helpers: lastDiv.helpers,  // Retain the value from the last div
				tags: [[]],
			};
			const newDivs = [...prevDivs, newDiv];
			// Set the expandedIndex to the index of the newly added div
			setExpandedIndex(newDivs.length - 1);
			return newDivs;
		});
	};

	const handleInputChange = (index: number, field: keyof Div, value: string | number | string[]) => {
		setDivs((prevDivs) =>
			prevDivs.map((div, i) => {
				if (i === index) return { ...div, [field]: value }; else return div;
			})
		);
	};

	const handleDateChange = (selectedDate: string) => {
		setDaterange(selectedDate);
	};

	const fetchDishCategories = async () => {
		try {
			showLoader()
			const f = await fetch('/api/dish_category')
			if (f.ok) {
				const d = await f.json()
				setDishCategory(d)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	const fetchDishes = async () => {
		try {
			showLoader()
			const f = await fetch('/api/dishes')
			if (f.ok) {
				const d = await f.json()
				setDishes(d)
			} else console.error(f.status, f.statusText)
		}
		catch (error) { console.error('Error fetching dishes:', error); } finally { hideLoader() }
	};
	const fetchEventMenus = async () => {
		try {
			showLoader()
			const f = await fetch(`/api/event_menu?id=${eventId}`)
			if (f.ok) {
				const data = await f.json()
				const eventMenusArray = Array.isArray(data) ? data : [data]
				const transformedDivs = eventMenusArray.map((menu) => ({
					id: menu.id,
					content: menu.content,
					subevent: menu.subevent,
					date: menu.date,
					time: menu.time,
					people: menu.people,
					dishes: menu.dishes,
					manpower: menu.manpower,
					waitersMale: menu.waitersMale,
					waitersFemale: menu.waitersFemale,
					washers: menu.washers,
					cooks: menu.cooks,
					helpers: menu.helpers,
					tags: menu.tags//menu.tags.map((tag: any) => [tag.categoryname, tag.dishname]),
				}))
				if (transformedDivs.length > 0) setDivs(transformedDivs)
			} else console.error(f.status, f.statusText)
		} catch (error) { console.error('Error fetching event menus:', error) } finally { hideLoader() }
	}

	useEffect(() => {
		if (eventId === null || eventId === undefined) navigate1('/admin');
		else {
			fetchDishCategories();

			fetchDishes();

			fetchEventMenus();
		}
	}, [eventId]);

	async function SaveToDatabase() {
		try {
			showLoader()
			const menusToSave: ScheduledMenuDto[] = divs.map((div) => ({
				schedularId: eventId,
				menuName: div.subevent,
				people: div.people,
				date: div.date ? new Date(div.date) : null, // Check if date is provided
				time: div.time || '', // Set to an empty string if div.time is not provided
				manpower: div.manpower,
				waitersMale: div.waitersMale,
				waitersFemale: div.waitersFemale,
				washers: div.washers,
				cooks: div.cooks,
				helpers: div.helpers,
				dishes: Object.values(div.tags).flatMap((dishArray) => dishArray)//div.tags.flat(),
			})) as any

			const f = await fetch('/api/add_menus', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(menusToSave), })
			if (f.ok) {
				const r = await f.json()
				if (r.e) { console.error('Error:', r) }
				else { navigate1('/admin/raw-material-list') }
			} else console.error(f.status, f.statusText)
		} catch (error) { console.error('Error:', error); } finally { hideLoader() }
	}

	return (
		<>
			<div className="flex items-center mt-8 intro-y">
				<h2 className="mr-4 font-medium">Event No:{eventId}</h2>
				<div className="p-4">
					<Litepicker className="block w-full mx-auto" value={daterange} disabled
						onChange={handleDateChange}
						options={{
							numberOfColumns: 2, numberOfMonths: 2,
							autoApply: false, singleMode: false, showWeekNumbers: true,
							dropdowns: { minYear: 1990, maxYear: null, months: true, years: true, },
						}} />
				</div>
			</div>

			<div className="p-2 bg-gray-100">
				{divs.map((div, index) => (
					<div key={index} className={`border border-solid border-gray-500 rounded-md p-4 mb-8 transition-all duration-500 ease-in-out bg-white`}>
						<div className="flex mb-2">
							<div className="text-lg cursor-pointer flex items-center w-full">
								<div className='bg-gray-100 rounded-full mr-2 p-5' onClick={() => handleToggle(index)} >
									<ChevronsDown className={"w-8 h-8 " + (expandedIndex === index ? "text-red-500" : "text-green-500")} />
								</div>
								<FormInline className="block text-sm w-5/6">
									<InputGroup.Text><b>{index + 1}. </b>Menu Name</InputGroup.Text>
									<FormInput type="text" value={div.subevent} className='w-svw'
										onChange={e => handleInputChange(index, 'subevent', e.target.value)} />
								</FormInline>
							</div>
							<div className='absolute right-20'>
								<Button variant='soft-danger' className=" text-red-500 hover:text-red-700 text-center" onClick={() => handleRemoveDiv(div.id)} >
									<Lucide icon='Trash2' />
								</Button>
							</div>
						</div>
						{expandedIndex === index &&
							<div className="overflow-x-auto scrollbar-hidden">
								<div className="text-gray-700">
									<div className="flex items-center text-sm mt-5"  >
										<div className='flex flex-wrap gap-2 mx-auto'>
											<InputGroup>
												<InputGroup.Text className='font-medium'>People:</InputGroup.Text>
												<FormInput type="number" value={div.people}
													onChange={e => handleInputChange(index, 'people', e.target.value)} />
											</InputGroup>
											<InputGroup>
												<InputGroup.Text className='font-medium'>Date:</InputGroup.Text>
												<Litepicker value={div.date}
													options={{
														autoApply: false, showWeekNumbers: true,
														dropdowns: { minYear: 1990, maxYear: null, months: true, years: true, },
													}}
													onChange={e => handleInputChange(index, 'date', e)} />
											</InputGroup>{/*setDate(e);*/}
											<InputGroup>
												<InputGroup.Text className='font-medium'>Time</InputGroup.Text>
												<FormInput type="time" value={div.time} onChange={e => handleInputChange(index, 'time', e.target.value)} />
											</InputGroup>
										</div>
									</div>
									<div className="flex items-center mb-2 m-8 ">
										<table className="w-full bg-gray-600 text-white border">
											<thead>
												<tr>
													<th className="py-2 px-4 border-b">Dish Category</th>
													<th className="py-2 px-4 border-b">Dishes</th>
												</tr>
											</thead>
											<tbody>
												{dishCategory.map((category, categoryIndex) => (
													<tr className="bg-white text-black" key={categoryIndex}>
														<td className="py-2 px-4 border-b">{category.text}</td>
														<td className="py-2 px-4 border-b">
															{<>{/*value={['Dish3', 'Dish1', 'Dish4']}*/}
																<TomSelect id={`dish-select-${category.text}-${index}`} className="w-full" multiple
																	value={Array.isArray(div.tags[category.text]) ? div.tags[category.text] : []}
																	onChange={(newTags: string[]) => {
																		setDivs((prevDivs) => {
																			const newDivs = [...prevDivs];
																			newDivs[index].tags[category.text] = newTags;
																			return newDivs;
																		});
																		handleInputChange(index, 'dishes', newTags);
																	}}>
																	{dishes.filter((dish) => dish.category === category.text).map((dish, i) => (<option key={i} value={dish.text}>{dish.text}</option>))}
																</TomSelect>
															</>
															}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
									<div className="grid grid-cols-12 gap-4 gap-y-3 mx-10">
										<div className="lg:col-span-4 sm:col-span-12 md:col-span-12">
											<InputGroup>
												<InputGroup.Text className='font-medium w-full'>ManPower:</InputGroup.Text>
												<FormInput type="number" value={div.manpower}
													onChange={e => handleInputChange(index, 'manpower', e.target.value)} />
											</InputGroup>
										</div>
										<div className="col-span-4">
											<InputGroup>
												<InputGroup.Text className='font-medium w-full'>Waiters(Male):</InputGroup.Text>
												<FormInput type="number" value={div.waitersMale}
													onChange={e => handleInputChange(index, 'waitersMale', e.target.value)} />
											</InputGroup>
										</div>
										<div className="col-span-4">
											<InputGroup>
												<InputGroup.Text className='font-medium w-full'>Waiters(Female):</InputGroup.Text>
												<FormInput type="number" value={div.waitersFemale}
													onChange={e => handleInputChange(index, 'waitersFemale', e.target.value)} />
											</InputGroup>
										</div>
										<div className="col-span-4">
											<InputGroup>
												<InputGroup.Text className='font-medium w-full'>Washers:</InputGroup.Text>
												<FormInput type="number" value={div.washers}
													onChange={e => handleInputChange(index, 'washers', e.target.value)} />
											</InputGroup>
										</div>
										<div className="col-span-4">
											<InputGroup>
												<InputGroup.Text className='font-medium w-full'>Cooks:</InputGroup.Text>
												<FormInput type="number" value={div.cooks}
													onChange={e => handleInputChange(index, 'cooks', e.target.value)} />
											</InputGroup>
										</div>
										<div className="col-span-4">
											<InputGroup>
												<InputGroup.Text className='font-medium w-full'>Helpers:</InputGroup.Text>
												<FormInput type="number" value={div.helpers}
													onChange={e => handleInputChange(index, 'helpers', e.target.value)} />
											</InputGroup>
										</div>
									</div>
								</div>
							</div>
						}
					</div>
				))}
				<div className='flex'>
					<Button variant='soft-primary' onClick={handleAddDiv}>
						<Lucide icon='Plus' className='mr-auto' />Add New Menu</Button>
					<div className="ml-auto text-lg font-semibold cursor-pointer items-center">
						<Button variant='soft-success' onClick={SaveToDatabase}>
							<Lucide icon='Save' className='mr-2' />Save & Proceed
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}

export default Main;