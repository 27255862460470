import React, { Fragment } from 'react';
import ReadOnlyRow from './ReadOnlyRow';
import EditRow from './EditRow';
import FormInput from '../../../../base-components/Form/FormInput';
import Form from './Form';

interface TableProps {
	dmd: DishMasterData;
	dm: DishMaster;
	unitProcessList: any;
	dmdList: DishMasterData[];
	editId?: number | null;
	handleEditClick: (e: any, dmd: DishMasterData) => void;
	handleEditForm: (e: any) => void;
	editFormData: DishMasterData;
	handleEditFormSubmit: (e: any) => void;
	handleDeleteClick: (id: number) => void;
	handleAddForm: (e: any) => void;
	handleSubmitForm: () => void;
	handleLoadForm: (e: any) => void;
	handleClearForm: () => void;
}

const Table: React.FC<TableProps> = ({ dmd, dm, unitProcessList, dmdList, editId, handleEditClick, handleEditForm, editFormData, handleEditFormSubmit, handleDeleteClick, handleSubmitForm, handleAddForm, handleLoadForm, handleClearForm }) => {
	//(dmds.length == 0) ? <></> :
	return (<div className="overflow-x-auto">
		<table className="w-full text-left">
			<thead style={{ background: '#f0eeee', textAlign: 'center' }}>
				<tr>
					<th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">Content</th>
					<th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">
						<div>People</div>
						<div className="flex align-center justify-center gap-2">
							{dm.people.map((number: number, index: number) => (
								<FormInput type="number" key={index} onKeyDown={(e) => ["ArrowUp", "ArrowDown", "e", "E"].includes(e.key) && e.preventDefault()}
									min={0} name={"people" + index} onChange={handleLoadForm} value={number} placeholder={"People" + index} required />)
							)}
						</div>
						<div>Kg</div>
						<div className="flex align-center justify-center gap-2">
							{dm.kg.map((number: number, index: number) => (
								<FormInput type="number" key={index} onKeyDown={(e) => ["ArrowUp", "ArrowDown", "e", "E"].includes(e.key) && e.preventDefault()}
									min={0} name={"kg" + index} onChange={handleLoadForm} value={number} placeholder={"KG" + index} required />)
							)}
						</div>
						<div>Price</div>
						<div className="flex align-center justify-center gap-2">
							{dm.price.map((number: number, index: number) => (
								<FormInput type="number" key={index} onKeyDown={(e) => ["ArrowUp", "ArrowDown", "e", "E"].includes(e.key) && e.preventDefault()}
									min={0} name={"price" + index} onChange={handleLoadForm} value={number} placeholder={"Price" + index} required />
							))}
						</div>
					</th>
					<th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">Unit</th>
					<th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">Process</th>
					<th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">Actions</th>
				</tr>
			</thead>
			<tbody>
				{dmdList.length == 0 ? <tr><td colSpan={5}><h1 className='text-center mt-3 text-xl font-medium leading-none'>Not added yet add from option below</h1></td></tr> :
					dmdList.map((d: DishMasterData) => (
						<Fragment key={d.id}>
							{editId === d.id ? (/*handleCancelClick={handleCancelClick}*/
								<EditRow handleEditFormSubmit={handleEditFormSubmit} editFormData={editFormData}
									handleEditForm={handleEditForm} unitProcessList={unitProcessList} />
							) : (<ReadOnlyRow dmd={d} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} unitProcessList={unitProcessList} />)}
						</Fragment>
					))}
			</tbody>
			<tfoot style={{ background: '#eee' }} >
				<tr>
					<td colSpan={5}>
						<h1 className='text-center mt-3 text-xl font-medium leading-none'>Add New  Row To above</h1>
					</td>
				</tr>
				<tr>{/*<td colSpan={5} ></td>*/}
					<Form dmd={dmd} handleClearForm={handleClearForm}
						handleAddForm={handleAddForm} handleSubmitForm={handleSubmitForm}
						unitProcessList={unitProcessList} />
				</tr>
			</tfoot>
		</table>
	</div>
	);
};

export default Table;