// eventIdSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EventIdState {
    eventId: string | null;
}

const initialState: EventIdState = {
    eventId: null,
};

const eventIdSlice = createSlice({
    name: "eventId",
    initialState,
    reducers: {
        setEventId: (state, action: PayloadAction<string>) => {
            state.eventId = action.payload;
        },
        clearEventId: (state) => {
            state.eventId = null;
        },
    },
});

export const { setEventId, clearEventId } = eventIdSlice.actions;
export default eventIdSlice.reducer;
