import { useEffect, useState } from "react";
import Button from "../../../base-components/Button";
import FormSwitch from "../../../base-components/Form/FormSwitch";
import { FormLabel, FormSelect } from "../../../base-components/Form";
import Lucide from "../../../base-components/Lucide";
import { useLoader } from "../../../base-components/Loader";

interface Menu {
	id?: number,
	icon: string,
	pathname?: string,
	selected?: boolean,
	title: string,
	subMenu?: Menu[],
}
export default function Main() {
	const { showLoader, hideLoader } = useLoader();
	const [users, setUsers] = useState([])
	const [navMenu, setNavMenu] = useState<Array<Menu | "divider">>([])

	const fetchNavUsers = async () => {
		try {
			showLoader()
			const f = await fetch('/api/nav_users')
			const x = await f.json()
			setUsers(x)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	useEffect(() => { fetchNavUsers() }, [])

	const saveMenu = () => {
		const ids = filterSelectedIds(navMenu).join(',');
		if (ids.length > 0) {
			const u = document.querySelector('#users') as HTMLSelectElement
			saveNavAccess(u.value, ids)
		}
		else { alert('Select atleast one menu') }
	}

	return (
		<>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
				<h2 className="mr-auto text-lg font-medium">Nav Menu</h2>
			</div>
			<div className="p-5 mt-5 intro-y box">
				<div className='flex items-center'>
					<FormLabel className='mb-0 mr-3'>User:</FormLabel>
					<FormSelect id='users' onChange={(e) => {
						loadNavAccess(e.target.value)
					}}>
						<option>Select User</option>
						{users.map((x, i) => <option key={i} value={x.id} >{x.userName}</option>)}
					</FormSelect>
				</div>
				{navMenu.map((x, i) => (
					x == 'divider' ? <hr key={i} className='my-2' /> :
						<ul id={'l1' + i} key={i} className='pl-5 mt-2  border-4 border-white border-l-success'>
							<li className='flex'>
								<Lucide icon='ChevronDown' className='mr-4' onClick={() => { toggleMenu('l1' + i) }} />
								<FormSwitch>
									<FormSwitch.Input id={'i1' + i} type="checkbox"
										onChange={(e) => {
											x.selected = e.target.checked
											setNavMenu([...navMenu])
										}}
										checked={x.selected} />
									<FormSwitch.Label htmlFor={'i1' + i}> {x.title} {x.pathname} </FormSwitch.Label>
								</FormSwitch>
							</li>
							{x.subMenu && <li>
								{x.subMenu.map((a, i1) => (
									<ul key={i1} className='pl-5 ml-20 mt-2 border-4 border-white border-l-warning'>
										<li className='flex'>
											<FormSwitch>
												<FormSwitch.Input id={'i2' + i + i1} type="checkbox"
													onChange={(e) => {
														a.selected = e.target.checked;
														const checked = x.subMenu.some(s => s.selected === true)
														const c: HTMLInputElement = document.querySelector('input[type=checkbox]#i1' + i)
														c.checked = checked
														x.selected = checked
														setNavMenu([...navMenu])
													}} checked={a.selected} />
												<FormSwitch.Label htmlFor={'i2' + i + i1}>{a.title} {a.pathname}</FormSwitch.Label>
											</FormSwitch>
										</li>
									</ul>
								))}
							</li>}
						</ul>
				))}
				<div className='flex'>
					<Button onClick={() => saveMenu()} className='mx-auto mt-5'>Save Menu</Button>
				</div>
			</div>
		</>
	);

	async function loadNavAccess(userId: string) {
		try {
			showLoader()
			const role = 'Admin'
			const f = await fetch(`/api/nav_access/${role}/${userId}`)
			if (f.ok) {
				const x = await f.json()
				if (x.e) console.error(x)
				else setNavMenu(x.d)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	function filterSelectedIds(arr: any[]): number[] {
		return arr.flatMap(item => {
			if (item.selected) return [item.id, ...(item.subMenu ? filterSelectedIds(item.subMenu) : [])];
			else if (item.subMenu) return filterSelectedIds(item.subMenu);
			else return [];
		});
	}

	function toggleMenu(id: string) {
		const e = document.querySelector('#' + id + ' li:nth-child(2)')
		e.classList.toggle('hidden')
	}

	async function saveNavAccess(id: string, menu: string) {
		try {
			showLoader()
			const d = JSON.stringify({ id, menu })
			const f = await fetch('/api/add_nav_access', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
			const x = await f.json()
			alert(JSON.stringify(x))
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
}